import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typeahead } from "react-bootstrap-typeahead";
import { Button, Dropdown, DropdownMenu, DropdownToggle, Form, Label } from "reactstrap";
import styled from "styled-components";
import {
    blackColor,
    greyColor,
    searchFormActiveColor,
    searchFormBorderColor,
    searchFormDropdownActiveColor,
    searchFormDropdownColor,
    searchFormDropdownFocusColor,
    searchFormFocusedColor,
    searchFormInpuColor,
    searchFormInputBorderColor,
    searchFormNumberColor,
    searchFormTitleColor,
    whiteColor
} from "../../../constants/ColorConstants";

export const SearchFormContainer = styled(Form)`
    border-radius: 12px;
    border-top-left-radius: 0px;
    background-color: ${whiteColor};
    box-shadow: rgb(0 0 0 / 18%) 0px 1px 2px !important;
    padding: 30px;
    border: none;
    margin-bottom: 20px;
`;
export const RadioLabel = styled(Label)`
    margin-right: 15px;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    gap: 2px;
    cursor: pointer;
`;
export const SearchFormInputContainer = styled.div`
    border: 1px solid ${searchFormInputBorderColor};
    border-radius: 8px;
    background: ${whiteColor};
    height: fit-content;
    flex: 1 0;
    margin-right: 10px;
    padding: 8px 14px;
    cursor: pointer;
    &:hover .close.rbt-close {
        opacity: 1 !important;
    }

    @media (max-width: 768px) {
        margin-right: 0px !important;
    }

    input {
        padding: 0px;
        border: none !important;
        font-size: 100% !important;
        line-height: 1.5;
        color: ${searchFormInpuColor};
        height: auto !important;
    }
    &:hover,
    &--focused {
        box-shadow: inset 0px 0px 0px 2px ${searchFormFocusedColor};
    }
`;

export const SearchFormLabel = styled.div`
    font-weight: 700;
    font-size: 12px;
    color: ${blackColor};
    line-height: 16px;
    letter-spacing: normal;
`;

export const SearchFormTypeHead = styled(Typeahead)`
    flex: 1 1 auto;
    border-left: none !important;
    & .rbt-input-hint-container {
        overflow: hidden;
    }
    & .dropdown-menu {
        width: 350px !important;
    }
    & .dropdown-menu li a {
        padding: 10px 20px;

        & .typeahead-country {
            font-size: 12px;
            color: ${greyColor};
        }
    }

    & .dropdown-item span {
        font-weight: normal;
        color: ${searchFormDropdownColor} !important;
    }

    & .dropdown-item {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &.active {
            background-color: ${searchFormActiveColor};
        }
        &:active {
            background-color: ${searchFormDropdownActiveColor};
        }
    }

    & .dropdown-menu li:last-child {
        border-bottom: 0px;
    }

    &:focus + .input-group-text {
        border: 2px solid ${searchFormDropdownFocusColor};

        & input {
            line-height: 17px;
        }
    }

    & .rbt-input-hint {
        display: none;
    }

    & .close.rbt-close {
        position: absolute;
        right: -10px;
        top: -15px;
        bottom: 0;
        padding: 0px 10px 0px 40px;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 30%);
        opacity: 0;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        margin: 2px;

        &:hover {
            opacity: 1 !important;
        }
    }
`;

export const SearchFlightButton = styled(Button)`
    width: 250px;
    float: right;
    border-radius: 8px;
    background: ${searchFormFocusedColor};
    font-size: 17px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    text-align: center;
    color: ${whiteColor};
    border: none;
    margin-right: 0px;
    box-shadow: none;
    padding: 15px;
    flex: 0.5 0 auto !important;
    @media (max-width: 768px) {
        width: 100%;
        margin-top: 5px;
    }
`;

export const CustomDateRangePickerWrapper = styled.div`
    flex: 1 0;
    margin-right: 10px;
    display: flex;
    flex-direction: row;
    gap: 10px;

    & .react-datepicker__current-month {
        color: #484848;
        font-size: 18px;
        text-align: center;
        padding-top: 10px;
        padding-bottom: 10px;
        caption-side: top;
    }

    & .react-datepicker__navigation {
        margin-top: 17px;
    }
    & .react-datepicker__header {
        background-color: #fff;
        border-bottom: none;
    }
    & .react-datepicker__day-names {
        & .react-datepicker__day-name {
            font-size: 14px;
            color: #757575;
            font-size: 80%;
            font-weight: 600;
        }
    }
    & .react-datepicker__week {
        & .react-datepicker__day-name {
            font-size: 14px;
            font-weight: 600;
        }
    }

    & .react-datepicker__day {
        /* height: 39px;
    width: 39px;
    padding-top: 7px;
    padding-right: 4px; */
    }

    & .react-datepicker__day--selected {
        background-color: #19b4d1 !important;
    }

    & .react-datepicker__day--in-range {
        background-color: #efefef;
        color: #000000 !important;
    }

    & .react-datepicker__day--selecting-range-end {
        background-color: #19b4d1 !important;
    }
    @media (max-width: 768px) {
        margin-right: 0px !important;

        & .react-datepicker__month-container {
            width: 100% !important;
        }
        & .react-datepicker {
            transform: scale(0.9);
        }
    }
`;

export const CustomDatePickerContainer = styled.div`
    border: 1px solid ${searchFormInputBorderColor};
    border-radius: 8px;
    background: ${whiteColor};
    flex: 1 0;
    padding: 8px 14px;
    cursor: pointer;
    ${(props) => props.selected && `box-shadow: inset 0px 0px 0px 2px ${searchFormFocusedColor};`}
    &:hover {
        box-shadow: inset 0px 0px 0px 2px ${searchFormFocusedColor};
    }
`;

export const DatePickerLabel = styled.div`
    font-weight: 700;
    font-size: 12px;
    color: ${blackColor};
    line-height: 16px;
    letter-spacing: normal;
`;

export const DatePickerValue = styled.div`
    font-size: 14px !important;
    line-height: 1.5;
    color: ${searchFormInpuColor};
`;

export const DatePickerHolder = styled.div`
    position: absolute;
    @media (max-width: 768px) {
        z-index: 9999;
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background: white;
    }
    & .DayPicker {
        @media (max-width: 768px) {
            border-radius: 8 px !important;
            overflow: hidden;
            box-shadow: none !important;
            background: white !important;
            margin-top: 35px !important;
            width: 100%;
            height: calc(100% - 31px);
            left: 0;
            top: 0;
        }
    }
`;

export const DatePickerCloseButton = styled.div`
    color: grey;
    font-size: 22px;
    margin-top: 10px;
    margin-left: 12px;
    cursor: pointer;
`;

export const CustomDropdown = styled(Dropdown)`
    flex: 1 0;
`;

export const CustomDropdownToggle = styled(DropdownToggle)`
    display: block;
    width: 100%;
    text-align: left;
    background: #fff !important;
    color: ${searchFormInpuColor} !important;
    border: 1px solid ${searchFormInputBorderColor};
    height: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-radius: 8px;
    padding: 8px 14px;
    &:after {
        display: none;
    }
    &:hover,
    &:active,
    &:focus {
        box-shadow: inset 0px 0px 0px 2px ${searchFormFocusedColor} !important;
        border-color: ${searchFormInputBorderColor} !important;
    }
`;

export const SearchFormFieldValue = styled.div`
    font-size: 14px !important;
    line-height: 1.5;
    color: ${searchFormInpuColor};
`;

export const CustomDropdownMenu = styled(DropdownMenu)`
    width: 100%;
    box-shadow: 0px 2px 20px rgb(0 0 0 / 12%);
    border-radius: 8px;
    border: none;
    margin-top: 10px;
    padding: 15px 0;
`;

export const SearchFormItem = styled.div`
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 14px;
`;

export const SearchFormNumber = styled.span`
    font-size: 18px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    color: ${searchFormNumberColor};
    margin-right: 10px;
`;

export const SearchFormTitle = styled.span`
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    color: ${searchFormTitleColor};
`;

export const SearchFormButtonCount = styled(Button)`
    background-color: ${whiteColor};
    font-size: 28px;
    font-weight: bold;
    color: ${searchFormFocusedColor};
    border: 1px solid ${searchFormBorderColor};
    border-radius: 100%;
    width: 40px;
    height: 40px;
    padding: 0px;
    text-align: center;
    &:disabled {
        background-color: ${whiteColor};
        color: ${searchFormFocusedColor};
        border: 1px solid ${searchFormBorderColor};
    }
    &:hover,
    &:active,
    &:focus {
        color: ${searchFormFocusedColor} !important;
        background-color: ${whiteColor} !important;
        box-shadow: inset 0px 0px 0px 2px ${searchFormFocusedColor} !important;
        border-color: ${searchFormBorderColor} !important;
    }
`;

export const SearchFormNote = styled.span`
    font-weight: 300;
`;

export const MultiCitySearchFieldContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    position: relative;
`;

export const CustomFontAwesomeIcon = styled(FontAwesomeIcon)`
    ${(props) =>
        props.disabled
            ? `opacity: 0.3;
        cursor: not-allowed;`
            : `opacity: 1;
        cursor: pointer;`}
`;

export const AddAnotherFlightButton = styled.div`
    background-color: transparent;
    font-weight: 600;
    font-size: 16px;
    margin: 5px 0 15px;
    display: block;
    color: ${searchFormFocusedColor};
    cursor: pointer;
`;

export const FlightItenaryContainer = styled.div`
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
`;

export const FlightDetailText = styled.p`
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 0;
`;
