import { List, Paper, TextField, Typography } from "@mui/material";
import { Button } from "reactstrap";
import styled from "styled-components";
import {
    linkColor,
    searchFormFocusedColor,
    searchFormInputBorderColor,
    whiteColor
} from "../../constants/ColorConstants";

export const ShowMoreButton = styled(Button)`
    font-size: 18px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    text-align: center;
    padding: 10px 30px;
    color: ${searchFormFocusedColor};
    border-radius: 4px;
    background-color: ${whiteColor};
    border: 1px solid ${searchFormInputBorderColor};
    &:hover,
    &:active,
    &:focus {
        color: ${searchFormFocusedColor} !important;
        border-radius: 4px;
        background-color: ${whiteColor};
        border: 1px solid ${searchFormInputBorderColor};
    }
    &:disabled {
        color: ${searchFormFocusedColor} !important;
        border-radius: 4px;
        background-color: ${whiteColor};
        border: 1px solid ${searchFormInputBorderColor};
        cursor: not-allowed;
    }
`;

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
`;

export const SearchedResultContainer = styled(Paper)`
    position: absolute;
    top: ${(props) => 40 + props.topheight}px;
    width: 300px;
    z-index: 9999;
    padding: 10px;
    max-height: 500px;
    overflow: auto;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
        0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    @media (max-width: 900px) {
        width: 250px;
    }
`;

export const PrimaryTextField = styled(TextField)`
    border-radius: 10px;
    ${(props) =>
        props.textfieldType === "primary" &&
        `
        & .MuiInputLabel-root {
        top: 14% !important;
}
    }
    & .Mui-focused {
        top: 0% !important;
    }
    `}
    & input {
        height: 30px !important;
        border-radius: 10px;
        background-color: white;
    }
`;

export const OptionTextColor = styled(Typography)`
    padding: 8px 8px;
    width: fit-content;
    background-color: transparent;
    font-size: 14px !important;
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.15px;
    color: #1a237e !important;
    background-color: white;
    border: 1px rgba(0, 0, 0, 0.23) solid;
    border-radius: 10px;
    margin-bottom: 30px !important;
    @media (max-width: 900px) {
        height: 40px;
        margin-bottom: 0px !important;
        padding: 0px 10px !important;
    }
`;

export const HeadingTypography = styled(Typography)`
    color: #141d38;
`;

export const CardContainer = styled(Paper)`
    box-shadow: 0 0 7px 0 hsl(0deg 0% 55% / 21%) !important;
    border-radius: 5px !important;
    margin-top: 20px;
    padding: 30px;
`;

export const HotelCardContainer = styled(CardContainer)`
    box-shadow: 0 0 7px 0 hsl(0deg 0% 55% / 21%) !important;
    border-radius: 5px !important;
    margin-top: 20px;
    padding: 30px;
    @media (max-width: 900px) {
        height: auto !important;
    }
`;

export const OptionsListContainer = styled(List)`
    padding: 0px;
    margin-left: 0px;
`;

export const CarouselImg = styled.img`
    width: 100%;
    height: 300px;
    object-fit: contain;
`;

export const CardTitle = styled(Typography)`
    border-bottom: 1px solid grey;
    padding-bottom: 8px;
    margin-bottom: 16px;
    font-weight: bold;
`;

export const PrimaryText = styled(Typography)`
    color: rgba(0, 0, 0, 0.87);
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: 0;
`;

export const CaptionText = styled(Typography)`
    color: ${(props) => props.color || "rgba(0, 0, 0, 0.54)"};
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: 0;
`;

export const LinkText = styled.span`
    font-weight: bold;
    color: #0072ea;
    cursor: pointer;
`;

export const ShowMoreLessText = styled(Typography)`
    color: ${linkColor};
    cursor: pointer;
`;

export const ReadMoreText = styled.div`
    position: relative;
    max-height: ${(props) => props.boxHeight || 250}px;
    overflow: hidden;
    width: 100%;
    padding: 10px;
    transition: max-height 0.15s ease-out;

    ${(props) =>
        props.isexpand &&
        `
    max-height: 5000px !important;
  transition:max-height 0.35s ease-in-out;
    `}
`;

export const ReadMoreLink = styled.span`
    position: absolute;
    bottom: 0;
    right: 0;
    display: block;
    width: 100%;
    height: 60px;
    text-align: center;
    color: ${linkColor};
    font-weight: bold;
    font-size: 16px;
    padding-top: 40px;
    background-image: linear-gradient(to bottom, transparent, white);
    cursor: pointer;

    ${(props) =>
        props.isexpand &&
        `
    position: relative;
  background-image: none;
  padding-top:10px;
  height:20px;
    `}
`;

export const PrimaryButton = styled(Button)`
    background: #58a946 !important;
    border-radius: 8px;
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    border: none;
    padding: 13px 30px;
    transition: box-shadow 0.2s ease 0s, transform 0.1s ease 0s !important;
`;

export const CurrencyText = styled.p`
    font-size: 23px;
    font-weight: 800;
    color: #353d74;
    letter-spacing: -0.06em;
    sup {
        font-size: 10px;
        font-weight: 700;
        top: -15px;
        letter-spacing: 0;
    }
`;

export const CaptionTextSecondary = styled.p`
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    margin: 0;
    color: ${(props) => props.color};
`;

export const Heading1 = styled.h3`
    font-size: 20px;
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    color: #58a946;
    margin: 0;
`;

export const FlightOptionStyle = styled(Typography)`
    font-size: ${(props) => props.textsize || "14px"} !important;
    color: #000 !important;
    text-align: left !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    width: 253px !important;
    padding: 0px !important;
`;

export const FLightSubOptionStyled = styled(FlightOptionStyle)`
    font-size: 12px;
    span {
        font-weight: normal !important;
    }
`;
