import { Box, ClickAwayListener, useMediaQuery, useTheme } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import React, { useState } from "react";
import { _url } from "../../../../config/utils";
import { SearchTextFieldStyle } from "../../fields/Style";

const MuiSingleDatePicker = ({
    date,
    isError,
    label,
    minimumDate,
    handleDateChange,
    type,
    onFieldBlur,
    maximumDate,
    errorMessage,
    dateFormat,
    ...rest
}) => {
    const [openDatepicker, setOpenDatepicker] = useState(false);
    const [openModal, setOpenModal] = React.useState(false);

    const theme = useTheme();
    const mobileView = useMediaQuery(theme.breakpoints.down(900));

    const handleChange = (newValue) => {
        handleDateChange(new Date(newValue));
        setOpenDatepicker(false);
    };

    if (type === "secondary") {
        return (
            <ClickAwayListener
                onClickAway={() => {
                    setOpenDatepicker(false);
                    onFieldBlur && onFieldBlur();
                }}
            >
                <Box>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DesktopDatePicker
                            label={label}
                            inputFormat={dateFormat || "DD MMM"}
                            value={date}
                            open={true}
                            onChange={handleChange}
                            views={["year", "month", "day"]}
                            maxDate={maximumDate ? new Date(maximumDate) : new Date(moment().add("12", "months"))}
                            minDate={minimumDate ? new Date(minimumDate) : new Date()}
                            renderInput={(params) => (
                                <SearchTextFieldStyle
                                    {...params}
                                    style={{ background: "white", borderRadius: 10, width: "100%" }}
                                    inputProps={{
                                        ...params.inputProps,
                                        readOnly: mobileView,
                                        placeholder: "Add Date"
                                    }}
                                    onKeyDown={(e) => rest.isDisabled && e.preventDefault()}
                                    error={!!isError}
                                    helperText={!!isError && errorMessage}
                                    onClick={() => {
                                        setOpenDatepicker((prev) => !prev);
                                    }}
                                    InputProps={{
                                        startAdornment: <img src={_url("assets/redesign/icons/calendar.svg")} />
                                    }}
                                />
                            )}
                        />
                    </LocalizationProvider>
                </Box>
            </ClickAwayListener>
        );
    }
    return (
        <ClickAwayListener onClickAway={() => setOpenDatepicker(false)}>
            <Box>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DesktopDatePicker
                        label={label}
                        inputFormat="DD MMM"
                        value={date}
                        onOpen={() => {
                            mobileView && setOpenModal(true);
                        }}
                        open={openDatepicker}
                        InputAdornmentProps={{ style: { display: "none" } }}
                        onChange={handleChange}
                        components={{
                            OpenPickerIcon: null
                        }}
                        maxDate={maximumDate ? new Date(maximumDate) : new Date(moment().add("12", "months"))}
                        minDate={minimumDate}
                        renderInput={(params) => (
                            <SearchTextFieldStyle
                                {...params}
                                style={{ background: "white", borderRadius: 10, width: "100%" }}
                                inputProps={{
                                    ...params.inputProps,
                                    readOnly: mobileView,
                                    placeholder: "dd/mm/yyyy"
                                }}
                                onClick={() => {
                                    setOpenDatepicker((prev) => !prev);
                                    mobileView && setOpenModal(true);
                                }}
                                InputProps={{
                                    startAdornment: <img src={_url("assets/redesign/icons/calendar.svg")} />
                                }}
                            />
                        )}
                    />
                </LocalizationProvider>
            </Box>
        </ClickAwayListener>
    );
};

export default MuiSingleDatePicker;
