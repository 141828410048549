/* eslint-disable no-useless-escape */
import { Box, IconButton, useMediaQuery, useTheme } from "@mui/material";
import React, { useRef, useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { debounce } from "throttle-debounce";
import InternalApi from "../../config/internalApi";
import { _url } from "../../config/utils";
import { ColorTheme } from "../../styles/color";
import { titleCase } from "../../utils/SearchUtils";
import { getSiteValueByLang } from "../../utils/SiteUtils";
import Highlighted from "./HighlightText";
import { FlightAutocompleteContainer } from "./Style";
import { SearchTextFieldStyle } from "./fields/Style";
import { Text } from "./typography/Style";

const removeStateInfo = (inputString) =>
    inputString?.replace(/\(state:[^\)]+\)/g, "").replace(/\(country:[^\)]+\)/g, "");

const FlightAutocompleteSearch = ({
    label,
    id,
    updateLocation,
    fieldName,
    index,
    selected,
    placeholder,
    startIcon
}) => {
    const [placesList, setPlacesList] = useState([]);
    const ref = useRef(null);
    const [searchedText, setSearchedText] = useState("");
    const theme = useTheme();
    const mobileView = useMediaQuery(theme.breakpoints.down("769"));
    const [isSearchedDone, setIsSearchedDone] = useState(false);

    const search = (keyword) => {
        setSearchedText(keyword);
        if (keyword === "") {
            setPlacesList([]);
            return false;
        }

        InternalApi.get("/search/airport", {
            keyword,
            subType: "AIRPORT",
            max: 10
        })
            .then((res) => {
                setIsSearchedDone(true);
                const places = [];
                if (res.data.length > 0) {
                    res.data.forEach((p) => {
                        const city = titleCase(p.address_city_name);
                        const name = titleCase(p.name);
                        const country = titleCase(p.address_country_name);
                        const state = titleCase(p.state);
                        places.push({
                            id: p.iata_code,
                            name,
                            city,
                            country,
                            state
                        });
                    });
                }
                setPlacesList(places);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getOptions = () => {
        if (!isSearchedDone) {
            return getSiteValueByLang(id === "origin" ? "popularDepartureDestination" : "popularArrivalDestination");
        }
        if (mobileView || placesList.length !== 0) {
            return placesList;
        }
        return selected || [];
    };

    const getLabelKey = (option) => {
        if (option && option.id) {
            if (option.city) {
                return `${option.city} (${option.id}) ${`(state: ${option?.state || ""})`} ${`(country: ${
                    option?.country || ""
                })`}`;
            }
            if (option.name) {
                return `${option.name} (${option.id}) ${`(state: ${option?.state || ""})`} ${`(country: ${
                    option?.country || ""
                })`}`;
            }
            return option.id;
        }
        return option;
    };

    return (
        <FlightAutocompleteContainer>
            <Typeahead
                labelKey={(option) => getLabelKey(option)}
                renderInput={(props) => (
                    <SearchTextFieldStyle
                        fullWidth
                        InputProps={{
                            startAdornment: startIcon ? <img src={startIcon} /> : undefined,
                            endAdornment: selected && (
                                <IconButton
                                    onClick={() => {
                                        ref.current._handleClear();
                                    }}
                                >
                                    <img src={_url("assets/redesign/icons/close_outlined.svg")} />
                                </IconButton>
                            )
                        }}
                        {...props}
                        value={removeStateInfo(props.value)}
                    />
                )}
                renderMenuItemChildren={(option) => (
                    <div>
                        <Box py={"10px"} display={"flex"} gap={"10px"}>
                            <Box>
                                <img src={_url("assets/redesign/icons/flight_leaving_dark.svg")} />
                            </Box>
                            <Box>
                                <Text size={"sm"} textweight={"medium"}>
                                    <Highlighted highlight={searchedText} text={option.city} /> (
                                    <Highlighted highlight={searchedText} text={option.id} />)
                                </Text>
                                <Text
                                    size={"sm"}
                                    textweight={"semiBold"}
                                    textcolor={ColorTheme.grayNeutral[500]}
                                    style={{ marginBottom: 0 }}
                                >
                                    <Highlighted highlight={searchedText} text={option.state} />{" "}
                                    <Highlighted highlight={searchedText} text={option.country} />
                                </Text>
                            </Box>
                        </Box>
                    </div>
                )}
                id={id}
                options={getOptions()}
                maxHeight="300px"
                placeholder={placeholder}
                onInputChange={debounce(200, search)}
                onChange={(selectedLocation) =>
                    updateLocation({
                        field: fieldName,
                        location: selectedLocation[0],
                        index: index
                    })
                }
                ref={ref}
                filterBy={["id", "airport", "city", "country", "state"]}
                selected={selected || []}
            />
        </FlightAutocompleteContainer>
    );
};

export default FlightAutocompleteSearch;
