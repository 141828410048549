import { Grid } from "@mui/material";
import React from "react";
import { HotelCardContainer } from "./CommonStyle";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { GridContainer } from "../stays/result/Style";

const LoadingShimmerRows = ({ noOfCards, isLoading, children }) => {
    const loadingArray = [];

    for (let i = 0; i < noOfCards; i++) {
        loadingArray.push(
            <HotelCardContainer key={i}>
                <GridContainer container direction="row" spacing={1}>
                    <Grid style={{ height: "100%" }} item xs={12} md={4}>
                        <Skeleton height={"200px"} width={"100%"} />
                    </Grid>
                    <Grid style={{ padding: 20 }} item xs={12} md={8}>
                        <Skeleton count={1} height={"40px"} />
                        <Skeleton count={4} />
                    </Grid>
                </GridContainer>
            </HotelCardContainer>
        );
    }

    if (isLoading) {
        return (
            <Grid item xs={12}>
                {loadingArray}
            </Grid>
        );
    }

    return <>{children}</>;
};

export default LoadingShimmerRows;
