import { reduce } from "lodash";
import moment from "moment";

export const checkRoomFieldValidation = (roomDetails) => {
    let error = false;

    roomDetails.forEach((item) => {
        if (item.children.length > 0) {
            const childrenAgesCheck = item.children.filter((item) => item.age === "");
            if (childrenAgesCheck.length > 0) {
                error = true;
            }
        }
    });
    return error;
};

export const prepareHotelNavigationData = (values) => {
    const roomInfo = JSON.stringify(values.roomDetails);
    if (checkRoomFieldValidation(values.roomDetails)) {
        return false;
    }

    const { location, locationCode, destination } = values;
    const checkInDate = moment(values.departureDate).format("YYYY-MM-DD");
    const checkOutDate = moment(values.returnDate).format("YYYY-MM-DD");

    return `/stays/search?destination=${JSON.stringify(
        destination
    )}&location=${location}&locationCode=${locationCode}&checkindate=${checkInDate}&checkoutdate=${checkOutDate}&roominfo=${roomInfo}`;
};

export const getTotalTravelers = (roomInfo) =>
    reduce(
        roomInfo,
        function (sum, n) {
            return sum + n.adult + n.children.length;
        },
        0
    );

export const handleChildAgeChange = (roomInfo, value, index) => {
    const copy = roomInfo;
    copy.children[index] = {
        id: copy.children[index].id,
        age: value
    };
    return copy.children;
};
