import { Grid } from "@mui/material";
import { useGetWindowSize } from "../../../components/common/hook/WindowSize";
import { _url } from "../../../config/utils";

const TestimonialBanner = () => {
    const { desktopView } = useGetWindowSize();
    return (
        <Grid container>
            <Grid item xs={12}>
                <img
                    style={{ width: "100%" }}
                    src={_url(
                        `assets/redesign/icons/${desktopView ? "section_earth_2.png" : "mobile_section_earth.png"}`
                    )}
                />
            </Grid>
        </Grid>
    );
};

export default TestimonialBanner;
