import moment from "moment";

export const createAction = (type, payload = {}, error = false) => ({
    type,
    payload,
    error
});

export const getParams = (params, searchQuery) => {
    const { multiCityLegs } = searchQuery;
    const multiCityQueries = [];
    if (searchQuery.type === "multi-city") {
        multiCityLegs.forEach((leg) => {
            multiCityQueries.push({
                origin: leg.origin.id,
                destination: leg.destination.id,
                departureDate: moment(leg.departureDate).format("YYYY-MM-DD")
            });
        });
        params = {
            ...params,
            flights: multiCityQueries
        };
        return params;
    }
    params = {
        ...params,
        flights: [
            {
                origin: searchQuery.origin.id,
                destination: searchQuery.destination.id,
                departureDate: searchQuery.departureDate
            }
        ]
    };
    if (searchQuery.returnDate) {
        params.flights.push({
            origin: searchQuery.destination.id,
            destination: searchQuery.origin.id,
            departureDate: searchQuery.returnDate
        });
    }
    return params;
};
