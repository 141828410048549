import {
    Box,
    Button,
    Divider,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    Slider,
    Stack,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { GuestRatingOptions } from "../../../constants/Options";
import { useDebounce } from "../../../hooks/CommonHooks";
import TextFieldWrapper from "../../common/TextFieldWrapper";
import { FilterContainer } from "./Style";

const valuetext = (value) => `$${value}`;

const HotelSearchFilter = ({ selectedFilters, setSelectedFilters }) => {
    const [initialPrice] = useState(selectedFilters.priceFilter);
    const [searchText, setSearchText] = useState(selectedFilters.searchText);
    const [propertyRating, setPropertyRating] = useState(selectedFilters.propertyRating);
    const [guestRating, setGuestRating] = useState(selectedFilters.guestRating);
    const [priceRange, setPriceRange] = useState([selectedFilters.priceFilter[0], selectedFilters.priceFilter[1]]);

    const theme = useTheme();
    const mobileView = useMediaQuery(theme.breakpoints.down(900));

    const handleChange = (_, newValue) => {
        setSelectedFilters({ ...selectedFilters, filterSelected: true });
        setPriceRange(newValue);
    };

    const searchTextDebounce = useDebounce(searchText, 500);
    const propertyRatingDebounce = useDebounce(propertyRating, 500);
    const guestRatingDebounce = useDebounce(guestRating, 500);
    const priceDebounce = useDebounce(priceRange, 500);

    useEffect(() => {
        setSelectedFilters({
            ...selectedFilters,
            searchText: searchText,
            propertyRating: propertyRating,
            priceFilter: priceRange,
            guestRating: guestRating
        });
    }, [searchTextDebounce, priceDebounce, propertyRatingDebounce, guestRatingDebounce]);

    const handleStarRating = (rating) => {
        setPropertyRating(rating === selectedFilters.propertyRating ? "" : rating);
        setSelectedFilters({ ...selectedFilters, filterSelected: true });
    };

    const handleGeneralRating = (rating) => {
        setGuestRating(rating === selectedFilters.guestRating ? "" : rating);
        setSelectedFilters({ ...selectedFilters, filterSelected: true });
    };

    const clearFilters = () => {
        setPriceRange(initialPrice);
        setSearchText("");
        setGuestRating("");
        setPropertyRating("");
        setSelectedFilters({
            searchText: "",
            priceFilter: initialPrice,
            propertyRating: "",
            guestRating: "",
            filterSelected: false
        });
    };
    return (
        <FilterContainer ismobile={mobileView ? 1 : 0}>
            <Grid container alignItems={"center"} justifyContent={"space-between"}>
                <Grid item>
                    <Typography style={{ marginTop: 10 }} variant="h6" gutterBottom>
                        Filters
                    </Typography>
                </Grid>
                {selectedFilters.filterSelected && (
                    <Grid item>
                        <Button variant={"text"} onClick={clearFilters}>
                            Clear all
                        </Button>
                    </Grid>
                )}
            </Grid>
            <Divider style={{ margin: "10px 0px" }} />
            <TextFieldWrapper
                value={searchText}
                label="Find a hotel"
                onChange={({ target }) => {
                    setSelectedFilters({ ...selectedFilters, filterSelected: true });
                    setSearchText(target.value);
                }}
            />
            <Typography style={{ marginTop: 10 }} variant="h6" gutterBottom>
                Hotel Price
            </Typography>
            <Divider style={{ margin: "10px 0px" }} />
            <Slider
                getAriaLabel={() => "Temperature range"}
                value={priceRange}
                onChange={handleChange}
                min={initialPrice[0]}
                max={initialPrice[1]}
                valueLabelDisplay="auto"
                getAriaValueText={valuetext}
            />
            <Typography variant="body1" gutterBottom>
                ${parseInt(selectedFilters?.priceFilter?.[0] || "0")} - $
                {parseInt(selectedFilters.priceFilter?.[1] || "0")}
            </Typography>
            <Box>
                <Typography style={{ marginTop: 10 }} variant="h6" gutterBottom>
                    Guest Rating
                </Typography>
                {GuestRatingOptions.map(({ value, label }) => (
                    <Stack direction="column" key={value}>
                        <RadioGroup
                            name="guest-rating"
                            aria-labelledby="gues-rating-label"
                            value={guestRating}
                            onClick={() => handleGeneralRating(value)}
                        >
                            <FormControlLabel value={value} control={<Radio />} label={label} />
                        </RadioGroup>
                    </Stack>
                ))}
            </Box>
            <Divider style={{ margin: "10px 0px" }} />
            {/* <Box>
        <Typography style={{ marginTop: 10 }} variant='h6' gutterBottom>
          Star Rating
        </Typography>
        <Stack direction='row' flexWrap='wrap'>
          {StarRatingOptions.map(({ label, value }) => (
            <Stack key={value}>
              <StarRatingButton
                isactive={propertyRating === value ? 1 : 0}
                variant='outlined'
                onClick={() => handleStarRating(value)}
              >
                {label} <StarRateIcon sx={{ color: '#1976d2' }} />
              </StarRatingButton>
            </Stack>
          ))}
        </Stack>
      </Box> */}
        </FilterContainer>
    );
};

export default HotelSearchFilter;
