import React from "react";
import { NumberFormatBase } from "react-number-format";

const format = (val) => {
    if (val === "") return "";
    let month = val.substring(0, 2);
    const year = val.substring(2, 4);

    if (month.length === 1 && month[0] > 1) {
        month = `0${month[0]}`;
    } else if (month.length === 2) {
        if (Number(month) === 0) {
            month = "01";
        } else if (Number(month) > 12) {
            month = "12";
        }
    }

    return `${month}/${year}`;
};

const CCExpDate = (props) => {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormatBase
            {...other}
            format={format}
            placeholder="MM/YY"
            mask={["M", "M", "Y", "Y"]}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.formattedValue
                    }
                });
            }}
        />
    );
};

export default CCExpDate;
