import { Grid } from "@mui/material";
import { DisplayText, Text } from "../../../components/common/typography/Style";
import { ColorTheme } from "../../../styles/color";
import TestimonialCarousel from "./TestimonialCarousel";

const TestimonialStoryCustomer = () => {
    return (
        <Grid container py={"72px"}>
            <Grid item xs={12}>
                <DisplayText textAlign={"center"} size={"md"} textweight={"bold"}>
                    Stories of customers
                </DisplayText>
                <Text textAlign={"center"} size={"md"} textcolor={ColorTheme.grayNeutral[600]} textweight={"regular"}>
                    Here are some of our favourite customer stories
                </Text>
            </Grid>
            <Grid item xs={12}>
                <TestimonialCarousel />
            </Grid>
        </Grid>
    );
};

export default TestimonialStoryCustomer;
