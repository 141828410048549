import { faPlane } from "@fortawesome/fontawesome-free-solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tippy from "@tippyjs/react";
import * as moment from "moment";
import { Trans } from "react-i18next";
import { Col, Row } from "reactstrap";
import { _url } from "../../../config/utils";
import { formatDuration } from "../../../utils/SearchUtils";

export const renderStopFlight = (stop, segments) => {
    if (stop === 0) {
        return null;
    }
    const arr = new Array(stop).fill(1);
    return arr.map((a, index) => (
        <Tippy content={segments[index].arrivalLocation} animation="shift-away" duration={[100, 80]} className="light">
            <span key={index} className="flight">
                {segments[index].arrivalCode}{" "}
            </span>
        </Tippy>
    ));
};

export const countDay = (days = []) => {
    if (days === null || days.length < 1) {
        return null;
    }
    const depart = moment(days[0].departureAt.slice(0, 10));
    const arrival = moment(days[days.length - 1].arrivalAt.slice(0, 10));
    const numDay = arrival.diff(depart, "days");
    if (numDay > 0) {
        return <span>+{numDay}</span>;
    }
    return null;
};

export const renderStopDot = (stop = 0) => {
    if (stop === 0) {
        return null;
    }
    const arr = new Array(stop).fill(1);
    return arr.map((a, index) => <div key={index} className="dot" />);
};

export const renderDetailFlight = ({ validatingCarrierCode, itineraries }) => {
    let carrierName = "";
    itineraries.forEach((itinerary) => {
        itinerary.segments.forEach((segment) => {
            if (segment.carrierCode === validatingCarrierCode) {
                carrierName = segment.carrierName;
            }
        });
    });
    if (carrierName === "") {
        carrierName = itineraries[0].segments[0].carrierName;
    }
    return <img alt={carrierName.trim()} src={_url(`assets/images/logos/${carrierName.trim()}.png`)} />;
};

export const renderFlightSegments = (data) =>
    data.itineraries.map((flight) => (
        <Col xs={12} key={`${JSON.stringify(flight)}-${Math.random()}`} style={{ marginTop: 10 }}>
            <Row className="search-result__segment address">
                <Col xs="auto" className="address-time">
                    <Tippy
                        content={flight.segments[0].departureLocation}
                        animation="shift-away"
                        duration={[100, 80]}
                        className="light"
                    >
                        <span className="search-result__segment-airport">{flight.segments[0].departureCode}</span>
                    </Tippy>
                    <Tippy
                        content={moment(flight.segments[0].departureAt).format("h:mma on ddd, D MMM YYYY")}
                        animation="shift-away"
                        duration={[100, 80]}
                        className="light"
                    >
                        <p className="search-result__segment-time">
                            {moment(flight.segments[0].departureAt).format("h:mm")}
                            <dd className="am-pm">{moment(flight.segments[0].departureAt).format("a")}</dd>
                        </p>
                    </Tippy>
                </Col>
                <Col className="text-center more-info">
                    <p>{formatDuration(flight.duration)}</p>
                    <div className="divider" style={{ margin: "5px 0px" }}>
                        {renderStopDot(flight.stops)}
                        <FontAwesomeIcon icon={faPlane} />
                    </div>
                    {flight.stops > 0 && (
                        <p className="divider-flight">{renderStopFlight(flight.stops, flight.segments)}</p>
                    )}
                    {flight.stops < 1 && (
                        <p className="direct">
                            <Trans>nonstop</Trans>
                        </p>
                    )}{" "}
                </Col>
                <Col xs="auto" className="text-right address-time">
                    <Tippy
                        content={flight.segments[flight.segments.length - 1].arrivalLocation}
                        animation="shift-away"
                        duration={[100, 80]}
                        className="light"
                    >
                        <span className="search-result__segment-airport">
                            {flight.segments[flight.segments.length - 1].arrivalCode}
                            {countDay(flight.segments)}
                        </span>
                    </Tippy>
                    <Tippy
                        content={moment(flight.segments[flight.segments.length - 1].arrivalAt).format(
                            "h:mma on ddd, D MMM YYYY"
                        )}
                        animation="shift-away"
                        duration={[100, 80]}
                        className="light"
                    >
                        <p className="search-result__segment-time">
                            {moment(flight.segments[flight.segments.length - 1].arrivalAt).format("h:mm")}
                            <dd className="am-pm">
                                {moment(flight.segments[flight.segments.length - 1].arrivalAt).format("a")}
                            </dd>
                        </p>
                    </Tippy>
                </Col>
            </Row>
        </Col>
    ));

export const renderSubFlight = (data = [], isReturn = false) => (
    <div className="content-sub">
        <p>{isReturn ? "Returns" : "Departure"}</p>
        <div className="details">
            {data.segments.map((sub, index) => (
                <div key={`subid-${sub.id}`} className="sub">
                    <p>
                        {sub.departureCode} - {sub.arrivalCode}
                    </p>
                    <Row>
                        <Col xs="auto">
                            <img
                                alt={isReturn ? "Returns" : "Departure"}
                                src={_url(`assets/images/${isReturn ? "landing" : "takeoff"}.svg`)}
                            />
                        </Col>
                        <Col>
                            <img
                                alt={sub.carrierName.trim()}
                                src={_url(`assets/images/logos/${sub.carrierName.trim()}.png`)}
                            />
                        </Col>
                    </Row>
                </div>
            ))}
        </div>
    </div>
);
export const renderSubFlightMobile = (data = [], isReturn = false) => (
    <div className="content-sub">
        <p>{isReturn ? "Returns" : "Departure"}</p>
        <div className="details">
            {data.segments.map((sub, index) => (
                <div key={index} className="sub">
                    <Row>
                        <Col xs="auto">
                            <img
                                alt={isReturn ? "Returns" : "Departure"}
                                src={_url(`assets/images/${isReturn ? "landing" : "takeoff"}.svg`)}
                            />
                        </Col>
                        <Col xs="auto">
                            <p>
                                {sub.departureCode} - {sub.arrivalCode}
                            </p>
                        </Col>
                        <Col>
                            <img
                                key={index}
                                alt={sub.carrierName.trim()}
                                src={_url(`assets/images/logos/${sub.carrierName.trim()}.png`)}
                            />
                        </Col>
                    </Row>
                </div>
            ))}
        </div>
    </div>
);
