import { Box, Grid } from "@mui/material";
import React from "react";
import { Text } from "../../../components/common/typography/Style";
import { ColorTheme } from "../../../styles/color";
import { useNavigate } from "react-router-dom";

const MobileUserInfo = ({ auth, logoutUser }) => {
    const navigate = useNavigate();
    return (
        <Grid container px={"16px"} py={"20px"}>
            <Grid py={"20px"} item xs={12} borderRadius={"10px"} bgcolor={ColorTheme.grayNeutral[100]}>
                <Grid container>
                    <Grid item xs={12} px={"16px"}>
                        <Text textAlign={"left"} size={"lg"} textweight={"semiBold"}>
                            Hi, {auth.user.firstName}
                        </Text>
                        <Text textAlign={"left"} size={"sm"} textweight={"regular"}>
                            {auth.user.email}
                        </Text>
                    </Grid>
                    <Grid item xs={12} py={"24px"} borderBottom={`1px ${ColorTheme.grayNeutral[200]} solid`}>
                        <Box
                            sx={{
                                borderLeft: window.location.pathname.includes("/bookings")
                                    ? `4px solid ${ColorTheme.secondary[500]}`
                                    : "",
                                pl: "16px"
                            }}
                        >
                            <Text
                                width={"100%"}
                                sx={{
                                    cursor: "pointer"
                                }}
                                textcolor={
                                    window.location.pathname.includes("/bookings")
                                        ? ColorTheme.secondary[600]
                                        : ColorTheme.grayNeutral[700]
                                }
                                size={"sm"}
                                onClick={() => navigate("/bookings")}
                                textweight={"regular"}
                            >
                                My Bookings
                            </Text>
                        </Box>
                        <Box
                            sx={{
                                borderLeft: window.location.pathname.includes("/refer")
                                    ? `4px solid ${ColorTheme.secondary[500]}`
                                    : "",
                                pl: "16px"
                            }}
                        >
                            <Text
                                width={"100%"}
                                sx={{
                                    cursor: "pointer"
                                }}
                                textcolor={
                                    window.location.pathname.includes("/refer")
                                        ? ColorTheme.secondary[600]
                                        : ColorTheme.grayNeutral[700]
                                }
                                mt={"16px"}
                                size={"sm"}
                                onClick={() => navigate("/refer")}
                                textweight={"regular"}
                            >
                                Credit
                            </Text>
                        </Box>
                        <Box
                            sx={{
                                borderLeft: window.location.pathname.includes("/refer")
                                    ? `4px solid ${ColorTheme.secondary[500]}`
                                    : "",
                                pl: "16px"
                            }}
                        >
                            <Text
                                width={"100%"}
                                sx={{
                                    cursor: "pointer",
                                    ":hover": {
                                        color: window.location.pathname.includes("/refer")
                                            ? ColorTheme.secondary[500]
                                            : ""
                                    }
                                }}
                                textcolor={
                                    window.location.pathname.includes("/refer")
                                        ? ColorTheme.secondary[600]
                                        : ColorTheme.grayNeutral[700]
                                }
                                mt={"16px"}
                                onClick={() => navigate("/refer")}
                                size={"sm"}
                                textweight={"regular"}
                            >
                                Refer and Get $25!
                            </Text>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Text
                            width={"100%"}
                            sx={{
                                cursor: "pointer",
                                ":hover": {
                                    color: ColorTheme.grayNeutral[200]
                                },
                                pl: "16px"
                            }}
                            textcolor={
                                window.location.pathname.includes("/refer")
                                    ? ColorTheme.secondary[600]
                                    : ColorTheme.grayNeutral[700]
                            }
                            mt={"16px"}
                            onClick={logoutUser}
                            size={"sm"}
                            textweight={"regular"}
                        >
                            Sign Out
                        </Text>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default MobileUserInfo;
