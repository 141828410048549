/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { get } from "lodash";
import { PrimaryTextField } from "./CommonStyle";

const TextFieldWrapper = (props) => {
    const { formik, name, onChange, onBlur, size, textfieldType = "primary" } = props;
    if (formik) {
        return (
            <PrimaryTextField
                {...props}
                textfieldType={textfieldType}
                style={{ height: "undefined" }}
                value={name && get(formik.values, name)}
                error={!!name && !!get(formik.errors, name) && !!get(formik.touched, name)}
                onChange={onChange || formik.handleChange}
                onBlur={() => {
                    onBlur ? onBlur() : name && formik && formik.setFieldTouched(name);
                }}
                helperText={!!name && !!get(formik.touched, name) && get(formik.errors, name)}
                fullWidth
                variant={"outlined"}
                size={size || "small"}
                autoComplete="off"
            />
        );
    }
    return <PrimaryTextField {...props} fullWidth variant={"outlined"} size={"small"} autoComplete="off" />;
};

export default TextFieldWrapper;
