import React from "react";
import { useGetWindowSize } from "../../components/common/hook/WindowSize";
import { Box, Dialog, Drawer, Grid, IconButton } from "@mui/material";
import { _url } from "../../config/utils";
import { Text } from "../../components/common/typography/Style";

const ReviewPaymentPlanModal = ({ open, handleClose }) => {
    const { mobileView } = useGetWindowSize();

    const renderInformation = () => {
        return (
            <Grid container rowSpacing={"24px"}>
                <Grid item xs={12}>
                    <Grid container flexWrap={"nowrap"} gap={"10px"}>
                        <Grid item>
                            <img src={_url("assets/icons/mobile_icon.svg")} />
                        </Grid>
                        <Grid item>
                            <Text size={"lg"} textweight={"semiBold"}>
                                Book your gataway
                            </Text>
                            <Text size={"md"} textweight={"regular"}>
                                Search from thousands of flights and hotels. Book with a fraction of the upfront cost
                            </Text>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container flexWrap={"nowrap"} gap={"10px"}>
                        <Grid item>
                            <img src={_url("assets/icons/coin_icon.svg")} />
                        </Grid>
                        <Grid item>
                            <Text size={"lg"} textweight={"semiBold"}>
                                Enjoy flexible payments
                            </Text>
                            <Text size={"md"} textweight={"regular"}>
                                Pay weekly or fortnightly before you travel. No interest. No credit checks.
                            </Text>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container flexWrap={"nowrap"} gap={"10px"}>
                        <Grid item>
                            <img src={_url("assets/icons/plane_icon.svg")} />
                        </Grid>
                        <Grid item>
                            <Text size={"lg"} textweight={"semiBold"}>
                                Travel with confidence
                            </Text>
                            <Text size={"md"} textweight={"regular"}>
                                Enjoy your getaway knowing that your flights are fully paid off.{" "}
                            </Text>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    };
    if (mobileView) {
        return (
            <Drawer anchor={"bottom"} open={open} onClose={handleClose}>
                <Box p={"16px"} pb={"56px"}>
                    <Box width={"100%"} display={"flex"} justifyContent={"flex-end"}>
                        <IconButton onClick={() => handleClose()}>
                            <img src={_url("assets/icons/close_icon.svg")} />
                        </IconButton>
                    </Box>
                    {renderInformation()}
                </Box>
            </Drawer>
        );
    }
    return (
        <Dialog open={open} onClose={handleClose}>
            <Box p={"16px"} pb={"56px"}>
                <Box width={"100%"} display={"flex"} justifyContent={"flex-end"}>
                    <IconButton onClick={() => handleClose()}>
                        <img src={_url("assets/icons/close_icon.svg")} />
                    </IconButton>
                </Box>
                {renderInformation()}
            </Box>
        </Dialog>
    );
};

export default ReviewPaymentPlanModal;
