import styled from "@emotion/styled";
import { Box, Button, Grid, Paper } from "@mui/material";
import Carousel from "react-material-ui-carousel";

export const CardImg = styled.img`
    max-width: 100%;
    height: 100%;
    border-radius: 5px;
    @media (max-width: 900px) {
        object-fit: contain;
    }
`;

export const MemberPrice = styled(Box)`
    background-color: #ffc94c;
    color: #141d38;
    border-radius: 30px;
    padding: 5px;
    width: 167px;
    font-size: 0.7rem;
    font-weight: bold;
    flex-wrap: wrap;
    align-items: center;
    display: flex;
`;

export const StarRatingButton = styled(Button)`
    margin: 3px 3px 3px 0px;
    padding: 8px;
    border-radius: 10px;
    color: ${(props) => (props.isactive ? "#fff" : "#000000")};
    border: 1px solid black;
    background: ${(props) => (props.isactive ? "#58A946" : "inherit")};
`;

export const GridContainer = styled(Grid)`
    height: calc(100% + 8px);
    @media (max-width: 900px) {
        height: auto;
    }
`;

export const FilterContainer = styled(Paper)`
    display: flex;
    justify-content: center;
    padding: 30px 10px;
    & .MuiInputBase-root {
        position: relative;
        top: 10px;
    }
`;

export const CarouselGrid = styled(Grid)`
    & div {
        height: 100%;
    }
    @media (max-width: 900px) {
        height: 300px;
        width: 100%;
        box-shadow: none;
    }
`;

export const CarouselContainer = styled(Carousel)`
    @media (min-width: 900px) {
        &.makeStyles-root-1 {
            height: 100%;
        }
        & .makeStyles-itemWrapper-3 {
            height: 100% !important;
        }
        & .makeStyles-item-2 {
            height: 100% !important;
        }
        & div {
            height: 100% !important;
        }
        & button {
            background-color: rgba(0, 0, 0, 0.54) !important;
        }
    }
`;

export const HotelContainer = styled(Grid)`
    box-shadow: 0 0 7px 0 hsl(0deg 0% 55% / 21%) !important;
    border-radius: 5px !important;
    margin-top: 20px;
`;

export const SlideshowContainer = styled(Grid)`
    background-image: ${(props) => `url(${props.image})`};
    background-size: cover;
    height: auto;
    background-position: center;
    border-radius: 5px;
    @media (max-width: 900px) {
        height: 300px;
    }
`;
export const LinkText = styled.div`
    color: #19b4d1;
    a {
        cursor: pointer;
    }
`;
