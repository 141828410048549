import moment from "moment";
import { useMemo } from "react";
import { _formatMoney } from "../../../config/utils";
import { getSiteValueByLang } from "../../../utils/SiteUtils";

export const usePaymentAmountDisplay = (reviewData, paymentPlan) =>
    useMemo(
        () => ({
            startDate: getStartDate(reviewData),
            frequency: getPaymentFrequency(reviewData.plan),
            moneyDisplay: getMoneyDisplay(reviewData, paymentPlan)
        }),
        [reviewData, paymentPlan]
    );

const getStartDate = (reviewData) => {
    if (!reviewData.startPaymentDate) {
        return "";
    }
    if (moment(reviewData.startPaymentDate).isSame(moment(), "day")) {
        return "today";
    }
    return moment(reviewData.startPaymentDate).format("ddd, D MMM");
};

const getPaymentFrequency = (plan) => {
    if (plan === 1) {
        return "Weekly";
    }
    return getSiteValueByLang("getPaymentFrequency");
};

const getMoneyDisplay = (reviewData, paymentPlan) => {
    if (reviewData.plan === 1) {
        return _formatMoney(paymentPlan.pricePerWeek / 100);
    }
    return _formatMoney(paymentPlan.pricePerFortnight / 100);
};
