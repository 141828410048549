import React, { Component } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col, Button } from "reactstrap";

class DropdownPassengers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdownOpen: false
        };
    }

    onToggle = (e) => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    };

    subAdult = () => {
        if (this.props.adults > 1) {
            this.props.onChangeAdults(this.props.adults - 1);
        }
    };

    addAdult = () => {
        if (this.props.adults + this.props.child + this.props.infants < 6) {
            this.props.onChangeAdults(this.props.adults + 1);
        }
    };

    subChildren = () => {
        if (this.props.child > 0) {
            this.props.onChangeChildren(this.props.child - 1);
        }
    };

    addChildren = () => {
        if (this.props.adults + this.props.child + this.props.infants < 6) {
            this.props.onChangeChildren(this.props.child + 1);
        }
    };

    subInfant = () => {
        if (this.props.infants > 0) {
            this.props.onChangeInfants(this.props.infants - 1);
        }
    };

    addInfant = () => {
        if (this.props.adults + this.props.child + this.props.infants < 6) {
            this.props.onChangeInfants(this.props.infants + 1);
        }
    };

    render() {
        return (
            <Dropdown
                isOpen={this.state.dropdownOpen}
                toggle={() => (!this.props.disableTravellers ? this.onToggle() : false)}
                className="SearchForm__dropdown dropdown-passengers"
            >
                <DropdownToggle caret color="success" className="SearchForm__dropdownToggleButton">
                    <div className="SearchForm__label">Passengers</div>
                    <div className="SearchForm__fieldValue">
                        {this.props.adults > 0 && (
                            <span className="info-passenger">
                                {this.props.adults} adult{this.props.adults > 1 ? "s" : ""}
                            </span>
                        )}
                        {this.props.child > 0 && (
                            <span className="info-passenger">
                                {this.props.adults > 0 && ", "}
                                {this.props.child} child{this.props.child > 1 ? "ren" : ""}
                            </span>
                        )}
                        {this.props.infants > 0 && (
                            <span className="info-passenger">
                                {(this.props.adults > 0 || this.props.child > 0) && ", "}
                                {this.props.infants} infant{this.props.infants > 1 ? "s" : ""}
                            </span>
                        )}
                    </div>
                </DropdownToggle>
                <DropdownMenu className="SearchForm__dropdownMenu">
                    <div className="item">
                        <Row>
                            <Col>
                                <span className="number">{this.props.adults}</span>
                                <span className="title">Adults</span>
                            </Col>
                            <Col xs="auto">
                                <Button
                                    onClick={this.subAdult}
                                    type="button"
                                    className="btn-count"
                                    disabled={this.props.adults === 0}
                                >
                                    -
                                </Button>
                                <Button onClick={this.addAdult} type="button" className="btn-count">
                                    +
                                </Button>
                            </Col>
                        </Row>
                    </div>
                    <div className="item">
                        <Row>
                            <Col>
                                <span className="number">{this.props.child}</span>
                                <span className="title">
                                    Children <span className="note">(2 to 11yrs)</span>
                                </span>
                            </Col>
                            <Col xs="auto">
                                <Button
                                    onClick={this.subChildren}
                                    type="button"
                                    className="btn-count"
                                    disabled={this.props.child === 0}
                                >
                                    -
                                </Button>
                                <Button onClick={this.addChildren} type="button" className="btn-count">
                                    +
                                </Button>
                            </Col>
                        </Row>
                    </div>
                    <div className="item">
                        <Row>
                            <Col>
                                <span className="number">{this.props.infants}</span>
                                <span className="title">
                                    Infants <span className="note">(under 2)</span>
                                </span>
                            </Col>
                            <Col xs="auto">
                                <Button
                                    onClick={this.subInfant}
                                    type="button"
                                    className="btn-count"
                                    disabled={this.props.infants === 0}
                                >
                                    -
                                </Button>
                                <Button onClick={this.addInfant} type="button" className="btn-count">
                                    +
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </DropdownMenu>
            </Dropdown>
        );
    }
}

export default DropdownPassengers;
