import { FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import React from "react";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import { AgeOptions } from "../../../constants/Options";
import { getTotalTravelers, handleChildAgeChange } from "./Util";

const RoomDetailCard = ({ roomInfo, index, handleValueChange, roomDetails }) => (
    <Grid container>
        <Grid item xs={12}>
            <Typography variant={"body1"} fontWeight={"bold"}>
                Room {index + 1}
            </Typography>
        </Grid>
        <Grid item xs={12}>
            <Grid container alignItems={"center"} justifyContent={"space-between"}>
                <Grid item xs={8}>
                    <Typography variant={"body1"}>Adults</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Grid display={"flex"} gap={"10px"} alignItems={"center"}>
                        <IconButton
                            disabled={roomInfo.adult === 1}
                            onClick={() => {
                                handleValueChange(roomInfo.id, "adults", roomInfo.adult - 1);
                            }}
                        >
                            <RemoveCircleOutlineOutlinedIcon
                                style={{ color: roomInfo.adult === 1 ? "#efefef" : "grey", fontSize: 30 }}
                            />
                        </IconButton>
                        <Typography variant="body1">{roomInfo.adult}</Typography>
                        <IconButton
                            disabled={
                                roomInfo.adult + roomInfo.children.length === 8 || getTotalTravelers(roomDetails) > 13
                            }
                            onClick={() => {
                                handleValueChange(roomInfo.id, "adults", roomInfo.adult + 1);
                            }}
                        >
                            <AddCircleOutlineOutlinedIcon
                                style={{
                                    color:
                                        roomInfo.adult + roomInfo.children.length === 8 ||
                                        getTotalTravelers(roomDetails) > 13
                                            ? "#efefef"
                                            : "grey",
                                    fontSize: 30
                                }}
                            />
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        <Grid mb={"20px"} item xs={12} justifyContent={"center"}>
            <Grid container alignItems={"center"} justifyContent={"space-between"}>
                <Grid item xs={8}>
                    <Typography variant={"body1"}>Children</Typography>
                    <Typography variant={"caption"}>Age 0 to 17</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Grid display={"flex"} gap={"10px"} alignItems={"center"}>
                        <IconButton
                            disabled={roomInfo.children.length === 0}
                            onClick={() => handleValueChange(roomInfo.id, "children", roomInfo.children.slice(0, -1))}
                        >
                            <RemoveCircleOutlineOutlinedIcon
                                style={{ color: roomInfo.children.length === 0 ? "#efefef" : "grey", fontSize: 30 }}
                            />
                        </IconButton>
                        <Typography variant="body1">{roomInfo.children.length}</Typography>
                        <IconButton
                            disabled={
                                roomInfo.adult + roomInfo.children.length === 8 || getTotalTravelers(roomDetails) > 13
                            }
                            onClick={() =>
                                handleValueChange(roomInfo.id, "children", [
                                    ...roomInfo.children,
                                    {
                                        id: `ch-${Math.random()}`,
                                        age: ""
                                    }
                                ])
                            }
                        >
                            <AddCircleOutlineOutlinedIcon
                                style={{
                                    color:
                                        roomInfo.adult + roomInfo.children.length === 8 ||
                                        getTotalTravelers(roomDetails) > 13
                                            ? "#efefef"
                                            : "grey",
                                    fontSize: 30
                                }}
                            />
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        <Grid item xs={12}>
            <Grid container spacing={"10px"}>
                {roomInfo.children.map((item, index) => (
                    <Grid mt={"10px"} item xs={6} key={item.id}>
                        <FormControl error={item.age === ""} fullWidth size="small">
                            <InputLabel>Child {index + 1} age</InputLabel>
                            <Select
                                value={item.age}
                                onChange={({ target }) =>
                                    handleValueChange(
                                        roomInfo.id,
                                        "children",
                                        handleChildAgeChange(roomInfo, target.value, index)
                                    )
                                }
                                label="Select age"
                            >
                                {AgeOptions.map(({ value, label }) => (
                                    <MenuItem key={value} value={value}>
                                        {label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                ))}
            </Grid>
        </Grid>
    </Grid>
);

export default RoomDetailCard;
