import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { Experiment, StubExperimentClient } from "@amplitude/experiment-js-client";

/**
 * @typedef {{ active: true, payload: * } | { active: false, payload: null }} Result
 */

/**
 * @typedef {Object} ContextValue
 * @property {function(string, string=): Result} isVariantActive - Checks if a variant is active.
 * @property {function(string, string=control): Result} isControlActive - Returns true if the user is in the experiment with variant `control`.
 */

/**
 * @implements {ContextValue}
 */
const amplitudeExperimentContextValue = (experiment) => {
    const isVariantActive = (flagKey, activeValue) => {
        const { value = "control", payload } = experiment.variant(flagKey);
        const active = activeValue ? value === activeValue : value !== "control";
        return { active, payload };
    };

    const isControlActive = (variant, controlVariantValue = "control") => isVariantActive(variant, controlVariantValue);

    return { isVariantActive, isControlActive };
};

const noOpContextValue = amplitudeExperimentContextValue(new StubExperimentClient())

/**
 * @type {React.Context<ContextValue | null>}
 */
const Context = createContext(null);

/**
 * @typedef {Object} AmplitudeExperimentProviderProps
 * @property {string} apiKey - The Amplitude API key.
 * @property {boolean} [debug=false] - Flag to enable debug mode.
 */

/**
 * AmplitudeExperimentProvider component.
 * @param {AmplitudeExperimentProviderProps} props - The props for the provider.
 * @returns {JSX.Element} The provider component.
 */
export const AmplitudeExperimentProvider = (props) => {
    const { apiKey, debug = false, children } = props;
    const [value, setValue] = useState(/** @type {ContextValue} */ noOpContextValue);

    useEffect(() => {
        const experiment = Experiment.initializeWithAmplitudeAnalytics(apiKey, {
            automaticFetchOnAmplitudeIdentityChange: true,
            debug
        });

        const start = async () => {
            await experiment.start();
            setValue(amplitudeExperimentContextValue(experiment));
        };

        const stop = async () => {
            await experiment.stop();
            setValue(noOpContextValue);
        };

        start().then(() => {
            if (debug) console.info("Amplitude Experiment started.");
        });

        return () => {
            stop().then(() => {
                if (debug) console.info("Amplitude Experiment stopped.");
            });
        };
    }, [apiKey, debug]);

    return useMemo(() => <Context.Provider value={value}>{children}</Context.Provider>, [value, children]);
};

/**
 * Custom hook to use the AmplitudeExperimentContextValue context.
 * @returns {ContextValue} The context value.
 * @throws {Error} If used outside an AmplitudeExperimentProvider.
 */
export const useAmplitudeExperiment = () => {
    const context = useContext(Context);

    if (!context) {
        throw new Error("useAmplitudeExperiment must be used within a AmplitudeExperimentProvider");
    }

    return context;
};
