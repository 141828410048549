import CloseIcon from "@mui/icons-material/Close";
import { Box, Dialog, Grid, IconButton } from "@mui/material";
import React from "react";
import { Text } from "../common/typography/Style";
import LoginForm from "../login/LoginForm";

const LoginModal = ({ toggleLoginModal, isOpen }) => {
    return (
        <Dialog
            PaperProps={{
                style: {
                    borderRadius: "20px"
                }
            }}
            open={isOpen}
            maxWidth={"xs"}
            fullWidth
            onClose={toggleLoginModal}
        >
            <Grid container>
                <Grid
                    position={"relative"}
                    borderBottom={`1px solid #dee2e6`}
                    item
                    alignItems={"center"}
                    xs={12}
                    display={"flex"}
                    py={"0.7rem"}
                    px={"1rem"}
                    justifyContent={"center"}
                >
                    <Box position={"absolute"} left={"10px"}>
                        <IconButton onClick={toggleLoginModal}>
                            <CloseIcon />
                        </IconButton>
                    </Box>

                    <Text size={"md"} textweight={"bold"}>
                        Welcome back!
                    </Text>
                </Grid>
                <Grid item xs={12} p={"1.8rem"}>
                    <LoginForm />
                </Grid>
            </Grid>
        </Dialog>
    );
};

export default LoginModal;
