import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import BlockIcon from "@mui/icons-material/Block";
import classNames from "classnames";
import * as moment from "moment";
import React, { Component } from "react";
import { Trans } from "react-i18next";
import { Button, Col, Row } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { _formatMoney, _url } from "../config/utils";
import { FLIGHT_SEARCH_MIN_DAYS_FROM_TODAY } from "../constants";
import FlightDetails from "./FlightDetails";
import ShowChangedFlightPrice from "./search/form/ShowChangedFlightPrice";
import { renderDetailFlight, renderFlightSegments } from "./search/results/SearchResultHooks";

class SearchResultBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            detailOpen: false
        };
    }

    toggleDetail = () => {
        this.setState({
            detailOpen: !this.state.detailOpen
        });
    };

    render() {
        const { searchResult } = this.props;
        const daysTillDeparture = searchResult
            ? moment(searchResult.itineraries[0].segments[0].departureAt.split("T")[0]).diff(moment(), "days") + 1
            : 0;

        return (
            <div key={uuidv4()} className={classNames("search-result-box", { "no-select": this.props.noSelect })}>
                <div className={classNames("search-result", { "detail-open": this.state.detailOpen })}>
                    <Row>
                        <Col className="left-content">
                            <Row>
                                {renderFlightSegments(searchResult)}
                                <Col xs={12} className="search-result-box__controls">
                                    <div className="flight-details-toggle">
                                        <div className="content-icon" onClick={this.toggleDetail}>
                                            Flight and baggage info
                                            <FontAwesomeIcon icon="angle-down" />
                                        </div>
                                    </div>
                                    {!this.props.noSelect && (
                                        <div className="search-result-box__highlights">
                                            {/* <span className="no-change-fees d-none d-md-block">
                        <FontAwesomeIcon icon="check" /> 30-day no change fees
                      </span> */}
                                            <span className="no-change-fees d-none d-md-block">
                                                {searchResult.itineraries[0].changesPermitted ? (
                                                    <AttachMoneyIcon style={{ fontSize: 16, marginBottom: 2 }} />
                                                ) : (
                                                    <BlockIcon
                                                        style={{ fontSize: 14, marginBottom: 2, marginRight: 2 }}
                                                    />
                                                )}
                                                Changes
                                            </span>
                                            <span className="no-change-fees d-none d-md-block">
                                                {searchResult.itineraries[0].cancellationsPermitted ? (
                                                    <AttachMoneyIcon style={{ fontSize: 16, marginBottom: 2 }} />
                                                ) : (
                                                    <BlockIcon
                                                        style={{ fontSize: 14, marginBottom: 2, marginRight: 2 }}
                                                    />
                                                )}
                                                <Trans>cancelation</Trans>
                                            </span>
                                            {/* {searchResult.includedCheckedBagsOnly && (
                        <span className="includes-checked-baggage d-none d-md-block" onClick={this.toggleDetail}>
                          <FontAwesomeIcon icon="check" />
                          {' '}
                          Includes checked baggage
                        </span>
                      )} */}
                                        </div>
                                    )}
                                </Col>
                            </Row>
                        </Col>
                        {!this.props.noSelect && (
                            <Col md="auto" className="right-content">
                                {this.props.currentFlightPrice ? (
                                    <ShowChangedFlightPrice
                                        searchResult={searchResult}
                                        currentFlightPrice={this.props.currentFlightPrice}
                                    />
                                ) : (
                                    <div>
                                        {renderDetailFlight(searchResult)}
                                        <p className="title">from</p>
                                        {daysTillDeparture < FLIGHT_SEARCH_MIN_DAYS_FROM_TODAY ? (
                                            <p className="pw">
                                                ${_formatMoney(searchResult.totalCompletePrice / 100)}
                                                <sup>{searchResult.currency.slice(0, -1)}</sup>
                                            </p>
                                        ) : (
                                            <div>
                                                <p className="pw">
                                                    ${_formatMoney(searchResult.pricePerWeek / 100)}
                                                    <sup>{searchResult.currency.slice(0, -1)}</sup>
                                                    /wk
                                                </p>
                                                <p className="total">
                                                    ${_formatMoney(searchResult.totalCompletePrice / 100)} total
                                                    <sup>{searchResult.currency.slice(0, -1)}</sup>
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                )}
                                <div>
                                    <Button onClick={() => this.props.onSelect(searchResult.id)} className="btn-select">
                                        Select
                                        <img alt="left-arrow" src={_url("assets/images/left-arrow.png")} />
                                    </Button>

                                    <span className="no-change-fees d-block d-md-none">
                                        {searchResult.itineraries[0].changesPermitted ? (
                                            <AttachMoneyIcon style={{ fontSize: 16, marginBottom: 2 }} />
                                        ) : (
                                            <BlockIcon style={{ fontSize: 14, marginBottom: 2, marginRight: 2 }} />
                                        )}
                                        Changes
                                    </span>
                                    <span className="no-change-fees d-block d-md-none">
                                        {searchResult.itineraries[0].cancellationsPermitted ? (
                                            <AttachMoneyIcon style={{ fontSize: 16, marginBottom: 2 }} />
                                        ) : (
                                            <BlockIcon style={{ fontSize: 14, marginBottom: 2, marginRight: 2 }} />
                                        )}
                                        <Trans>cancelation</Trans>
                                    </span>
                                </div>
                            </Col>
                        )}
                    </Row>
                </div>
                {this.state.detailOpen && (
                    <FlightDetails itinerary={searchResult} flights={searchResult.itineraries} type={this.props.type} />
                )}
            </div>
        );
    }
}

export default SearchResultBox;
