import { NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

export const CallToActionAcknowledgement = ({ paymentPlan }) => {
    const bottomLinks = (
        <div style={{ marginBottom: 10 }}>
            By clicking on the button below, I acknowledge that I have read and accept the{" "}
            <NavLink to="/terms" target="_blank" className="terms-of-use">
                Terms of Use
            </NavLink>
            ,{" "}
            <HashLink to="/terms#cancellation-policy" target="_blank" className="cancellation-policy">
                Cancelation Policy
            </HashLink>{" "}
            and{" "}
            <HashLink to="/privacy-policy" className="privacy-policy">
                Privacy Policy
            </HashLink>{" "}
            and I am responsible for adhering to any Government travel advice.
        </div>
    );

    if (!paymentPlan || !paymentPlan.terms) {
        return bottomLinks;
    }

    return bottomLinks;
};

