import { Box, Divider, Grid } from "@mui/material";
import React from "react";
import { Trans } from "react-i18next";
import { Text } from "../../../components/common/typography/Style";
import { ColorTheme } from "../../../styles/color";
import { logAmplitudeEvent } from "../../../utils/log-amplitude-event";
import { dividePaymentPlanInRange } from "./divide-payment-plan-in-range";
import { DepositAmountButton } from "./Style";
import { displayPercent } from "./display-percent";

export const PaymentDepositeSlider = ({ onUpdatePaymentPlan, reviewData, setReviewData, minimumDepositValue }) => {
    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <Text size={"lg"} textweight={"semiBold"}>
                        Select your deposit amount
                    </Text>
                    <Text size={"sm"} textweight={"regular"}>
                        Pro tip: a greater deposit can significantly lower your <Trans>installments</Trans>!{" "}
                    </Text>
                    <Box mt={"10px"} display={"flex"} gap={"8px"} overflow={"auto"}>
                        {!!minimumDepositValue &&
                            !!reviewData.depositPercent &&
                            dividePaymentPlanInRange(
                                minimumDepositValue.minDepositPercentage,
                                minimumDepositValue.maxDepositPercentage,
                                10
                            ).map((item) => (
                                <DepositAmountButton
                                    isactive={reviewData.depositPercent === item ? 1 : 0}
                                    minWidth={"99px"}
                                    key={item}
                                    onClick={() => {
                                        if (reviewData.depositPercent !== item) {
                                            setReviewData({ ...reviewData, depositPercent: item });
                                        }
                                        logAmplitudeEvent("adjustDepositAmount", { depositAmount: item });
                                        onUpdatePaymentPlan({ ...reviewData, depositPercent: item });
                                    }}
                                >
                                    <Text
                                        size={"md"}
                                        textweight={"semiBold"}
                                        textcolor={
                                            reviewData.depositPercent === item
                                                ? ColorTheme.base.white
                                                : ColorTheme.grayNeutral[400]
                                        }
                                    >
                                        { displayPercent({ value: item, lowAmountReplace: { threshold: 9, replacement: 'AU$49.00' } }) }
                                    </Text>
                                </DepositAmountButton>
                            ))}
                    </Box>
                    <Box my={["20px", "20px", "28px"]}>
                        <Divider />
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};
