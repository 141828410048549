import { Box, Grid } from "@mui/material";
import { PrimaryButton } from "../../../components/common/button/ButtonStyle";
import { useGetWindowSize } from "../../../components/common/hook/WindowSize";
import { DisplayText, Text } from "../../../components/common/typography/Style";
import { _url } from "../../../config/utils";
import { ColorTheme } from "../../../styles/color";
import { customerFiveStarStories } from "../util";
import CustomerFiveStarCard from "./CustomerFiveStarCard";

const TestimonialHeader = () => {
    const { desktopView } = useGetWindowSize();
    return (
        <Grid container columnSpacing={"40px"} justifyContent={"flex-end"}>
            <Grid item xs={12} md={5}>
                <DisplayText
                    textheight={desktopView ? undefined : "42px"}
                    size={desktopView ? "lg" : "md"}
                    textweight={"semiBold"}
                    className="display-text"
                >
                    Trusted by thousands of travellers all around the world
                </DisplayText>
                <Text
                    mb={"32px"}
                    mt={"16px"}
                    textcolor={ColorTheme.grayNeutral[600]}
                    size={"md"}
                    textweight={"regular"}
                    className="description-text"
                >
                    From seamless booking experiences to the flexibility of paying later, our mission is to make travel
                    accessible and worry-free. Join the ranks of satisfied travelers who have placed their trust and
                    experience the difference with us.
                </Text>
                <PrimaryButton className="book-flight" fullWidth style={{ width: "294px" }}>
                    Book a flight
                </PrimaryButton>
            </Grid>
            <Grid item xs={12} md={6} position={"relative"} bottom={["-40px", "-40px", "60px"]}>
                <Grid container columnSpacing={"16px"}>
                    <Grid item xs={12} md={6}>
                        <Grid container>
                            <Grid item xs={12}>
                                <img
                                    style={{
                                        height: 44,
                                        width: "100%",
                                        objectFit: "cover",
                                        borderRadius: "10px 10px 0px 0px"
                                    }}
                                    width={"100%"}
                                    src={_url("assets/redesign/icons/rectangle_green.svg")}
                                />
                            </Grid>
                            {customerFiveStarStories.slice(0, 2).map((review) => (
                                <Grid item xs={12} mt={"8px"}>
                                    <CustomerFiveStarCard review={review} />
                                </Grid>
                            ))}
                            {desktopView && (
                                <Grid item xs={12}>
                                    <img
                                        style={{
                                            marginTop: 20,
                                            height: 36,
                                            width: "100%",
                                            objectFit: "cover",
                                            borderRadius: "10px 10px 0px 0px"
                                        }}
                                        width={"100%"}
                                        src={_url("assets/redesign/icons/rectangle_green.svg")}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6} position={"relative"} bottom={["0px", "0px", "10px"]}>
                        <Grid container>
                            {desktopView && (
                                <Grid item xs={12} pr={["0px", "0px", "20px"]}>
                                    <img width={"100%"} src={_url("assets/redesign/icons/rectangle_green.svg")} />
                                </Grid>
                            )}
                            {customerFiveStarStories.slice(2, 4).map((review) => (
                                <Grid item xs={12} pr={["0px", "0px", "20px"]} mt={"8px"}>
                                    <CustomerFiveStarCard review={review} />
                                </Grid>
                            ))}
                            <Grid item xs={12} mt={["15px", "15px", "10px"]} pr={["0px", "0px", "20px"]}>
                                <img
                                    style={{
                                        transform: "rotate(180deg)",
                                        height: desktopView ? 20 : 44,
                                        width: "100%",
                                        objectFit: "cover",
                                        borderRadius: desktopView ? "0px 0px 10px 10px" : "10px 10px 0px 0px"
                                    }}
                                    width={"100%"}
                                    src={_url("assets/redesign/icons/rectangle_green.svg")}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    {desktopView && (
                        <Grid item xs={1} position={"absolute"} top={"-40px"} right={0}>
                            {Array(4)
                                .fill(1)
                                .map((_, index) => (
                                    <Box mt={"20px"}>
                                        <img key={index} src={_url("assets/redesign/icons/rectangle_vertical.svg")} />
                                    </Box>
                                ))}
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default TestimonialHeader;
