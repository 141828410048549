import { Box } from "@mui/material";
import { LinkText, Text } from "../../../components/common/typography/Style";
import { _url } from "../../../config/utils";
import { ColorTheme } from "../../../styles/color";

const StoryCard = ({ testimonial, name, link }) => {
    return (
        <Box p={["0px", "0px", "50px"]} width={["92vw", "92vw", "957px"]}>
            <Box position={"relative"} top={"50px"} right={["-10px", "-10px", "40px"]}>
                <img src={_url("assets/redesign/icons/comma_icon.svg")} />
            </Box>
            <Box px={["16px", "16px", "64px"]} py={["64px", "64px", "64px"]} bgcolor={ColorTheme.grayNeutral[100]}>
                <div
                    dangerouslySetInnerHTML={{
                        __html: testimonial
                    }}
                    className="testimonial"
                />
                <Box
                    mt={"24px"}
                    display={"flex"}
                    flexDirection={["column", "column", "row"]}
                    justifyContent={"space-between"}
                >
                    <Box my={["24px", "24px", "0px"]}>
                        <Text className="user_name" size={"lg"} textweight={"semiBold"}>
                            -{name}
                        </Text>
                    </Box>
                    <Box alignItems={"center"} display={"flex"} gap={"5px"}>
                        <LinkText onClick={() => window.open(link, "_blank")} className="link_text">
                            Check this on
                        </LinkText>
                        <img
                            onClick={() => window.open(link, "_blank")}
                            style={{ cursor: "pointer" }}
                            src={_url("assets/redesign/icons/trustpilot_story.svg")}
                        />
                        <img
                            style={{ cursor: "pointer" }}
                            onClick={() => window.open(link, "_blank")}
                            src={_url("assets/redesign/icons/arrow_export.svg")}
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default StoryCard;
