import axios from 'axios';
import { API_URL } from './index';

const instance = axios.create({
  baseURL: API_URL,
  withCredentials: true,
});

instance.interceptors.response.use(
  (response) => {
    if (response.status >= 200 && response.status < 300) {
      console.log(`Response Body (${response.status}):`, response.data);
    }
    return response;
  },
  (error) => {
    const { response } = error;

    if (response) {
      // Log client error responses (400 status codes)
      if (response.status >= 400) {
        console.log(`Response Body (${response.status}):`, response.data);
      }

      // if (response.status === 403 || response.status === 401) {
      //   store.dispatch(logoutAuth());
      // }
    } else {
      // Log network or other errors
      console.log('Error:', error.message);
    }
    return Promise.reject(error);
  },
);

export default instance;
