/* eslint-disable react-hooks/exhaustive-deps */
import {
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MAX_HOTEL_LIST } from "../../../constants";
import { StaySortOptions } from "../../../constants/Options";
import { getDiffDays } from "../../../utils/SiteUtils";
import { HeadingTypography, LinkText } from "../../common/CommonStyle";
import LoadingShimmerRows from "../../common/LoadingShimmerRows";
import HotelSearchFilter from "../filter/HotelSearchFilter";
import MobileStaySearchFilter from "../filter/mobilefilter/MobileStaySearchFilter";
import StaySearchForm from "../searchform/StaySearchForm";
import HotelCard from "./HotelCard";
import { FilterContainer } from "./Style";
import { getFilteredHotelList, getStaysList, sortHotelList } from "./Util";

const HotelSearchView = ({
    locationCode,
    checkindate,
    checkoutdate,
    roomInfo,
    isBookingStays,
    hideSort,
    bookingStaysDesign
}) => {
    const searchParams = new URLSearchParams(window.location.search);
    const [hotelList, setHotelList] = React.useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [sortOrder, setSortOrder] = useState(isBookingStays ? "RECOMMENDED" : "PRICE_LOW_TO_HIGH");
    const [selectedFilter, setSelectedFilter] = useState({
        searchText: "",
        priceFilter: [],
        propertyRating: "",
        guestRating: "0",
        filterSelected: false
    });
    const [listLength, setListLength] = useState(MAX_HOTEL_LIST);
    const theme = useTheme();
    const mobileView = useMediaQuery(theme.breakpoints.down(900));
    const [stayInterval, setStayInterval] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        setIsLoading(true);
        setStayInterval(getDiffDays(searchParams.get("checkindate"), searchParams.get("checkoutdate")));
        getStaysList(
            {
                locationCode: searchParams.get("locationCode") || locationCode,
                checkInDate: searchParams.get("checkindate") || checkindate,
                checkOutDate: searchParams.get("checkoutdate") || checkoutdate,
                roomInfo: searchParams.get("roominfo") || roomInfo,
                isFromPortal: isBookingStays || false
            },
            (res) => {
                const hotelsList = isBookingStays ? res.splice(0, 2) : res;
                setHotelList(hotelsList);
                const list = sortHotelList(hotelsList, "PRICE_LOW_TO_HIGH");
                list.length > 0 &&
                    setSelectedFilter((prev) => ({
                        ...prev,
                        priceFilter: [
                            parseFloat(list[0].chepestRoom.total) - 1,
                            parseFloat(list[list.length - 1].chepestRoom.total) + 1
                        ]
                    }));
                setIsLoading(false);
            },
            () => {
                setHotelList([]);
                setIsLoading(false);
            }
        );
    }, [
        searchParams.get("locationCode"),
        searchParams.get("checkindate"),
        searchParams.get("checkoutdate"),
        searchParams.get("roominfo")
    ]);

    const sortHotelSearchList = (value) => {
        setSortOrder(value);
    };

    const getShowMoreLessButton = () => {
        if (hotelList.length <= MAX_HOTEL_LIST) {
            return null;
        }
        if (hotelList.length > listLength) {
            return (
                <Button style={{ fontSize: 20 }} onClick={() => setListLength(() => listLength + 4)}>
                    Show More Results
                </Button>
            );
        }
        return (
            <Button style={{ fontSize: 20 }} onClick={() => setListLength(() => MAX_HOTEL_LIST)}>
                Show Less Results
            </Button>
        );
    };

    const renderListSection = () => {
        if (isLoading) {
            return null;
        }
        if (mobileView) {
            return <MobileStaySearchFilter selectedFilters={selectedFilter} setSelectedFilters={setSelectedFilter} />;
        }
        return <HotelSearchFilter selectedFilters={selectedFilter} setSelectedFilters={setSelectedFilter} />;
    };

    const moveToStayDescription = (hotelId, hotelInfo) => {
        const destination = {
            id: hotelInfo.regionId,
            countryCode: hotelInfo.fullAddress.country,
            name: hotelInfo.region,
            countryName: hotelInfo.fullAddress.countryName
        };
        if (isBookingStays) {
            navigate(
                `/stays/detail?destination=${JSON.stringify(destination)}&locationCode=${hotelInfo.regionId}&location=${
                    hotelInfo.region
                }&checkindate=${checkindate}&checkoutdate=${checkoutdate}&roominfo=${roomInfo}&hotelId=${hotelId}&isFromPortal=true`
            );
            return;
        }
        navigate(`/stays/detail${window.location.search}&hotelId=${hotelId}`);
    };

    const moveToAllStayList = (hotelInfo) => {
        if (hotelInfo) {
            const destination = {
                id: hotelInfo.regionId,
                countryCode: hotelInfo.fullAddress.country,
                name: hotelInfo.region,
                countryName: hotelInfo.fullAddress.countryName
            };
            navigate(
                `/stays/search?destination=${JSON.stringify(destination)}&locationCode=${hotelInfo.regionId}&location=${
                    hotelInfo.region
                }&checkindate=${checkindate}&checkoutdate=${checkoutdate}&roominfo=${roomInfo}&isFromPortal=true`
            );
            return;
        }
    };

    return (
        <div>
            {!isBookingStays && (
                <FilterContainer>
                    <Grid container justifyContent={"center"}>
                        <Grid item xs={12} md={10} style={{ maxWidth: 1140 }}>
                            <StaySearchForm
                                search={{
                                    roomDetails: JSON.parse(searchParams.get("roominfo")),
                                    location: searchParams.get("location"),
                                    locationCode: searchParams.get("locationCode"),
                                    checkindate: searchParams.get("checkindate"),
                                    checkoutdate: searchParams.get("checkoutdate"),
                                    destination: JSON.parse(searchParams.get("destination"))
                                }}
                            />
                        </Grid>
                    </Grid>
                </FilterContainer>
            )}
            <Grid
                container
                justifyContent="center"
                style={{
                    background: isBookingStays ? "inherit" : "#E3ECED",
                    minHeight: isBookingStays ? "fit-content" : "calc(100vh - 171px)"
                }}
            >
                <Grid item xs={12} md={11} style={{ maxWidth: 1440 }}>
                    <Grid container spacing={2}>
                        {!isBookingStays && (
                            <Grid item xs={12} md={3}>
                                {/* <Map mapSrc={mapSrc} mapWidth="100%" height="143px" /> */}
                                {/* <Divider /> */}
                                {renderListSection()}
                            </Grid>
                        )}
                        <Grid
                            item
                            xs={12}
                            md={isBookingStays ? 12 : 9}
                            style={{ marginTop: bookingStaysDesign ? 0 : 30 }}
                        >
                            <Grid container>
                                {!hideSort && (
                                    <Grid item xs={12}>
                                        <Grid alignItems={"center"} p={1} container justifyContent="space-between">
                                            <Grid item display={"flex"} alignItems={"center"} flexWrap={"wrap"}>
                                                <HeadingTypography variant="h6" sx={{ fontWeight: { md: "bold" } }}>
                                                    Choose your hotel
                                                </HeadingTypography>
                                                {isBookingStays && hotelList.length > 0 && (
                                                    <LinkText onClick={() => moveToAllStayList(hotelList?.[0])}>
                                                        {" "}
                                                        &nbsp;<a className="back-to-results">(See all properties)</a>
                                                    </LinkText>
                                                )}
                                            </Grid>
                                            <Grid item mt={["30px", "0px"]}>
                                                <FormControl sx={{ width: { xs: "320px", md: "280px" } }} fullWidth>
                                                    <InputLabel id="sort-by">Sort By</InputLabel>
                                                    <Select
                                                        onChange={({ target }) => sortHotelSearchList(target.value)}
                                                        value={sortOrder}
                                                        label="Sort By"
                                                    >
                                                        {StaySortOptions.map((option) => (
                                                            <MenuItem key={option.value} value={option.value}>
                                                                {option.label}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}

                                <Grid item xs={12} style={{ marginBottom: 30 }}>
                                    <LoadingShimmerRows isLoading={isLoading} noOfCards={2}>
                                        {hotelList.length > 0 ? (
                                            <>
                                                {getFilteredHotelList(hotelList, selectedFilter, sortOrder)
                                                    .slice(0, listLength)
                                                    .map((propertyData) => (
                                                        <HotelCard
                                                            stayInterval={stayInterval}
                                                            key={propertyData.hotelId}
                                                            propertyData={propertyData}
                                                            moveToStayDescription={(hotelId) =>
                                                                moveToStayDescription(hotelId, propertyData)
                                                            }
                                                            bookingStaysDesign={bookingStaysDesign}
                                                            isBookingStays={isBookingStays}
                                                        />
                                                    ))}
                                                <Grid style={{ marginTop: 30 }} container justifyContent={"center"}>
                                                    {getShowMoreLessButton()}
                                                </Grid>
                                            </>
                                        ) : (
                                            <Grid marginTop={"40px"} container justifyContent="center">
                                                <Grid item>
                                                    <Typography
                                                        bgcolor={"#FBE5E8"}
                                                        padding={"10px 20px"}
                                                        borderRadius={"5px"}
                                                        variant="h6"
                                                        style={{ color: "red" }}
                                                        sx={{ fontWeight: { md: "bold" } }}
                                                    >
                                                        NO AVAILABILITY
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        )}
                                    </LoadingShimmerRows>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default HotelSearchView;
