import InternalApi from "../../../config/internalApi";
import { getStaysSearchResultApiUrl } from "../../../constants/APIConstants";

const transformData = (res) => {
    if (!res) {
        return [];
    }
    return res.map((item) => ({
        id: item.id,
        countryCode: item.country_code,
        name: item.name_full,
        countryName: item.country_name
    }));
};
export const getSearchedLocation = (text, successCallBack, errorCallback) => {
    InternalApi.get(getStaysSearchResultApiUrl(text))
        .then((res) => {
            successCallBack(transformData(res));
        })
        .catch(() => errorCallback());
};
