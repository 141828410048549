export const TestimonialSectionCards = [
    {
        id: 1,
        icon: "budget.svg",
        title: "Budget",
        description: "Our payment plans mean the price of your ticket is spread over many weeks!"
    },
    {
        id: 2,
        icon: "best_price.svg",
        title: "Cheaper Price",
        description: "You get to lock in today’s price for just a small price protecting you from price hikes"
    },
    {
        id: 3,
        icon: "customer_service.svg",
        title: "Good customer support",
        description: "We provide 24/7 customer support via chat and call. We are here to make your travels smooth."
    }
];

export const customerStories = [
    {
        id: 1,
        user: "Nadia",
        testimonial: `<p>I was nervous about using PayLater for my trip abroad, but it all ended up working out great. I paid my flight off on Wednesday night and had my e-ticket by 10:00 AM Thursday morning. I was able to find my flight on the airline website and confirm that everything was valid. I was anxious because I saw some reviews saying it might take longer, but it was really quick.The flight was obviously a little pricier than the airline website but worth it to have my tickets secured and pay in payments.I'm very picky about making any recommendations but would definitely recommend them to anyone who wants to take a nice trip but needs the payment flexibility.</p>`,
        link: "https://au.trustpilot.com/reviews/6568b306db5904cb1daed965"
    },
    {
        id: 2,
        user: "Drey",
        testimonial: `<p>
        Pay Later Travel is Legit,
        </p>
        <p>
        At first I was a bit skeptical that I wasn’t going to receive my E-ticket. But I received it less than 24-48 hours.
        </p>
        <p>
        In order to purchase yours,
        </p>
        <p>
        Firstly make a deposit, set up a payment plan either weekly or Fortnightly with a 20-50% optional choice. Once you complete paying off your booking, your E-ticket is on the way.
        </p>
        <p>
        I believe Pay Later Travel was Easy and Simple to Follow and i definitely recommend using it again in the future. Customer service were also 100% on to every message I’ve requested and my experience was definitely well presented.
        </p>
        `,
        link: "https://au.trustpilot.com/reviews/6539f06d729348d0986f8014"
    },
    {
        id: 3,
        user: "Shay",
        testimonial: `<p>I recently had the pleasure of using Pay Later services for my travel needs, and I must say that it has transformed the way I plan and book my trips. This innovative payment option offers convenience, flexibility, and peace of mind like never before.One of the aspects I appreciate most is the transparency and absence of hidden fees. Pay Later clearly lays out the terms, including the due date and any potential interest charges. This upfront information empowers me to make informed decisions, and I've never encountered any surprises or additional costs.</p>`,
        link: "https://au.trustpilot.com/reviews/654515e1ccd97e887b1d8a7c"
    },
    {
        id: 4,
        user: "Cee",
        testimonial: `<p>I have used Paylater travel for almost 2 years now and they have been great! I have always made payments on time however, i moved interstate and my financial situation changed a bit and had to delay my payments because of that. Paylater travel were lovely and helped me create a goal to achieve paying off my trip and I made sure I did thanks to their great customer service! Thank you so much for your support and service guys. Will use again in the future for sure! 😊</p>`,
        link: "https://au.trustpilot.com/reviews/654881e65fe90be1ecf0c922"
    }
];

export const customerFiveStarStories = [
    {
        id: 1,
        user: "Laura",
        testimonial: `<p>
        I love love love this site!!! It's awesome and helps prepare for those trips you plan on advance. I recommend this site to everyone I know for travel.
        </p>`,
        link: "https://au.trustpilot.com/reviews/656877b3b985e199b3c8f173"
    },
    {
        id: 3,
        user: "Terry",
        testimonial: `<p>I really appreciated these done to us helping us for our reservation with out this option I cannot afford paying upfront. Thank Pay Later Travel. This is a huge helped for me to purchased our tickets through your company. Thank you Joy for helping me today and giving me advise before our travel or journey to LA.</p>`,
        link: "https://au.trustpilot.com/reviews/654515e1ccd97e887b1d8a7c"
    },
    {
        id: 2,
        user: "Andrea",
        testimonial: `<p>
        Payment was smooths and the E-ticket process was very quick as well. I called customer service and they were able to provide them to me. I enjoyed the experience and will definitely use it again.
        </p>
        `,
        link: "https://au.trustpilot.com/reviews/656ddc7f6026e69d05d3e674"
    },

    {
        id: 4,
        user: "Amanda",
        testimonial: `<p>After a bumpy start not caused from Pay travel, but from a third party. The people from pay travel have gone above and beyond to rectify my issues. Very grateful for the correction in refund. And a small credit of apology goes along way.. Will diffently use again . Thanks again to all involved</p>`,
        link: "https://au.trustpilot.com/reviews/654881e65fe90be1ecf0c922"
    }
];
