import { US_RECENT_BOOKINGS, US_SITE_POPULAR_ARRIVAL, US_SITE_POPULAR_DEPARTURE } from "../constants/Options";
import { usaPopularDestination } from "../containers/redesign/homepage/constant";

export const US_CONSTANT = {
    country: "us",
    placesAutocompletedLocation: {
        lat: 31,
        lng: -100
    },
    addressCountry: "United States",
    billingAddressSelectStates: "US",
    getPaymentFrequency: "Bi-weekly",
    passportOrigin: "US",
    bookingsEmail: "support@paylatertravel.com",
    desktopPicker: "MM/dd/yyyy",
    phoneInputWrapper: "us",
    trustpilot: "https://www.trustpilot.com/review/paylatertravel.com",
    homeflightMockup: "assets/images/paylaterflightswebusa.png",
    question: {
        description: "If you have any questions, pleae contact us via chat or email support@paylatertravel.com."
    },
    countryCode: "102",
    bookingReview: "https://au.trustpilot.com/review/paylatertravel.com",
    currencyCode: "USD",
    stateOrigin: "US",
    questionChat: {
        description: "Questions? Contact us via chat or call us on",
        telephoneNo: "+1 (888) 974 0329"
    },
    supportMail: "support@paylatertravel.com",
    madeWithLove: "",
    privacyPolicy: "UsPrivacyPolicy",
    formikMuiDateFormat: "MM/DD/YYYY",
    hotelLink: "https://www.booking.com/index.html?aid=1826066",
    cardRental: "https://www.gotrentalcars.com/us-label/?agence_id=5890",
    phoneAutoFormat: true,
    paymentPlan: (value) => `x${value}`,
    reviewSupport: "ReviewSupportUs",
    gaMeasuremntId: "G-P3RJJR6Z0Z",
    showTravelInsurance: true,
    popularDestination: usaPopularDestination,
    popularDepartureDestination: US_SITE_POPULAR_DEPARTURE,
    popularArrivalDestination: US_SITE_POPULAR_ARRIVAL,
    recentBookings: US_RECENT_BOOKINGS,
    termsAndConditions: "https://paylatertravel.notion.site/United-States-Customer-Terms-and-Conditions-7e43ec2d4cd94c2988905a8a5d54e595",
    dateOfBirthExample: "12/30/1997"
};
