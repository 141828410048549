import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enAU from "./translations/enAu.json";
import enUS from "./translations/enUs.json";

i18n.use(initReactI18next).init({
    resources: {
        enUS: { translation: enUS },
        enAU: { translation: enAU }
    },
    lng: "enUS", // Default language
    fallbackLng: "enUS", // Fallback language in case the selected language doesn't have translations
    interpolation: {
        escapeValue: false
    }
});
