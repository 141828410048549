const moment = require("moment");

export const isSearchDateLessThanAllowed = ({
    todayDate,
    flightSearchMinDaysFromToday,
    searchSelectedDepartureDate
}) => {
    const minimumAllowedDepartureDate = moment(todayDate, "YYYY-MM-DD").add(flightSearchMinDaysFromToday, "days");
    const selectedDepartureDate = moment(searchSelectedDepartureDate, "YYYY-MM-DD");
    return selectedDepartureDate < minimumAllowedDepartureDate;
};
