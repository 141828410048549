import { Box, useMediaQuery, useTheme } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import moment from "moment";
import * as React from "react";
import { FEATURE_FLAG_MOBILE_NEW_DATEPICKER } from "../../../constants";
import CustomMobileDateRangePicker from "./CustomMobileDateRangePicker";
import { StyledDatePickerTextField } from "./Style";

const BasicDateRangePicker = ({
    startDate,
    endDate,
    startDateLabel,
    endDateLabel,
    handleDateChange,
    minimumDate,
    maximumDate,
    textFieldType
}) => {
    const [openModal, setOpenModal] = React.useState(false);

    const theme = useTheme();
    const mobileView = useMediaQuery(theme.breakpoints.down(900));

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} localeText={{ start: startDateLabel, end: endDateLabel }}>
            <DateRangePicker
                value={[startDate, endDate]}
                onChange={(newValue) => {
                    handleDateChange({
                        startDate: new Date(newValue[0]),
                        endDate: newValue[1] ? new Date(newValue[1]) : null
                    });
                }}
                onOpen={() => {
                    FEATURE_FLAG_MOBILE_NEW_DATEPICKER && mobileView && setOpenModal(true);
                }}
                open={FEATURE_FLAG_MOBILE_NEW_DATEPICKER && mobileView ? false : undefined}
                inputFormat={"DD MMM"}
                maxDate={maximumDate ? new Date(maximumDate) : new Date(moment().add("12", "months"))}
                minDate={minimumDate ? new Date(minimumDate) : new Date()}
                renderInput={(startProps, endProps) => (
                    <Box display={"flex"} gap={"10px"}>
                        <StyledDatePickerTextField
                            placeholder={"Add Date"}
                            textfieldtype={textFieldType}
                            style={{ background: "white", borderRadius: 10, width: "100%" }}
                            {...startProps}
                            inputProps={{
                                ...startProps.inputProps,
                                placeholder: "Add Date"
                            }}
                            InputLabelProps={{
                                shrink: true
                            }}
                        />
                        <StyledDatePickerTextField
                            style={{ marginLeft: 5, borderRadius: 10, background: "white", width: "100%" }}
                            {...endProps}
                            textfieldtype={textFieldType}
                            inputProps={{
                                ...endProps.inputProps,
                                placeholder: "Add Date"
                            }}
                            InputLabelProps={{
                                shrink: true
                            }}
                        />
                    </Box>
                )}
            />

            <CustomMobileDateRangePicker
                endDate={endDate}
                maximumDate={maximumDate}
                isOpen={openModal}
                minimumDate={minimumDate}
                startDate={startDate}
                handleClose={() => setOpenModal(false)}
                handleDateChange={handleDateChange}
            />
        </LocalizationProvider>
    );
};

export default BasicDateRangePicker;
