import { AU_RECENT_BOOKINGS, AU_SITE_POPULAR_ARRIVAL, AU_SITE_POPULAR_DEPARTURE } from "../constants/Options";
import { auPopularDestination } from "../containers/redesign/homepage/constant";

export const NZ_CONSTANT = {
    country: "nz",
    placesAutocompletedLocation: {
        lat: -41,
        lng: 175
    },
    addressCountry: "New Zealand",
    billingAddressSelectStates: "NZ",
    getPaymentFrequency: "Fortnightly",
    passportOrigin: "NZ",
    stripePromise: "stripePromiseAU",
    bookingsEmail: "support@paylatertravel.com",
    desktopPicker: "dd/MM/yyyy",
    phoneInputWrapper: "nz",
    trustpilot: "https://www.trustpilot.com/review/paylatertravel.com.au", // TODO: update this to use co.nz
    homeflightMockup: "assets/images/paylaterflightsweb.png",
    question: {
        description: "If you have any questions please contact on",
        telephoneNo: "+ 61 1300 399 902", // TODO: Update the telephoneNo to dedicated NZ number
        email: "or email support@paylatertravel.com"
    },

    countryCode: "28",
    bookingReview: "https://www.trustpilot.com/review/paylatertravel.com.au", // TODO: update this to use co.nz
    currencyCode: "NZD",
    stateOrigin: "NZ",
    questionChat: {
        description: "Questions? Chat or contact us:",
        telephoneNo: "61 1300 399 902"
    },
    supportMail: "support@paylatertravel.com",
    privacyPolicy: "AuPrivacyPolicy", // TODO: update privacy policy for NZ version
    formikMuiDateFormat: "DD/MM/YYYY",
    hotelLink: "https://www.booking.com/index.html?aid=1826066",
    cardRental: "https://www.driveaway.com.au/affiliates/1014025",
    phoneAutoFormat: false,
    paymentPlan: (value) => `${value} fortnight(s)`,
    reviewSupport: "ReviewSupportNZ", // TODO: customise more in ReviewSupportNZ
    gaMeasuremntId: "G-Q2KV8PSEVF", // a new GA measurement id
    showTravelInsurance: false,
    popularDestination: auPopularDestination, // TODO fix with NZ content
    popularDepartureDestination: AU_SITE_POPULAR_DEPARTURE, // TODO fix with NZ content
    popularArrivalDestination: AU_SITE_POPULAR_ARRIVAL, // TODO fix with NZ content
    recentBookings: AU_RECENT_BOOKINGS, // TODO fix with NZ content
    termsAndConditions: "https://paylatertravel.notion.site/Australia-Customer-Terms-and-Conditions-b85da50e066645008bbc659e74e918df", // TODO fix with NZ content
    dateOfBirthExample: "30/12/1997"
};
