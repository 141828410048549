export const displayPercent = ({ value /* between 0 and 100 */, precision = 2, trimTrailingZeros = true, lowAmountReplace }) => {
    if (value === null || value === undefined) {
        return '';
    }

    if (lowAmountReplace && value <= lowAmountReplace.threshold) {
        return lowAmountReplace.replacement;
    }

    let formatted = value.toFixed(precision);
    if (trimTrailingZeros) {
        formatted = parseFloat(formatted).toString();
    }

    return formatted + '%';
}
