export const dividePaymentPlanInRange = (start, end, interval) => {
    const result = [start];

    for (let i = start + interval; i <= end; i += interval) {
        result.push(Math.round(i));
    }

    if (result[result.length - 1] !== end) {
        result.push(end);
    }

    return result;
};
