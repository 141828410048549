import { Grid } from "@mui/material";
import { Text } from "../../../components/common/typography/Style";
import { _url } from "../../../config/utils";
import { ColorTheme } from "../../../styles/color";

const SectionCard = ({ icon, title, description }) => {
    return (
        <Grid
            bgcolor={ColorTheme.secondary[50]}
            p={"32px"}
            container
            height={"100%"}
            borderBottom={`2px solid ${ColorTheme.secondary[500]}`}
        >
            <Grid item xs={12}>
                <img className="section_card_icon" src={_url(`assets/redesign/icons/${icon}`)} />
            </Grid>
            <Grid item xs={12} mt={"24px"}>
                <Text className="section_card_title" size={"xl"} textweight={"bold"}>
                    {title}
                </Text>
                <Text
                    className="section_card_description"
                    mt={"16px"}
                    textcolor={ColorTheme.grayNeutral[600]}
                    textweight={"regular"}
                    size={"sm"}
                >
                    {description}
                </Text>
            </Grid>
        </Grid>
    );
};

export default SectionCard;
