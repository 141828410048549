import { Box, Divider, Grid } from "@mui/material";
import React, { useState } from "react";
import { Text } from "../../../../components/common/typography/Style";
import { _url } from "../../../../config/utils";
import { ColorTheme } from "../../../../styles/color";
import { MobileDatepickerContainer } from "../style";
import SearchDestinationModal from "./SeacrhDestinationModal";

const FlightSearchTextField = ({
    id,
    placeholderLeaving,
    placeholderDestination,
    updateLeavingFrom,
    updateDestination,
    leaving,
    destination
}) => {
    const [openSearchModal, setOpenSearchModal] = useState(null);

    const selectFlight = (data) => {
        if (openSearchModal === "origin") {
            updateLeavingFrom(data);
            setOpenSearchModal(null);
            return;
        }
        updateDestination(data);
        setOpenSearchModal(null);
    };
    return (
        <MobileDatepickerContainer
            border={`1px ${ColorTheme.grayNeutral[300]} solid`}
            borderRadius={"16px 16px 4px 4px"}
            container
            alignItems={"stretch"}
        >
            <Grid container alignItems={"stretch"} flexWrap={"nowrap"}>
                <Grid
                    px={"8px"}
                    py={"16px"}
                    item
                    display={"flex"}
                    flexDirection={"column"}
                    justifyContent={"space-between"}
                >
                    <img style={{ height: 22.5 }} src={_url("assets/redesign/icons/flight_depart.svg")} />
                    <Box
                        height={"100%"}
                        position={"relative"}
                        left={"50%"}
                        borderLeft={`1px ${ColorTheme.grayNeutral[500]} dashed`}
                    />
                    <img style={{ height: 22.5 }} src={_url("assets/redesign/icons/flight_land.svg")} />
                </Grid>
                <Grid item xs={11}>
                    <Grid container>
                        <Grid
                            display={"flex"}
                            justifyContent={"space-between"}
                            item
                            alignItems={"center"}
                            xs={12}
                            height={"52px"}
                            px={"12px"}
                            py={"16px"}
                        >
                            <Text
                                textOverflow={"ellipsis"}
                                overflow={"hidden"}
                                whiteSpace={"nowrap"}
                                onClick={() => setOpenSearchModal("origin")}
                                size={"sm"}
                                width={"100%"}
                                textweight={"regular"}
                                textcolor={leaving ? undefined : ColorTheme.grayNeutral[400]}
                            >
                                {leaving?.id
                                    ? leaving.city
                                        ? `${leaving?.city || ""} (${leaving?.id || ""})`
                                        : leaving.name
                                        ? `${leaving.name} (${leaving.id})`
                                        : leaving?.id
                                    : placeholderLeaving}
                            </Text>
                        </Grid>
                        <Grid item xs={12}>
                            <Box position={"relative"}>
                                <img
                                    style={{
                                        position: "absolute",
                                        bottom: -20,
                                        right: 10
                                    }}
                                    onClick={() => {
                                        updateLeavingFrom({
                                            field: "origin",
                                            location: destination
                                        });
                                        updateDestination({
                                            field: "destination",
                                            location: leaving
                                        });
                                    }}
                                    src={_url("assets/redesign/icons/arrow-up-down.svg")}
                                />
                            </Box>
                            <Divider />
                        </Grid>
                        <Grid
                            display={"flex"}
                            justifyContent={"space-between"}
                            item
                            xs={12}
                            height={"52px"}
                            px={"12px"}
                            py={"16px"}
                        >
                            <Text
                                textOverflow={"ellipsis"}
                                overflow={"hidden"}
                                width={"100%"}
                                whiteSpace={"nowrap"}
                                onClick={() => setOpenSearchModal("destination")}
                                size={"sm"}
                                textweight={"regular"}
                                textcolor={destination ? undefined : ColorTheme.grayNeutral[400]}
                            >
                                {destination?.id
                                    ? destination.city
                                        ? `${destination?.city || ""} (${destination?.id || ""})`
                                        : destination.name
                                        ? `${destination.name} (${destination.id})`
                                        : destination.id
                                    : placeholderDestination}
                            </Text>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {openSearchModal && (
                <SearchDestinationModal
                    open={!!openSearchModal}
                    handleClose={() => setOpenSearchModal(null)}
                    handleSelect={selectFlight}
                    type={openSearchModal}
                    searchText={
                        openSearchModal === "origin"
                            ? leaving
                                ? `${leaving?.city || ""} (${leaving?.id || ""})`
                                : ""
                            : destination
                            ? `${destination?.city || ""} (${destination?.id || ""})`
                            : ""
                    }
                />
            )}
        </MobileDatepickerContainer>
    );
};

export default FlightSearchTextField;
