// TODO: handle sharing the full ColorTheme when booking-management moves to a separate project
export const ColorTheme = {
    primary: {
        500: "#58A946",
    },
    grayNeutral: {
        700: "#384250",
    },
    secondary: {
        500: "#26BDD7",
    },
    system: {
        error: {
            50: "#FEF3F2",
            100: "#FEE4E2",
            200: "#FECDCA",
            300: "#F04438",
            400: "#B42318"
        }
    }
};
