import { Box } from "@mui/material";
import { Text } from "../../../../components/common/typography/Style";

const CraftedSectionCard = ({ info }) => {
    return (
        <Box>
            <Box display={"flex"} justifyContent={"center"}>
                <img src={`assets/redesign/icons/${info.img}`} alt={info.img} />
            </Box>
            <Text py={"8px"} textAlign={"center"} size={"xl"} textweight={"semiBold"}>
                {info.title}
            </Text>
            <Text textAlign={"center"} size={"md"} textweight={"regular"}>
                {info.description}
            </Text>
        </Box>
    );
};

export default CraftedSectionCard;
