import Styled from "@emotion/styled";
import { Grid, TextField } from "@mui/material";

export const CardElement = Styled(Grid)`
  border: 1px solid rgba(0, 0, 0, 0.2);
    padding: 0px 5px;
    border-radius: 8px;
    margin-bottom: 10px;
    background: #ffff;
`;

export const CardTextfIeld = Styled(TextField)`
 & .MuiOutlinedInput-input {
    padding-left: 0px !important;
 }
 & .MuiOutlinedInput-notchedOutline {
   border: 0px;
   border-color: red;
 }
`;
