import React from "react";
import { PatternFormat } from "react-number-format";

const CreditCardNumberFormat = (props) => {
    const { inputRef, onChange, ...other } = props;
    return (
        <PatternFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.formattedValue
                    }
                });
            }}
            allowEmptyFormatting
            format="#### #### #### ####"
            mask=" "
        />
    );
};

export default CreditCardNumberFormat;
