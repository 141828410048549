import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useGetWindowSize } from "../../../components/common/hook/WindowSize";
import { logAmplitudeEvent } from "../../../utils/log-amplitude-event";

export const useReview = () => {
    const navigate = useNavigate();
    const booking = useSelector((state) => state.bookings.recentlyCompletedBooking);
    const { desktopView } = useGetWindowSize();
    useEffect(() => {
        if (!booking) {
            navigate("/");
        }
        logAmplitudeEvent("trustPilotReviewPromptViewed");
    }, []);

    return {
        navigate,
        booking,
        desktopView
    };
};
