import { useMediaQuery, useTheme } from "@mui/material";

export const useGetWindowSize = () => {
    const theme = useTheme();
    const mobileView = useMediaQuery(theme.breakpoints.down(900));
    const desktopView = useMediaQuery(theme.breakpoints.up(900));

    return {
        mobileView,
        desktopView
    };
};
