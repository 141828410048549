import { Grid } from "@mui/material";
import React from "react";
import { Text } from "../../../../components/common/typography/Style";
import { FlightStyledSearchTab } from "../style";

const FlightSearchTab = ({ flightsType, setFlightType }) => {
    return (
        <Grid container mt={"12px"}>
            <Grid item xs={4}>
                <FlightStyledSearchTab
                    onClick={() => setFlightType("return")}
                    isactive={flightsType === "return" ? 1 : 0}
                >
                    <Text size={"md"} textweight={"medium"}>
                        Return
                    </Text>
                </FlightStyledSearchTab>
            </Grid>
            <Grid item xs={4}>
                <FlightStyledSearchTab
                    onClick={() => setFlightType("one-way")}
                    isactive={flightsType === "one-way" ? 1 : 0}
                >
                    <Text size={"md"} textweight={"medium"}>
                        One Way
                    </Text>
                </FlightStyledSearchTab>
            </Grid>
            <Grid item xs={4}>
                <FlightStyledSearchTab
                    onClick={() => setFlightType("multi-city")}
                    isactive={flightsType === "multi-city" ? 1 : 0}
                >
                    <Text size={"md"} textweight={"medium"}>
                        Multi city
                    </Text>
                </FlightStyledSearchTab>
            </Grid>
        </Grid>
    );
};

export default FlightSearchTab;
