import { Box } from "@mui/material";
import { Text } from "../../../../components/common/typography/Style";
import { ColorTheme } from "../../../../styles/color";

const ProblemSolvingCard = ({ info, isactive, setHoveredCard }) => {
    if (!isactive) {
        return (
            <Box
                style={{ cursor: "pointer" }}
                onMouseEnter={() => setHoveredCard(info)}
                p={"24px"}
                borderBottom={`1px ${ColorTheme.grayNeutral[200]} solid`}
            >
                <Box p={"12px 24px 12px 32px"}>
                    <Text size={"xl"} textweight={"semiBold"}>
                        {info.title}
                    </Text>
                </Box>
            </Box>
        );
    }
    return (
        <Box style={{ cursor: "pointer" }} p={"24px"} borderBottom={`1px ${ColorTheme.grayNeutral[200]} solid`}>
            <Box px={"32px"} borderLeft={`4px solid ${ColorTheme.secondary[500]}`}>
                <Text textcolor={ColorTheme.secondary[600]} textweight={"semiBold"} size={"xl"}>
                    {info.title}
                </Text>
                <Text mt={"8px"} textcolor={ColorTheme.grayNeutral[700]} textweight={"regular"} size={"md"}>
                    {info.description}
                </Text>
            </Box>
        </Box>
    );
};

export default ProblemSolvingCard;
