import { Button, Grid, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { windowScroll } from "../../../utils/SiteUtils";

const PropertyDetailNavigationButton = () => {
    const [selectedTab, setSelectedTab] = useState("overview");

    useEffect(() => {
        selectedTab !== "overview" && windowScroll(selectedTab);
    }, [selectedTab]);

    return (
        <Grid container justifyContent={"space-bewteen"}>
            <Grid item xs={10}>
                <Tabs
                    value={selectedTab}
                    onChange={(_, value) => setSelectedTab(value)}
                    textColor="primary"
                    indicatorColor="primary"
                >
                    <Tab value="overview" label="Overview" />
                    <Tab value="room" label="Rooms" />
                    <Tab value="policies" label="Policies" />
                </Tabs>
            </Grid>
            <Grid item mt={["30px", ""]}>
                <Button onClick={() => setSelectedTab("room")} variant={"contained"}>
                    Reserve a room
                </Button>
            </Grid>
        </Grid>
    );
};

export default PropertyDetailNavigationButton;
