
const _url = (url) => `/${url}`;

const _formatMoney = (num, trimTrailingZeros = false) => {
  if (!num || num === null) {
    return 0;
  }
  let num1 = '';
  let num2 = '';
  num = parseFloat(num).toFixed(2);
  num = num.toString();
  num1 = num.split('.')[0];
  if (num.indexOf('.') > -1) {
    num2 = num.split('.')[1];
  }
  let arr = num1.split('').reverse().join('').match(/.{1,3}/g)
    .reverse();
  arr = arr.map((a) => a.split('').reverse().join(''));
  const res = arr.join(',');
  if (num2.length > 0 && (num2 !== '00' || !trimTrailingZeros)) {
    return res.concat('.', num2);
  }
  return res;
};

export default {
  _url,
  _formatMoney
};

export {
  _url,
  _formatMoney
};
