import { Grid } from "@mui/material";
import React from "react";
import { _url } from "../../../config/utils";
import HomeFeatureCard from "./HomeFeatureCard";

const HomeFeature = () => {
    return (
        <Grid container mt={["0px", "0px", "80px"]} mb={"68px"}>
            <Grid item xs={12} mt={"40px"}>
                <div
                    className="trustpilot-widget"
                    data-locale="en-US"
                    data-template-id="5406e65db0d04a09e042d5fc"
                    data-businessunit-id="6023a4aac0f73e00011dcb3e"
                    data-style-height="28px"
                    data-style-width="100%"
                    data-theme="light"
                >
                    <a
                        href="https://www.trustpilot.com/review/paylatertravel.com"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Trustpilot
                    </a>
                </div>
            </Grid>

            <Grid item xs={12} mt={"40px"}>
                <Grid container rowSpacing={["40px", "40px", "0px"]} columnSpacing={"56px"}>
                    <Grid item xs={12} md={4}>
                        <HomeFeatureCard
                            description={"Search from thousands of destinations. Book with as low as a 10% deposit"}
                            title={"Find great deals"}
                            icon={_url("assets/redesign/icons/booking.svg")}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <HomeFeatureCard
                            description={
                                "Pay weekly or fortnightly for up to 26 weeks before you travel. No interest. No credit checks."
                            }
                            title={"Enjoy flexible payments"}
                            icon={_url("assets/redesign/icons/revolver.svg")}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <HomeFeatureCard
                            description={"Once all your payments are made you’ll receive your e-ticket within 24 hours"}
                            title={"Travel with confidence"}
                            icon={_url("assets/redesign/icons/world.svg")}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default HomeFeature;
