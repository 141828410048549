import React, { Component } from "react";
import * as moment from "moment";
import { v4 as uuidv4 } from "uuid";
import classNames from "classnames";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Carousel } from "react-responsive-carousel";
import { _url } from "../config/utils";

class HotelResultBox extends Component {
    capitalise = (str) => str.toLowerCase().replace(/(?:^|\s|["'([{])+\S/g, (match) => match.toUpperCase());

    render() {
        const { result, query } = this.props;
        const { checkInDate, checkOutDate, rooms } = query;
        const nights = moment(checkOutDate, "YYYY-MM-DD").diff(moment(checkInDate, "YYYY-MM-DD"), "days");
        const cdnUrl = "https://hotelscdn.paylatertravel.com.au/";

        const stars = [];
        const fabricateRating = result.hotel.rating ? (result.hotel.rating < 3 ? 3 : result.hotel.rating) : 4;
        // render stars
        if (result.hotel) {
            for (let i = 0; i < fabricateRating; i++) {
                stars.push(<FontAwesomeIcon icon="star" />);
            }
        }

        return (
            <div key={uuidv4()} className="hotel-result-box">
                <div className="hotel-result-box__slider">
                    <Carousel showThumbs={false} infiniteLoop>
                        {result.images &&
                            result.images.map((image) => (
                                <div
                                    key={image}
                                    onClick={() => this.props.onSelect(result.hotel.hotelId)}
                                    className="hotel-result-box__slider-image"
                                    style={{
                                        backgroundImage: `url(${cdnUrl}${image})`
                                    }}
                                />
                            ))}
                    </Carousel>
                    <div
                        className="hotel-result-box__slider-content"
                        onClick={() => this.props.onSelect(result.hotel.hotelId)}
                    >
                        <h2>{result.hotel && this.capitalise(result.hotel.name)}</h2>
                        <div className="hotel-result-box__slider-details">
                            {this.props.query.destination && (
                                <p>
                                    <FontAwesomeIcon icon="map-marker-alt" />{" "}
                                    {this.props.query.destination.split("_")[0]},{" "}
                                    {this.props.query.destination.split("_")[1].replace("/", "")}
                                </p>
                            )}
                            {result.hotelDetails && result.hotelDetails.hotel.tags && (
                                <div className="HotelTags">
                                    {Array.isArray(result.hotelDetails.hotel.tags) ? (
                                        result.hotelDetails.hotel.tags.map((tag) => (
                                            <div className="HotelTags__tag">{tag}</div>
                                        ))
                                    ) : (
                                        <div className="HotelTags__tag">{result.hotelDetails.hotel.tags}</div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="hotel-result-box__content" onClick={() => this.props.onSelect(result.hotel.hotelId)}>
                    <div className="hotel-result-box__content-inner">
                        <div className="hotel-result-box__logo">
                            <img src={`${cdnUrl}${result.logo}`} />
                        </div>
                        {result.available && (
                            <div className="hotel-result-box__details">
                                <div className="hotel-result-box__stars">{stars}</div>
                                <div>
                                    <p>{`${nights} night${nights > 1 ? "s" : ""}, ${rooms} room${
                                        rooms > 1 ? "s" : ""
                                    }`}</p>
                                    <p className="title">from</p>
                                    {/* <p className="pw">${_formatMoney(searchResult.pricePerWeek / 100)}<sup>AU</sup>/wk</p> */}
                                    <p className="pw">
                                        ${parseFloat(result.pricePerWeek / 100).toFixed(2)}
                                        <sup>AU</sup>
                                        /wk
                                    </p>
                                    {/* <p className="total">${_formatMoney(searchResult.totalCompletePrice / 100)} total <span>AU</span></p> */}
                                    <p className="total">
                                        ${parseFloat(result.total / 100).toFixed(2)} total
                                        <span>AU</span>
                                    </p>
                                </div>
                            </div>
                        )}
                    </div>
                    <button
                        type="button"
                        className={classNames("btn-select btn btn-secondary", {
                            disabled: !result.available
                        })}
                        onClick={() => {
                            if (!result.available) return false;
                            this.props.onSelect(result.hotel.hotelId);
                        }}
                    >
                        {result.available ? "View Details" : "Sold out"}{" "}
                        {result.available && <img alt="left-arrow" src={_url("assets/images/left-arrow.png")} />}
                        {/* View Details <img alt="left-arrow" src={_url('assets/images/left-arrow.png')}/> */}
                    </button>
                    {result.available && (
                        <span className="hotel-result-call-out">
                            <div>No deposit required.</div>
                            <div>Free cancellation.</div>
                        </span>
                    )}
                </div>
            </div>
        );
    }
}

export default HotelResultBox;
