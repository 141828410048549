import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import moment from "moment";
import * as React from "react";
import { FEATURE_FLAG_MOBILE_NEW_DATEPICKER } from "../../../../constants";
import CustomMobileDateRangePicker from "../CustomMobileDateRangePicker";
import { _url } from "../../../../config/utils";
import { SearchTextFieldStyle } from "../../fields/Style";

const MuiDateRangePicker = ({
    startDate,
    endDate,
    startDateLabel,
    endDateLabel,
    handleDateChange,
    minimumDate,
    maximumDate,
    textFieldType
}) => {
    const [openModal, setOpenModal] = React.useState(false);

    const theme = useTheme();
    const mobileView = useMediaQuery(theme.breakpoints.down(900));

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} localeText={{ start: startDateLabel, end: endDateLabel }}>
            <DateRangePicker
                value={[startDate, endDate]}
                onChange={(newValue) => {
                    handleDateChange({
                        startDate: new Date(newValue[0]),
                        endDate: newValue[1] ? new Date(newValue[1]) : null
                    });
                }}
                onOpen={() => {
                    FEATURE_FLAG_MOBILE_NEW_DATEPICKER && mobileView && setOpenModal(true);
                }}
                open={FEATURE_FLAG_MOBILE_NEW_DATEPICKER && mobileView ? false : undefined}
                inputFormat={"DD MMM"}
                maxDate={maximumDate ? new Date(maximumDate) : new Date(moment().add("12", "months"))}
                minDate={minimumDate ? new Date(minimumDate) : new Date()}
                renderInput={(startProps, endProps) => (
                    <Grid container columnSpacing={"10px"}>
                        <Grid item xs={6}>
                            <SearchTextFieldStyle
                                placeholder={"Add Date"}
                                style={{ background: "white", borderRadius: 10, width: "100%" }}
                                {...startProps}
                                inputProps={{
                                    ...startProps.inputProps,
                                    placeholder: "Depart"
                                }}
                                fullWidth
                                InputProps={{
                                    startAdornment: <img src={_url("assets/redesign/icons/calendar.svg")} />
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <SearchTextFieldStyle
                                style={{ borderRadius: 10, background: "white", width: "100%" }}
                                {...endProps}
                                inputProps={{
                                    ...endProps.inputProps,
                                    placeholder: "Return"
                                }}
                                fullWidth
                                InputProps={{
                                    startAdornment: <img src={_url("assets/redesign/icons/calendar.svg")} />
                                }}
                            />
                        </Grid>
                    </Grid>
                )}
            />

            <CustomMobileDateRangePicker
                endDate={endDate}
                maximumDate={maximumDate}
                isOpen={openModal}
                minimumDate={minimumDate}
                startDate={startDate}
                handleClose={() => setOpenModal(false)}
                handleDateChange={handleDateChange}
            />
        </LocalizationProvider>
    );
};

export default MuiDateRangePicker;
