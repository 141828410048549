export const usaPopularDestination = [
    {
        id: 1,
        title: "Las Vegas",
        image: "assets/redesign/us/lasVegas.png"
    },
    {
        id: 2,
        title: "Orlando",
        image: "assets/redesign/us/orlando.png"
    },
    {
        id: 3,
        title: "Miami",
        image: "assets/redesign/us/miami.png"
    },
    {
        id: 4,
        title: "New York",
        image: "assets/redesign/us/nyc.png"
    },
    {
        id: 5,
        title: "San Juan",
        image: "assets/redesign/us/sanjuan.png"
    }
];

export const auPopularDestination = [
    {
        id: 1,
        title: "Bali",
        image: "assets/redesign/au/Denpasar.png"
    },
    {
        id: 2,
        title: "Fiji",
        image: "assets/redesign/au/Nadi.png"
    },
    {
        id: 3,
        title: "Auckland",
        image: "assets/redesign/au/Auckland.png"
    },
    {
        id: 4,
        title: "Samoa",
        image: "assets/redesign/au/Apia.png"
    },
    {
        id: 5,
        title: "Melbourne",
        image: "assets/redesign/au/Melbourne.png"
    }
];
