import React, { useEffect, useState } from "react";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Badge } from "reactstrap";
import { CardDetails } from ".";
import { _url } from "../config/utils";

const CardDropdown = (props) => {
    const { cards, selectedCardId, onSelectCard } = props;
    const [addCardSelected, setAddCardSelected] = useState(false);

    useEffect(() => {
        setAddCardSelected(!selectedCardId);
    }, [selectedCardId]);

    return (
        <div>
            <div className="CardsDropdown__label">
                <div>Pay with</div>
                <div className="CardsDropdown__labelCards">
                    <img src={_url("assets/images/creditcards.png")} />
                </div>
            </div>
            {cards && cards.length > 0 && (
                <UncontrolledDropdown className="CardsDropdown">
                    <DropdownToggle caret className="CardsDropdown__toggleButton btn-block">
                        <div className="CardsDropdown__placeholder">
                            {!addCardSelected ? (
                                <CardDetails card={cards.find((x) => x.id === selectedCardId)} />
                            ) : (
                                "Add new card"
                            )}
                        </div>
                    </DropdownToggle>
                    <DropdownMenu className="CardsDropdown__menu">
                        {cards.map((c) => (
                            <DropdownItem
                                key={c.id}
                                onClick={() => {
                                    onSelectCard(c.id);
                                    setAddCardSelected(false);
                                }}
                            >
                                <CardDetails card={c} /> {c.id === selectedCardId && <Badge>Selected</Badge>}
                            </DropdownItem>
                        ))}
                        {props.onSelectAddNewCard && <DropdownItem divider />}
                        {props.onSelectAddNewCard && (
                            <DropdownItem
                                onClick={() => {
                                    setAddCardSelected(true);
                                    props.onSelectAddNewCard();
                                }}
                            >
                                Add new card
                            </DropdownItem>
                        )}
                    </DropdownMenu>
                </UncontrolledDropdown>
            )}
        </div>
    );
};

export default CardDropdown;
