import { Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import CancelationPolicyInfoModal from "./CancelationPolicyInfoModal";
import PropertyRoomCard from "./PropertyRoomCard";
import RoomInformationModal from "./RoomInformationModal";
import SpecialPromotionInfoModal from "./SpecialPromotionInfoModal";

const PropertyRoomView = ({ rooms, handleRoomSelect }) => {
    const [tariffNotes, setSelectedTariffNotes] = useState(null);
    const [specialPromotionModal, setSpecialPromotionModal] = useState(null);
    const [cancelationPolicies, setCancelationPolicies] = useState(null);

    if (!rooms && rooms.length === 0) {
        return (
            <Typography variant="body1" gutterBottom>
                No rooms found
            </Typography>
        );
    }
    return (
        <Grid container>
            <Typography variant="h5" gutterBottom>
                Select Your Room
            </Typography>
            <Grid container spacing={"30px"}>
                {rooms.map((room) => (
                    <Grid key={room.roomId} item xs={12} md={4}>
                        <PropertyRoomCard handleRoomSelect={handleRoomSelect} room={room} />
                    </Grid>
                ))}
            </Grid>

            {tariffNotes && (
                <RoomInformationModal notes={tariffNotes} handleClose={() => setSelectedTariffNotes(null)} />
            )}
            {specialPromotionModal && (
                <SpecialPromotionInfoModal
                    specialPromotion={specialPromotionModal}
                    handleClose={() => setSpecialPromotionModal(null)}
                />
            )}
            {cancelationPolicies && (
                <CancelationPolicyInfoModal
                    cancelationPolicies={cancelationPolicies}
                    handleClose={() => setCancelationPolicies(null)}
                />
            )}
        </Grid>
    );
};

export default PropertyRoomView;
