import { ClickAwayListener, TextField } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import React from "react";
import moment from "moment";

const PaymentLinkDatePicker = ({
    date,
    isError,
    label,
    handleDateChange,
    type,
    onFieldBlur,
    errorMessage,
    handleClose,
    anchorEl,
    maxPaymentStartDate,
    ...rest
}) => {
    const handleChange = (newValue) => { handleDateChange(new Date(newValue)); };
    return (
        <ClickAwayListener onClickAway={() => { handleClose(); }}>
            <div style={{ visibility: "hidden", position: "absolute" }}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DesktopDatePicker
                        label={label}
                        value={date}
                        open
                        PopperProps={{
                            placement: "bottom",
                            anchorEl: anchorEl
                        }}
                        onChange={handleChange}
                        views={["year", "month", "day"]}
                        minDate={moment()}
                        maxDate={moment(maxPaymentStartDate)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                style={{ background: "white", borderRadius: 10, width: "100%" }}
                                inputProps={{
                                    ...params.inputProps,
                                    placeholder: "Add Date"
                                }}
                                onKeyDown={(e) => rest.isDisabled && e.preventDefault()}
                                error={!!isError}
                                helperText={!!isError && errorMessage}
                                onClick={() => setOpenDatepicker((prev) => !prev)}
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                        )}
                    />
                </LocalizationProvider>
            </div>
        </ClickAwayListener>
    );
};

export default PaymentLinkDatePicker;
