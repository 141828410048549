import Styled from "@emotion/styled";
import { Grid, Typography } from "@mui/material";
import { PrimaryButton } from "../../../components/common/button/ButtonStyle";

const StyledHeading = Styled(Typography)`
    margin-top: 10px;
    margin-bottom: 10px;
    font-family: Inter;
    font-size: 60px;
    font-style: normal;
    line-height: 32px;
    letter-spacing: -1.2px;
    color: #101828;
    font-weight: 700;
    font-size: 24px;
`;
const StyledBodyText = Styled(Typography)`
    color: #101828;
    font-family: Inter;
    font-style: normal;
    line-height: 24px;
    font-weight: 400;
    font-size: 14px;
`;

const StyledSection = Styled.div`
    margin-bottom: 10px;
`;

const StyledContainer = Styled(Grid)`
    height: fit-content;
    padding: 16px 24px;
    margin: 40px 24px;
    max-width: 1044px;
    text-align: center;
`;

export const ErrorMessage = ({ viewNavigation, heading, text, children }) => {
    const handleClick = () => {
        viewNavigation.executeAction({
            href: 'plt:///bookings'
        });
    };
    return (
        <Grid height={"100vh"} container justifyContent={"center"}>
            <StyledContainer>
                { children }
                <StyledSection>
                    <StyledHeading>{heading}</StyledHeading>
                </StyledSection>
                <StyledSection>
                    <StyledBodyText>{text}</StyledBodyText>
                </StyledSection>
                <PrimaryButton fullWidth onClick={handleClick}>Back to Dashboard</PrimaryButton>
            </StyledContainer>
        </Grid>
    );
};
