import { Box, Dialog, Grid, IconButton, TextField } from "@mui/material";
import { debounce } from "lodash";
import React, { useCallback, useState } from "react";
import Highlighted from "../../../../components/common/HighlightText";
import { Text } from "../../../../components/common/typography/Style";
import InternalApi from "../../../../config/internalApi";
import { _url } from "../../../../config/utils";
import { ColorTheme } from "../../../../styles/color";
import { titleCase } from "../../../../utils/SearchUtils";
import { getSiteValueByLang } from "../../../../utils/SiteUtils";

const SearchDestinationModal = ({ open, handleClose, type, handleSelect, searchText }) => {
    const [placesList, setPlacesList] = useState([]);
    const [isSearchedDone, setIsSearchedDone] = useState(false);
    const [searchedText, setSearchedText] = useState(searchText);

    const search = (keyword) => {
        if (keyword === "") {
            setPlacesList([]);
            return false;
        }

        InternalApi.get("/search/airport", {
            keyword,
            subType: "AIRPORT",
            max: 10
        })
            .then((res) => {
                setIsSearchedDone(true);
                const places = [];
                if (res.data.length > 0) {
                    res.data.forEach((p) => {
                        const city = titleCase(p.address_city_name);
                        const name = titleCase(p.name);
                        const country = titleCase(p.address_country_name);
                        const state = titleCase(p.state);
                        places.push({
                            id: p.iata_code,
                            name,
                            city,
                            country,
                            state
                        });
                    });
                }

                setPlacesList(
                    places.filter(
                        (item) =>
                            item.id.toLowerCase().includes(keyword.toLowerCase()) ||
                            item.city.toLowerCase().includes(keyword.toLowerCase()) ||
                            item.name.toLowerCase().includes(keyword.toLowerCase()) ||
                            item.country.toLowerCase().includes(keyword.toLowerCase()) ||
                            item.state.toLowerCase().includes(keyword.toLowerCase())
                    )
                );
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const debounceFn = useCallback(debounce(search, 500), []);

    const renderSearch = () => {
        if (isSearchedDone && placesList.length > 0) {
            return (
                <Grid item xs={12} mt={"16px"}>
                    <Grid container>
                        {placesList.map((option, index) => (
                            <Box
                                width={"100%"}
                                mt={"8px"}
                                onClick={() => {
                                    handleSelect({
                                        field: type,
                                        location: option,
                                        index: index
                                    });
                                }}
                                key={option.id}
                                py={"10px"}
                                display={"flex"}
                                gap={"10px"}
                            >
                                <Box>
                                    {type === "origin" ? (
                                        <img
                                            style={{ height: 22.5 }}
                                            src={_url("assets/redesign/icons/flight_depart.svg")}
                                        />
                                    ) : (
                                        <img
                                            style={{ height: 22.5 }}
                                            src={_url("assets/redesign/icons/flight_land.svg")}
                                        />
                                    )}
                                </Box>
                                <Box>
                                    <Text size={"sm"} textweight={"medium"}>
                                        <Highlighted highlight={searchedText} text={option.city} /> (
                                        <Highlighted highlight={searchedText} text={option.id} />
                                        {" - "}
                                        <Highlighted highlight={searchedText} text={option.name} />)
                                    </Text>
                                    <Text textcolor={ColorTheme.grayNeutral[500]} size={"sm"} textweight={"regular"}>
                                        <Highlighted highlight={searchedText} text={option.state} />,{" "}
                                        <Highlighted highlight={searchedText} text={option.country} />
                                    </Text>
                                </Box>
                            </Box>
                        ))}
                    </Grid>
                </Grid>
            );
        }

        if (isSearchedDone && placesList.length === 0) {
            return (
                <Grid item xs={12} mt={"16px"}>
                    <Box display={"flex"} justifyContent={"center"}>
                        <img src={_url("assets/redesign/icons/search_icon.svg")} />
                    </Box>
                    <Text mt={"8px"} size={"md"} align={"center"} textweight={"semiBold"}>
                        No result Found
                    </Text>
                    <Text mt={"8px"} textcolor={"#4D5761"} size={"sm"} align={"center"} textweight={"regular"}>
                        We cannot find what you are looking for. Try another search
                    </Text>
                </Grid>
            );
        }

        return (
            <Grid item xs={12} mt={"16px"}>
                <Text size={"md"} textweight={"semiBold"}>
                    Popular {type === "origin" ? "Origins" : "Destinations"}
                </Text>
                {getSiteValueByLang(
                    type === "origin" ? "popularDepartureDestination" : "popularArrivalDestination"
                ).map((option, index) => (
                    <Box
                        width={"100%"}
                        mt={"8px"}
                        onClick={() => {
                            handleSelect({
                                field: type,
                                location: option,
                                index: index
                            });
                        }}
                        key={option.id}
                        py={"10px"}
                        display={"flex"}
                        gap={"10px"}
                    >
                        <Box>
                            {type === "origin" ? (
                                <img style={{ height: 22.5 }} src={_url("assets/redesign/icons/flight_depart.svg")} />
                            ) : (
                                <img style={{ height: 22.5 }} src={_url("assets/redesign/icons/flight_land.svg")} />
                            )}
                        </Box>
                        <Box>
                            <Text size={"sm"} textweight={"medium"}>
                                <Highlighted highlight={searchedText} text={option.city} /> (
                                <Highlighted highlight={searchedText} text={option.id} />
                                {" - "}
                                <Highlighted highlight={searchedText} text={option.name} />)
                            </Text>
                            <Text textcolor={ColorTheme.grayNeutral[500]} size={"sm"} textweight={"regular"}>
                                <Highlighted highlight={searchedText} text={option.state} />,{" "}
                                <Highlighted highlight={searchedText} text={option.country} />
                            </Text>
                        </Box>
                    </Box>
                ))}
            </Grid>
        );
    };
    return (
        <Dialog open={open} onClose={handleClose} fullScreen>
            <Grid container pt={"30px"} px={"16px"}>
                <Grid item xs={12}>
                    <IconButton onClick={handleClose} style={{ position: "absolute", right: 10, top: 23 }}>
                        <img src={_url("assets/redesign/icons/close_icon.svg")} />
                    </IconButton>
                    <Text size={"md"} align="center" textweight={"semiBold"}>
                        Choose {type === "origin" ? "Origin" : "Destination"}
                    </Text>
                    <Box mt={"20px"}>
                        <TextField
                            InputProps={{
                                startAdornment: <img src={_url("assets/redesign/icons/search_icon.svg")} />,
                                endAdornment: searchedText && (
                                    <IconButton onClick={() => setSearchedText("")}>
                                        <img src={_url("assets/redesign/icons/close_outlined.svg")} />
                                    </IconButton>
                                )
                            }}
                            autoFocus
                            fullWidth
                            value={searchedText}
                            placeholder="Search country, city or airport"
                            onChange={(e) => {
                                debounceFn(e.target.value);
                                setSearchedText(e.target.value);
                            }}
                        />
                    </Box>
                </Grid>
                {renderSearch()}
            </Grid>
        </Dialog>
    );
};

export default SearchDestinationModal;
