import Styled from "@emotion/styled";
import { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { View } from "../../../booking-management/View";
import { ColorTheme } from "../../../styles/color";
import { ErrorMessage } from "./ErrorMessage";

const toFields = (querystring) => {
    return querystring.split('&').reduce((fields, item) => {
        const [name, value] = item.split('=');
        fields.push({ name, value });
        return fields;
    }, []);
};

const BookingManagement = ({ viewNavigation }) => {
    const [bookingManagementViewModel, setBookingManagementViewModel] = useState({ status: 'VIEW_NOT_LOADED', payload: {} });
    const handleViewActionClick = async (action) => {
        const viewModel = await viewNavigation.executeAction(action);
        if (viewModel) {
            setBookingManagementViewModel(viewModel);
        }
    };

    useEffect(() => {
        const url = new URL(window.location.href);
        const fields = toFields(url.search.slice(1)); // Remove leading "?" from querystring
        const apiPath = url.pathname.slice('/view'.length); // Remove "/view" prefix from route
        handleViewActionClick({ href: apiPath, method: 'GET', fields });
    }, [] /* Entry-Point: runs on first render */);

    const renderView = () => {
        const StyledView = Styled(Grid)`
            height: fit-content;
            padding: 16px 24px;
            margin: 40px 24px;
            background-color: #fff;
            max-width: 1044px;
            border-radius: 10px;
        `;
        if (bookingManagementViewModel.status === 'VIEW_LOADED') {
            return (
                <StyledView>
                    <View model={bookingManagementViewModel.payload} onActionClick={handleViewActionClick} />
                </StyledView>
            );
        }
        return <></>;
    };

    const renderCatchAllError = () => {
        if (bookingManagementViewModel.status === 'CATCH_ALL_ERROR') {
            return <Grid height={"100vh"} bgcolor={ColorTheme.grayNeutral[100]} container justifyContent={"center"}>
                <ErrorMessage
                    viewNavigation={viewNavigation}
                    heading="Sorry. Internal Server Error!"
                    text="Sorry it’s not you - its us. Our team is hunting for the bug."
                >
                    <img alt="Internal Server Error" src="/assets/images/errors/InternalServerError.svg" width="100%" />
                </ErrorMessage>
            </Grid>
        }
        return <></>;
    };

    const renderNotFoundError = () => {
        if (bookingManagementViewModel.status === 'PAGE_NOT_FOUND') {
            return <Grid height={"100vh"} bgcolor={ColorTheme.grayNeutral[100]} container justifyContent={"center"}>
                <ErrorMessage
                    viewNavigation={viewNavigation}
                    heading="Oh no. Seems like you got lost"
                    text="We searched everywhere but couldn’t find what you’re looking for. Let’s find a better place for you to go."
                >
                    <div className="error_page_not_found">
                        <img alt="Not Found" src="/assets/images/errors/NotFound.svg" width="100%" />
                    </div>
                </ErrorMessage>
            </Grid>
        }
        return <></>;
    };

    const renderSystemDownError = () => {
        if (bookingManagementViewModel.status === 'SYSTEM_DOWN') {
            return <Grid height={"100vh"} bgcolor={ColorTheme.grayNeutral[100]} container justifyContent={"center"}>
                <ErrorMessage
                    viewNavigation={viewNavigation}
                    heading="System Down"
                    text="Sorry it’s not you - its us. Our team is hunting for the bug."
                >
                    <img alt="System Down" src="/assets/images/errors/SystemDown.svg" width="100%" />
                </ErrorMessage>
            </Grid>
        }
        return <></>;
    };

    return (
        <Grid height={"100vh"} bgcolor={ColorTheme.grayNeutral[100]} container justifyContent={"center"}>
            { renderView() }
            { renderCatchAllError() }
            { renderNotFoundError() }
            { renderSystemDownError() }
        </Grid>
    );
};

export default BookingManagement;
