import queryString from "query-string";
import InternalApi from "../config/internalApi";
import { flightsType } from "../constants/AppConstants";
import { getSiteValueByLang } from "./SiteUtils";

export const formatDuration = (duration) =>
    duration.replace("PT", "").replace("D", "d ").replace("H", "h ").replace("M", "m");

export const titleCase = (str) => {
    str = str.toLowerCase();
    str = str.split(" ");
    for (let i = 0; i < str.length; i++) str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
    return str.join(" ");
    // ["I'm", "A", "Little", "Tea", "Pot"].join(' ') => "I'm A Little Tea Pot"
};

export const prepareFlightSeachQuery = async (flightsQuery) => {
    const searchString = `?adults=${flightsQuery.adults}&children=${flightsQuery.children}&infants=${flightsQuery.infants}`;
    const { origin, destination, departureDate, returnDate, type, multiCityLegs } = flightsQuery;
    const urlParams = queryString.parse(searchString);
    // format multicity legs from the url
    // retreive iata code information from the server
    let iataCodes = "";
    if (flightsQuery.type === flightsType.multiCity) {
        multiCityLegs.forEach((legObj) => {
            if (legObj.origin && legObj.destination) {
                iataCodes += `${legObj.origin.id},${legObj.destination.id},`;
            }
        });
    }
    if (flightsQuery.type !== flightsType.multiCity) {
        iataCodes = `${origin.id},${destination.id}`;
    }
    console.log("iataCodes", iataCodes);
    const places = await InternalApi.get("/iata-lookup", { iataCodes });

    let query = {
        ...flightsQuery,
        currencyCode: getSiteValueByLang("currencyCode")
    };

    // if origin and return date exists then we are in a return search
    if (flightsQuery.type !== flightsType.multiCity) {
        const originIataMeta = places.find((x) => x.iata_code === origin.id);
        const destinationIataMeta = places.find((x) => x.iata_code === destination.id);
        query = {
            ...query,
            origin: {
                id: origin.id,
                city: titleCase(originIataMeta.address_city_name),
                name: titleCase(originIataMeta.name),
                country: titleCase(originIataMeta.address_country_name)
            },
            destination: {
                id: destination.id,
                city: titleCase(destinationIataMeta.address_city_name),
                name: titleCase(destinationIataMeta.name),
                country: titleCase(destinationIataMeta.address_country_name)
            },
            type,
            c: undefined,
            ...urlParams
        };

        if (departureDate) query.departureDate = departureDate;
        if (returnDate) query.returnDate = returnDate;
        if (type === flightsType.oneWay) query.returnDate = undefined;
        return query;
    }
    // we are in a multi city search
    const formattedMultiCityLegs = multiCityLegs.map((legObj) => {
        const originIataMeta = places.find((x) => x.iata_code === legObj.origin.id);
        const destinationIataMeta = places.find((x) => x.iata_code === legObj.destination.id);
        return {
            origin: {
                id: legObj.origin.id,
                city: titleCase(originIataMeta.address_city_name),
                name: titleCase(originIataMeta.name),
                country: titleCase(originIataMeta.address_country_name)
            },
            destination: {
                id: legObj.destination.id,
                city: titleCase(destinationIataMeta.address_city_name),
                name: titleCase(destinationIataMeta.name),
                country: titleCase(destinationIataMeta.address_country_name)
            },
            departureDate: legObj.departureDate
        };
    });
    query = {
        ...query,
        multiCityLegs: formattedMultiCityLegs,
        type: flightsType.multiCity,
        c: undefined,
        ...urlParams
    };
    return query;
};

export const filterSearchedFlights = (searchResults) => {
    if (!searchResults && !searchResults.carriersVisibilityFilter) {
        return [];
    }
    return searchResults.data.filter((itinerary) => {
        // if there is only one carrier for this itinerary & its active
        if (
            itinerary.carriers.length === 1 &&
            (searchResults.carriersVisibilityFilter.length === 0 ||
                searchResults.carriersVisibilityFilter.includes(itinerary.carriers[0]))
        ) {
            return true;
        }
        if (
            itinerary.carriers.length > 1 &&
            (searchResults.carriersVisibilityFilter.length === 0 ||
                searchResults.carriersVisibilityFilter.includes("COMBINATIONS"))
        ) {
            return true;
        }
        return false;
    });
};
