export const flightsType = {
    return: "return",
    oneWay: "one-way",
    multiCity: "multi-city"
};

export const INITIAL_SHOW_MORE_SIZE = 8;
export const ADULTS = "adults";
export const CHILDREN = "children";
export const CHILDREN_AGES = "childrenages";
export const INFANTS = "infants";
export const brokenImage = "/assets/images/broken.jpg";
export const titleOptions = ["Mr", "Miss", "Mrs", "Ms", "Master"];
