import { Grid } from "@mui/material";
import { useEffect } from "react";
import { ColorTheme } from "../../styles/color";
import { logAmplitudeEvent } from "../../utils/log-amplitude-event";
import TestimonialBanner from "./component/TestimonialBanner";
import TestimonialContentSection from "./component/TestimonialContentSection";
import TestimonialHeader from "./component/TestimonialHeader";
import TestimonialSection from "./component/TestimonialSection";
import TestimonialStoryCustomer from "./component/TestimonialStoryCustomer";

const Testimonial = () => {
    useEffect(() => {
        logAmplitudeEvent("Testimonials page view");
    }, []);
    return (
        <Grid container pt={["56px", "56px", "140px"]} bgcolor={ColorTheme.grayNeutral[50]}>
            <Grid item xs={12} px={["16px", "16px", "0px"]}>
                <Grid container>
                    <Grid item xs={12}>
                        <TestimonialHeader />
                    </Grid>
                    <Grid item xs={12}>
                        <TestimonialContentSection />
                    </Grid>
                    <Grid item xs={12} bgcolor={ColorTheme.base.white}>
                        <TestimonialSection />
                    </Grid>
                    <Grid item xs={12}>
                        <TestimonialBanner />
                    </Grid>
                    <Grid item xs={12}>
                        <TestimonialStoryCustomer />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Testimonial;
