import { object, ref, string } from "yup";
import InternalApi from "../../../config/internalApi";
import { toast } from "react-toastify";
import { loginUser } from "../../../redux/modules/auth";

export const updatePasswordInitialValue = {
    password: "",
    confirmPassword: ""
};

export const updatePasswordFormValidation = object().shape({
    password: string().required("Password is mandatory"),
    confirmPassword: string()
        .oneOf([ref("password"), null], "Passwords must match")
        .required("Confirm Password is mandatory")
});

export const forgotPassword = (values, email, authProviderId) => async (dispatch) => {
    return InternalApi.put("v2/auth/password", {
        authProviderId: authProviderId,
        password: values.password
    })
        .then(() => {
            dispatch(
                loginUser({
                    email: email,
                    password: values.password
                })
            );
            return Promise.resolve();
        })
        .catch((error) => {
            toast.error(error.response.data.error.message, {
                position: toast.POSITION.TOP_RIGHT
            });
            return Promise.reject();
        });
};
