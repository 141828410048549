import { AU_RECENT_BOOKINGS, AU_SITE_POPULAR_ARRIVAL, AU_SITE_POPULAR_DEPARTURE } from "../constants/Options";
import { auPopularDestination } from "../containers/redesign/homepage/constant";

export const AU_CONSTANT = {
    country: "au",
    placesAutocompletedLocation: {
        lat: -34,
        lng: 151
    },
    addressCountry: "Australia",
    billingAddressSelectStates: "US",
    getPaymentFrequency: "Fortnightly",
    passportOrigin: "AU",
    stripePromise: "stripePromiseAU",
    bookingsEmail: "support@paylatertravel.com.au",
    desktopPicker: "dd/MM/yyyy",
    phoneInputWrapper: "au",
    trustpilot: "https://au.trustpilot.com/review/paylatertravel.com.au",
    homeflightMockup: "assets/images/paylaterflightsweb.png",
    question: {
        description: "If you have any questions please contact on",
        telephoneNo: "1300 399 902",
        email: "or email support@paylatertravel.com."
    },

    countryCode: "28",
    bookingReview: "https://au.trustpilot.com/review/paylatertravel.com.au",
    currencyCode: "AUD",
    stateOrigin: "AU",
    questionChat: {
        description: "Questions? Chat or contact us:",
        telephoneNo: "1300 399 902"
    },
    supportMail: "support@paylatertravel.com.au",
    madeWithLove: "Made with love in Sydney Australia.",
    privacyPolicy: "AuPrivacyPolicy",
    formikMuiDateFormat: "DD/MM/YYYY",
    hotelLink: "https://www.booking.com/index.html?aid=1826066",
    cardRental: "https://www.driveaway.com.au/affiliates/1014025",
    phoneAutoFormat: false,
    paymentPlan: (value) => `${value} fortnight(s)`,
    reviewSupport: "ReviewSupportAu",
    gaMeasuremntId: "G-Q2KV8PSEVF",
    showTravelInsurance: false,
    popularDestination: auPopularDestination,
    popularDepartureDestination: AU_SITE_POPULAR_DEPARTURE,
    popularArrivalDestination: AU_SITE_POPULAR_ARRIVAL,
    recentBookings: AU_RECENT_BOOKINGS,
    termsAndConditions: "https://paylatertravel.notion.site/Australia-Customer-Terms-and-Conditions-b85da50e066645008bbc659e74e918df",
    dateOfBirthExample: "30/12/1997"
};
