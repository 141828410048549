import classNames from "classnames";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Row } from "reactstrap";
import { PaymentAmountDisplay } from "./paymentplan/PaymentAmountDisplay";
import { PaymentDepositeSlider } from "./paymentplan/PaymentDepositeSlider";
import { PaymentPlanChooseText } from "./paymentplan/PaymentPlanChooseText";
import { PaymentPlanOptions } from "./paymentplan/PaymentPlanOptions";

import { logAmplitudeEvent } from "../../utils/log-amplitude-event";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Emoji } from "../../components";

// eslint-disable-next-line arrow-body-style
export const ReviewPaymentPlan = ({
    paymentPlan,
    onUpdatePaymentPlan,
    reviewData,
    setReviewData,
    minimumDepositValue
}) => {
    const [showCoupon, setShowCoupon] = useState(false);
    const detailFlight = useSelector((state) => state.detailFlight);
    const balance = useSelector((state) => state.referrals.balance);
    const searchQuery = useSelector((state) => state.search.query.flights);
    const departureDate =
        searchQuery.type === "multi-city" ? searchQuery.multiCityLegs[0].departureDate : searchQuery.departureDate;

    const applyCoupon = (e) => {
        e.preventDefault();
        setReviewData({
            ...reviewData,
            couponCode: e.target.couponCode.value
        });
        onUpdatePaymentPlan(
            {
                ...reviewData,
                couponCode: e.target.couponCode.value
            },
            false
        );
    };

    const onChangePaymentFrequency = ({ paymentFrequency }) => {
        logAmplitudeEvent("paymentFrequencySelected", { paymentFrequency });
        if (paymentFrequency === "weekly") {
            reviewData = { ...reviewData, plan: 1 };
            setReviewData(reviewData);
        }
        if (paymentFrequency === "biWeekly") {
            reviewData = { ...reviewData, plan: 2 };
            setReviewData(reviewData);
        }
        onUpdatePaymentPlan(reviewData);
    };

    return (
        <div
            className={classNames("payment-wrap", {
                "no-payment-required": paymentPlan.total === 0 || !paymentPlan.weeks
            })}
        >
            <Row style={{ padding: 0 }}>
                <PaymentDepositeSlider
                    reviewData={reviewData}
                    setReviewData={setReviewData}
                    onUpdatePaymentPlan={onUpdatePaymentPlan}
                    minimumDepositValue={minimumDepositValue}
                />
            </Row>
            <Row style={{ paddingTop: 0, paddingBottom: 0 }}>
                <PaymentPlanChooseText />
                <PaymentPlanOptions
                    paymentPlan={paymentPlan}
                    reviewData={reviewData}
                    onChangePaymentFrequency={onChangePaymentFrequency}
                />
            </Row>
            <Row style={{ padding: "0px 10px" }}>
                {paymentPlan.total > 0 && (
                    <PaymentAmountDisplay
                        departureDate={departureDate}
                        paymentPlan={paymentPlan}
                        reviewData={reviewData}
                        detailFlight={detailFlight}
                        totalPrice={paymentPlan.total}
                        setReviewData={setReviewData}
                        onUpdatePaymentPlan={onUpdatePaymentPlan}
                    />
                )}
            </Row>

            {balance > 0 && (!paymentPlan.discount || (paymentPlan.discount && !paymentPlan.discount.amount)) && (
                <div
                    className="coupon-cta"
                    onClick={() => {
                        setReviewData({
                            ...reviewData,
                            credit: balance
                        });
                        onUpdatePaymentPlan(
                            {
                                ...reviewData,
                                credit: balance
                            },
                            false
                        );
                    }}
                >
                    Hooray! You have ${balance && parseFloat(balance / 100).toFixed(2)} PLT Credit to spend{" "}
                    <Emoji symbol="😀" label="happy" /> Apply credit <FontAwesomeIcon icon="angle-right" />
                </div>
            )}
        </div>
    );
};
