import { Box, Dialog } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { DateRangePicker } from "react-date-range";
import { getScrollPosition } from "../../../utils/SiteUtils";
import { PrimaryButton } from "../CommonStyle";
import { StyledCalendarPickerContainer } from "./Style";

const CustomMobileDateRangePicker = ({
    handleClose,
    maximumDate,
    minimumDate,
    startDate,
    endDate,
    isOpen,
    handleDateChange
}) => {
    const [selectedRange, setSelectedRange] = useState([
        {
            startDate: startDate ? new Date(startDate) : null,
            endDate: endDate ? new Date(endDate) : null,
            key: "selection"
        }
    ]);
    const scrollableDivRef = useRef(null);

    useEffect(() => {
        setTimeout(() => {
            addMonths();
            scrollableDivRef.current?.scrollTo({
                top: getScrollPosition(".rdrStartEdge"),
                left: 0,
                behavior: "smooth"
            });
        }, 100);
    }, [isOpen]);

    const handleSelect = (ranges) => {
        setSelectedRange([ranges.selection]);
    };

    const confirmDateSelect = () => {
        handleDateChange({
            startDate: selectedRange[0].startDate,
            endDate: selectedRange[0].endDate
        });
        handleClose();
    };

    const createWeekDay = (textContent, div) => {
        var elem = document.createElement("span");
        elem.textContent = textContent;
        elem.className = "rdrWeekDay";
        div.append(elem);
    };

    const addMonths = () => {
        var elements = document.querySelectorAll(".rdrMonth");
        var startOfWeek = document.querySelectorAll(".rdrDays");
        for (var k in elements) {
            var div = document.createElement("div");
            div.className = "rdrWeekDays";
            createWeekDay("Mon", div);
            createWeekDay("Tue", div);
            createWeekDay("Wed", div);
            createWeekDay("Thu", div);
            createWeekDay("Fri", div);
            createWeekDay("Sat", div);
            createWeekDay("Sun", div);
            if (k != 0 && elements[k] && elements[k].style) {
                elements[k].style.marginTop = "40px";
                elements[k].insertBefore(div, startOfWeek[k]);
            }
        }
    };

    return (
        <Dialog open={isOpen} fullScreen>
            <StyledCalendarPickerContainer
                overflow={"auto"}
                height={"90vh"}
                display={"flex"}
                justifyContent={"center"}
                pb={"40px"}
                ref={scrollableDivRef}
            >
                <DateRangePicker
                    ranges={selectedRange}
                    onChange={handleSelect}
                    maxDate={maximumDate ? new Date(maximumDate) : new Date(moment().add("12", "months"))}
                    minDate={minimumDate ? new Date(minimumDate) : new Date()}
                    months={12}
                    showPreview={false}
                    showMonthAndYearPickers={false}
                    showMonthArrow={false}
                    shownDate={selectedRange[0].startDate}
                    scroll={{ enabled: true }}
                    weekStartsOn={1}
                    fixedHeight
                    direction={"vertical"}
                />
            </StyledCalendarPickerContainer>
            <Box mt={"40px"} p={"10px"} width={"100%"}>
                <PrimaryButton onClick={confirmDateSelect} style={{ width: "100%" }}>
                    Done
                </PrimaryButton>
            </Box>
        </Dialog>
    );
};

export default CustomMobileDateRangePicker;
