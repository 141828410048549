import { Box } from "@mui/material";
import React from "react";
import { _url } from "../../../../config/utils";
import moment from "moment";
import { logAmplitudeEvent } from "../../../../utils/log-amplitude-event";

const promoStartInclusive = moment("2024-10-01T00:00:00+10:00");
const promoEndExclusive = moment("2024-10-31T00:00:00+10:00");
const isProduction = window.location.hostname === 'www.paylatertravel.com.au';

export const UABanner = () => {
    if (isProduction && !moment().isBetween(promoStartInclusive, promoEndExclusive, undefined, "[)")) {
        return null;
    }

    return (
        <Box
            id="united-airlines-banner"
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            sx={{ width: "100%" }}
        >
            <picture
                style={{ cursor: "pointer" }}
                onClick={() => {
                    logAmplitudeEvent('promotion-banner-clicked', { promotion: 'united airlines' })
                    window.scrollTo({ top: 0, behavior: "smooth" });
                }}
            >
                <source
                    srcSet={_url("assets/redesign/promotions/united-airlines/banner-1920.webp")}
                    media="(min-width: 1200px)"
                />
                <source
                    srcSet={_url("assets/redesign/promotions/united-airlines/banner-1440.webp")}
                    media="(min-width: 700px)"
                />
                <img
                    src={_url("assets/redesign/promotions/united-airlines/banner-390.webp")}
                    alt={"United Airlines Banner"}
                    style={{
                        width: "100%",
                        height: "auto"
                    }}
                />
            </picture>
        </Box>
    );
};
