import { Grid } from "@mui/material";
import { _url } from "../../../config/utils";
import Styled from "@emotion/styled";

export const ReviewContainer = Styled(Grid)`
    background-image: url(${_url("assets/redesign/icons/review_us_on_trustpilot.png")});
 
 @media (max-width: 900px) {
    background-image: url(${_url("assets/redesign/icons/rate_us_on_trust_pilot_mobile.png")});
    background-size: cover;
    background-repeat: no-repeat;
    }

`;
