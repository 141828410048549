import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Form, Input } from "reactstrap";
import { loginUser } from "../../redux/modules/auth";
import { getReferrals } from "../../redux/modules/referrals";
import { forgotPassword } from "./util";

const LoginForm = () => {
    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const [showForgotPasswordForm, setShowForgotPasswordForm] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleSubmit = (event) => {
        dispatch(
            loginUser({
                email,
                password
            })
        )
            .then(() => {
                if (window.location.pathname === "/login") {
                    navigate("/bookings");
                }
                // get referrals balance
                dispatch(getReferrals("flight"));
            })
            .catch(() => {
                toast.error("Wrong email or password");
            });
        event.preventDefault();
    };

    const sendPasswordReset = (event) => {
        if (email !== "") {
            forgotPassword(email).then(() => {
                setShowForgotPasswordForm(false);
            });

            event.preventDefault();
        }
    };

    return (
        <div>
            {!showForgotPasswordForm && (
                <Form className="login-form" onSubmit={handleSubmit}>
                    <Input
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Email address"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <Input
                        type="password"
                        name="password"
                        id="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <Button block>Continue</Button>
                    <div className="login-form__action-links">
                        <div>
                            <a onClick={() => setShowForgotPasswordForm((prev) => !prev)}>Forgot password?</a>
                        </div>
                        <div>Don&apos;t have an account? Book your first trip!</div>
                    </div>
                </Form>
            )}
            {showForgotPasswordForm && (
                <Form className="login-form login-form__forgot-password" onSubmit={sendPasswordReset}>
                    <p>
                        Enter the email address associated with your account and we’ll email you a link to reset your
                        password.
                    </p>
                    <Input
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Email address"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <Button block>Send reset link</Button>
                    <div className="login-form__action-links">
                        <div>
                            <a onClick={() => setShowForgotPasswordForm(false)}>Back to login</a>
                        </div>
                    </div>
                </Form>
            )}
        </div>
    );
};

export default LoginForm;
