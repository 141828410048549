import { makeAPIRequest, usingFetchAPI } from "./APIRequest";

const toQuerystring = (fields) => fields.map(field => `${field.name}=${field.value}`).join('&');

export const ViewNavigation = ({ navigate, targetHost }) => {
    const executeAction = async (action) => {
        if (action.href.startsWith('plt://')) {
            return executeLocalAction(action);
        }
        if (action.href.startsWith('/')) {
            return executeExternalAction(action);
        };
        throw new Error(`Unsupported dashboard action href=${action.href}`);
    };
    const executeLocalAction = (action) => {
        const targetLocalPath = action.href.split('plt://').join('');
        navigate(targetLocalPath);
    };
    const executeExternalAction = async (action) => {
        try {
            const makeRequest = makeAPIRequest(usingFetchAPI);
            const apiResponse = await makeRequest(action.method, `${targetHost}${action.href}`, { fields: action.fields });
            if (apiResponse.statusCode === 200) {
                const parsedResponseBody = JSON.parse(apiResponse.body);
                if (apiResponse.locationRedirect) {
                    executeAction({
                        method: 'GET',
                        href: apiResponse.locationRedirect
                    });
                    return { status: 'REDIRECT', payload: parsedResponseBody };
                }
                const qs = toQuerystring(action.fields);
                navigate(`/view${action.href}${qs ? `?${qs}` : ''}`);
                return { status: 'VIEW_LOADED', payload: parsedResponseBody };
            }
            if (apiResponse.statusCode === 404) {
                return { status: 'PAGE_NOT_FOUND', payload: {} };
            }
            console.error(`BookingManagement: Unsupported status code (${apiResponse.statusCode}) for action: ${JSON.stringify(action)}`);
            return { status: 'CATCH_ALL_ERROR', payload: {} };
        } catch(e) {
            console.error(`BookingManagement: Failed to fetch action: ${JSON.stringify(action)}.`, e);
            return { status: 'SYSTEM_DOWN', payload: {} };
        }
    };
    return { executeAction };
};