import React from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsAltH, faPen, faPlaneDeparture, faPlaneArrival, faUsers } from "@fortawesome/free-solid-svg-icons";
import * as moment from "moment";
import { _url } from "../config/utils";

const SearchInfo = ({ searchQuery }) => (
    <div className="search-info">
        <div>
            <NavLink className="edit" to="/">
                <img alt="edit" className="ico-search" src={_url("assets/images/pen.svg")} />
            </NavLink>
            <p className="search-info__airport-codes">
                <span>
                    {searchQuery.type === "multi-city" ? searchQuery.multiCityLegs[0].origin.id : searchQuery.origin.id}
                </span>
                <FontAwesomeIcon style={{ margin: "0 5px" }} icon={faArrowsAltH} />
                <span>
                    {searchQuery.type === "multi-city"
                        ? searchQuery.multiCityLegs[searchQuery.multiCityLegs.length - 1].destination.id
                        : searchQuery.destination.id}
                </span>
                <span className="search-info__airport-codes__multi-city-flights">
                    {searchQuery.type === "multi-city" && `(${searchQuery.multiCityLegs.length} flights)`}
                </span>
            </p>
            <p>
                <FontAwesomeIcon icon={faUsers} />
                <span>
                    {searchQuery.adults} Adult
                    {searchQuery.adults > 1 ? "s" : ""} {searchQuery.children}{" "}
                    {searchQuery.children > 1 ? "Children" : "Child"} {searchQuery.infants} Infant
                    {searchQuery.infants > 1 ? "s" : ""}
                </span>
            </p>
            <NavLink className="edit-m" to="/">
                <FontAwesomeIcon color="#47495f" style={{ margin: "0 5px" }} icon={faPen} />
            </NavLink>
        </div>
        <div>
            <p>
                <FontAwesomeIcon icon={faPlaneDeparture} />
                <span>
                    {moment(
                        searchQuery.type === "multi-city"
                            ? searchQuery.multiCityLegs[0].departureDate
                            : searchQuery.departureDate
                    ).format("ddd DD MMM")}
                </span>
            </p>
            {searchQuery.type !== "one-way" && (
                <p>
                    <FontAwesomeIcon icon={faPlaneArrival} />
                    <span>
                        {moment(
                            searchQuery.type === "multi-city"
                                ? searchQuery.multiCityLegs[searchQuery.multiCityLegs.length - 1].departureDate
                                : searchQuery.returnDate
                        ).format("ddd DD MMM")}
                    </span>
                </p>
            )}
        </div>
    </div>
);

export default SearchInfo;
