import { Suspense, lazy } from "react";
import { _url } from "../../../config/utils";
import { IS_USA_SITE } from "../../../constants";
import { getSiteValueByLang } from "../../../utils/SiteUtils";

const Review = lazy(() => import(`./${getSiteValueByLang("reviewSupport")}`));

export const ReviewSupport = () => (
    <div className="end max-w-640">
        <div className="checkout-feature-wrapper">
            <div>
                <img
                    className="checkout-tids"
                    style={{ width: "250px", height: "100%", cursor: "pointer" }}
                    src={_url("assets/images/trustpilot-transparent.png")}
                />
            </div>
            <br />

            <Suspense fallback={"..loading"}>
                <Review />
            </Suspense>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: 30 }}>
                {
                    IS_USA_SITE ? 
                        <img className="checkout-tids" alt="iata-tids" src={_url("assets/images/iata-tids-asta.svg")} /> :
                        <img className="checkout-tids" alt="iata-tids" src={_url("assets/images/iata-tids.png")} />
                }
            </div>
        </div>
    </div>
);
