import AddIcon from "@mui/icons-material/Add";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Grid, TextField } from "@mui/material";
import { useState } from "react";
import { Text } from "../../components/common/typography/Style";
import { ColorTheme } from "../../styles/color";
import { StyledPromoCodeContainer } from "./Style";

const ApplyPromoCode = ({ applyCoupon, paymentPlan }) => {
    const [showPromoCode, setShowPromoCode] = useState(false);
    const [promoCode, setPromoCode] = useState("");

    const handlePromoCodeClick = () => {
        if (paymentPlan.discount && paymentPlan.discount.amount) {
            setPromoCode("");
            applyCoupon("");
            return;
        }
        applyCoupon(promoCode);
    };
    return (
        <Grid container px={[0, 0, undefined]}>
            <Grid item xs={12}>
                <Text
                    size={"xs"}
                    style={{ cursor: "pointer" }}
                    textcolor={ColorTheme.secondary[600]}
                    onClick={() => setShowPromoCode((prev) => !prev)}
                >
                    {!showPromoCode ? <AddIcon /> : <KeyboardArrowUpIcon />} Apply a Promo Code
                </Text>
            </Grid>
            {showPromoCode && (
                <StyledPromoCodeContainer itemx xs={12} mt={"8px"}>
                    <TextField
                        onChange={(e) => setPromoCode(e.target.value)}
                        value={promoCode}
                        fullWidth
                        error={paymentPlan.discount && paymentPlan.discount.errors}
                        InputProps={{
                            endAdornment: (
                                <Text
                                    onClick={handlePromoCodeClick}
                                    size={"xs"}
                                    textweight={"medium"}
                                    textcolor={
                                        paymentPlan.discount && paymentPlan.discount.amount
                                            ? ColorTheme.grayNeutral[700]
                                            : ColorTheme.secondary[600]
                                    }
                                    style={{ cursor: "pointer" }}
                                >
                                    {paymentPlan.discount && paymentPlan.discount.amount ? "Remove" : "Apply"}
                                </Text>
                            )
                        }}
                    />
                    {paymentPlan.discount && paymentPlan.discount.errors && (
                        <Text size={"xs"} textcolor={ColorTheme.system.error["400"]}>
                            {paymentPlan.discount.errors.message}
                        </Text>
                    )}
                    {paymentPlan.discount && paymentPlan.discount.amount && (
                        <Text size={"xs"} textcolor={ColorTheme.primary[600]}>
                            Coupon code applied successfully.
                        </Text>
                    )}
                </StyledPromoCodeContainer>
            )}
        </Grid>
    );
};

export default ApplyPromoCode;
