/* eslint-disable unused-imports/no-unused-imports */
import { Box, Grid, IconButton, Menu } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { _url } from "../../../config/utils";
import { Text } from "../../../components/common/typography/Style";
import { ColorTheme } from "../../../styles/color";

const AccountButton = ({ auth, logoutUser }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate();
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    return (
        <Box display={["none", "none", "block"]}>
            <IconButton onClick={handleClick}>
                <img
                    onMouseEnter={(e) => {
                        e.target.src = _url("assets/redesign/icons/account_logo_blue.svg");
                    }}
                    onMouseLeave={(e) => {
                        e.target.src = _url(
                            `assets/redesign/icons/${anchorEl ? "account_logo_blue.svg" : "account_logo_white.svg"}`
                        );
                    }}
                    src={_url(`assets/redesign/icons/${anchorEl ? "account_logo_blue.svg" : "account_logo_white.svg"}`)}
                    alt={"asset"}
                />
            </IconButton>
            <Menu
                PaperProps={{
                    style: {
                        width: "281px",
                        borderRadius: 20
                    }
                }}
                id="basic-menu"
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={() => setAnchorEl(null)}
            >
                <Grid container>
                    <Grid item xs={12} p={"20px 24px"}>
                        <Text size={"md"} textweight={"semiBold"}>
                            Hi, {auth.user.firstName}
                        </Text>
                        <Text size={"sm"} textweight={"regular"}>
                            {auth.user.email}
                        </Text>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        p={"20px 24px"}
                        borderTop={`1px ${ColorTheme.grayNeutral[200]} solid`}
                        borderBottom={`1px ${ColorTheme.grayNeutral[200]} solid`}
                    >
                        <Text
                            width={"100%"}
                            sx={{
                                cursor: "pointer",
                                ":hover": {
                                    color: ColorTheme.grayNeutral[200]
                                }
                            }}
                            size={"sm"}
                            onClick={() => navigate("/bookings")}
                            textweight={"regular"}
                        >
                            My Bookings
                        </Text>
                        <Text
                            width={"100%"}
                            sx={{
                                cursor: "pointer",
                                ":hover": {
                                    color: ColorTheme.grayNeutral[200]
                                }
                            }}
                            mt={"16px"}
                            size={"sm"}
                            onClick={() => navigate("/refer")}
                            textweight={"regular"}
                        >
                            Credit
                        </Text>
                        <Text
                            width={"100%"}
                            sx={{
                                cursor: "pointer",
                                ":hover": {
                                    color: ColorTheme.grayNeutral[200]
                                }
                            }}
                            mt={"16px"}
                            onClick={() => navigate("/refer")}
                            size={"sm"}
                            textweight={"regular"}
                        >
                            Refer and Get $25!
                        </Text>
                    </Grid>
                    <Grid item xs={12} p={"20px 24px"}>
                        <Text
                            width={"100%"}
                            sx={{
                                cursor: "pointer",
                                ":hover": {
                                    color: ColorTheme.grayNeutral[200]
                                }
                            }}
                            onClick={() => {
                                logoutUser();
                            }}
                            size={"sm"}
                            textweight={"regular"}
                        >
                            Sign Out
                        </Text>
                    </Grid>
                </Grid>
            </Menu>
        </Box>
    );
};

export default AccountButton;
