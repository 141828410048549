import Styled from "@emotion/styled";
import { Grid } from "@mui/material";
import { ColorTheme } from "../../styles/color";

export const StyledPromoCodeContainer = Styled(Grid)`
  
  & .MuiInputBase-root {
    &:focus-visible {
        outline: ${ColorTheme.secondary[600]} auto 1px;
    }
  }
  & .Mui-focused {
        border-color: ${ColorTheme.secondary[600]} !important;
    }
    fieldset {
        &:focus-visible {
        outline: ${ColorTheme.secondary[600]} auto 1px;
    }
    }
  
`;
