import { Box } from "@mui/material";
export const ReviewTermsAndConditions = ({ paymentPlan }) => {
    const terms = paymentPlan.terms;
    if (!terms) return null;
    return terms.map((term) => (
        <div key={term.title} className="review-terms-item">
            <span className="review-terms-title">{term.title}</span>
            <Box
                mt={"2px"}
                className="review-terms-content"
                dangerouslySetInnerHTML={{
                    __html: term.htmlContent
                }}
            />
        </div>
    ));
}
