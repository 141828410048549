import CloseIcon from "@mui/icons-material/Close";
import { Box, Grid, IconButton } from "@mui/material";
import { PrimaryButton } from "../../../components/common/button/ButtonStyle";
import { DisplayText, Text } from "../../../components/common/typography/Style";
import { _url } from "../../../config/utils";
import { ColorTheme } from "../../../styles/color";
import { getSiteValueByLang } from "../../../utils/SiteUtils";
import { logAmplitudeEvent } from "../../../utils/log-amplitude-event";
import { useReview } from "./hook";
import { ReviewContainer } from "./style";

const Review = () => {
    const { booking, navigate, desktopView } = useReview();

    if (!booking) {
        return null;
    }

    return (
        <ReviewContainer
            px={"20px"}
            pt={["100px", "100px", "32px"]}
            minHeight={"100vh"}
            container
            pb={"120px"}
            justifyContent={"center"}
        >
            {!desktopView && (
                <Grid item xs={12} position={"absolute"} right={30} top={"120px"}>
                    <Box display={"flex"} justifyContent={"flex-end"}>
                        <IconButton onClick={() => navigate(`/bookings/${booking.reference}`)}>
                            <CloseIcon style={{ color: ColorTheme.grayNeutral[400], fontSize: 24 }} />
                        </IconButton>
                    </Box>
                </Grid>
            )}

            <Grid
                item
                p={"40px"}
                borderRadius={"10px"}
                bgcolor={ColorTheme.base.white}
                height={"fit-content"}
                width={"484px"}
            >
                <Grid container>
                    <Grid item xs={12}>
                        <Box display={"flex"} gap={"24px"} justifyContent={"center"}>
                            <img
                                width={"114px"}
                                src={_url("assets/redesign/icons/paylater_logo.svg")}
                                alt={"paylater_logo"}
                            />
                            <img
                                width={"114px"}
                                src={_url("assets/redesign/icons/trustpilot_logo.svg")}
                                alt={"trustpilot_logo"}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} py={"32px"}>
                        <Box display={"flex"} justifyContent={"center"}>
                            <img src={_url("assets/redesign/icons/testimonial.svg")} alt={"testimonial"} />
                        </Box>
                        <DisplayText
                            mt={"12px"}
                            textheight={"38px"}
                            textAlign={"center"}
                            size={"sm"}
                            textweight={"bold"}
                        >
                            Review Us On Trustpilot
                        </DisplayText>
                        <Box display={"flex"} mt={"16px"} justifyContent={"center"}>
                            <img
                                width={"172px"}
                                src={_url("assets/redesign/icons/five_stars.svg")}
                                alt={"testimonial"}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} py={["12px", "12px", "32px"]}>
                        <Text
                            textcolor={ColorTheme.grayNeutral[800]}
                            textAlign={"center"}
                            size={"md"}
                            textweight={"regular"}
                        >
                            What made your experience great? What is PayLaterTravel doing well? Voice your opinion and
                            tell us what you think about our business.
                        </Text>
                    </Grid>
                    <Grid item xs={12} py={["12px", "12px", "32px"]}>
                        <PrimaryButton
                            onClick={() => {
                                logAmplitudeEvent("reviewUsOnTrustPilotButtonClicked");
                                window.open(getSiteValueByLang("bookingReview"), "_blank");
                            }}
                            buttoncolor={"#00B67A"}
                            fullWidth
                        >
                            Review us on <img src={_url("assets/redesign/icons/trustpilot_white.svg")} />
                        </PrimaryButton>

                        <Box mt={"16px"}>
                            <Text
                                style={{ cursor: "pointer" }}
                                textcolor={ColorTheme.grayNeutral[600]}
                                textAlign={"center"}
                                size={"md"}
                                onClick={() => navigate(`/bookings/${booking.reference}`)}
                                textweight={"regular"}
                            >
                                No, Thanks
                            </Text>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </ReviewContainer>
    );
};

export default Review;
