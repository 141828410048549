import Styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";
import { ColorTheme } from "./ColorTheme";
import { ViewActions } from "./ViewActions";

const renderBodySection = (section) => {
    const StyledBodyText = Styled(Typography)`
        color: #101828;
        font-family: Inter;
        font-style: normal;
        line-height: 24px;
        font-weight: 400;
        font-size: 14px;
    `;
    const StyledBodyContainer = Styled(Box)`
        padding: 10px;
    `;
    return (
        section.type === "body" && (
            <StyledBodyContainer key={section.type} className="section_type_body">
                <StyledBodyText
                    className="section_type_body_content"
                    dangerouslySetInnerHTML={{ __html: section.params.content }}
                />
            </StyledBodyContainer>
        )
    );
};

const renderTitleSection = (section) => {
    const StyledTitleText = Styled(Typography)`
        font-family: Inter;
        font-size: 60px;
        font-style: normal;
        line-height: 32px;
        letter-spacing: -1.2px;
        color: #101828;
        font-weight: 700;
        font-size: 24px
    `;
    return (
        section.type === "title" && (
            <div key={section.type} className="section_type_title">
                <StyledTitleText className="section_type_title_content">{section.params.content}</StyledTitleText>
            </div>
        )
    );
};

const renderTitleReceivedSection = (section) => {
    const StyledTitle = Styled(Typography)`
        font-family: Inter;
        font-size: 24px;
        font-style: normal;
        line-height: 32px;
        letter-spacing: -1.2px;
        color: #101828;
        font-weight: 700;
    `;
    const StyledSubtitle = Styled(Typography)`
        color: #101828;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        line-height: 24px;
        font-weight: 400;
    `;
    const StyledContainer = Styled(Box)`
        padding: 10px;
        text-align: center;
    `;
    return (
        section.type === "title-received" && (
            <StyledContainer key={section.type} className="section_type_title_received">
                <div>
                    <img alt="email.svg" src="/assets/redesign/icons/email.svg" />
                </div>
                <StyledTitle className="section_type_title_received_title">{section.params.title}</StyledTitle>
                <StyledSubtitle className="section_type_title_received_subtitle">
                    {section.params.subtitle}
                </StyledSubtitle>
            </StyledContainer>
        )
    );
};

const renderBodyError = (section) => {
    const StyledBodyErrorText = Styled(Typography)`
        color: #fff;
        font-family: Inter;
        font-style: normal;
        line-height: 24px;
        font-weight: 400;
        font-size: 14px;
        text-align: center;
    `;
    const StyledBodyErrorContainer = Styled(Box)`
        padding: 10px;
        background-color: ${ColorTheme.system.error[300]};
    `;
    return (
        section.type === "body-error" && (
            <StyledBodyErrorContainer key={section.type} className="section_type_body_error">
                <StyledBodyErrorText
                    className="section_type_body_error_content"
                    dangerouslySetInnerHTML={{ __html: section.params.content }}
                />
            </StyledBodyErrorContainer>
        )
    );
};

const StyledSection = Styled(Box)`
    margin-bottom: 10px;
`;

export const View = ({ model, onActionClick = () => {} }) => {
    return (
        <>
            {model.sections.map((section, index) => (
                <StyledSection key={index}>
                    <>{renderBodySection(section)}</>
                    <>{renderTitleSection(section)}</>
                    <>{renderBodyError(section)}</>
                    <>{renderTitleReceivedSection(section)}</>
                </StyledSection>
            ))}
            <ViewActions actions={model.actions} onClick={onActionClick} />
        </>
    );
};
