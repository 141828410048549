import { Grid } from "@mui/material";
import { ColorTheme } from "../../../styles/color";
import AboutPayLaterSection from "./component/AboutPayLaterSection";
import CraftedBySection from "./component/CraftedBySection";
import JoinGlobalTeamSection from "./component/JoinGlobalTeamSection";
import OurStory from "./component/OurStory";
import ProblemSolvingSection from "./component/ProblemSolvingSection";
import WhyUsSection from "./component/WhyUsSection";

const AboutUs = () => {
    return (
        <Grid pt={"56px"} bgcolor={ColorTheme.grayNeutral[50]} container justifyContent={"center"}>
            <Grid item xs={12} md={10} px={["16px", "16px", "0px"]}>
                <Grid container>
                    <Grid item xs={12}>
                        <AboutPayLaterSection />
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                item
                xs={12}
                px={["16px", "16px", "0px"]}
                py={["56px", "56px", "72px"]}
                bgcolor={ColorTheme.grayNeutral[50]}
            >
                <Grid container justifyContent={"center"}>
                    <Grid item xs={12} md={10}>
                        <WhyUsSection />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={10} px={["16px", "16px", "0px"]} mt={"40px"}>
                <Grid container>
                    <Grid item xs={12} mb={"56px"}>
                        <ProblemSolvingSection />
                    </Grid>
                    <Grid item xs={12} mt={"80px"}>
                        <CraftedBySection />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} mt={"72px"} py={["52px", "52px", "72px"]} bgcolor={ColorTheme.secondary[50]}>
                <Grid container justifyContent={"center"}>
                    <Grid item xs={10}>
                        <OurStory />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={10} px={"10px"} mt={"56px"}>
                <Grid container>
                    <Grid item xs={12}>
                        <JoinGlobalTeamSection />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default AboutUs;
