import { Grid } from "@mui/material";
import { DisplayText, Text } from "../../../../components/common/typography/Style";

const WhyUsSection = () => {
    return (
        <Grid container>
            <Grid item xs={12}>
                <DisplayText size={"sm"} textweight={"bold"}>
                    Why we started
                </DisplayText>
                <Text size={"md"} textweight={"regular"}>
                    Pay Later Travel™ was founded in 2017 in Sydney, Australia with a simple yet powerful mission: to{" "}
                    <span style={{ fontWeight: 600 }}>make travel accessible for everyone.</span>
                </Text>
            </Grid>
            <Grid item xs={12} mt={"16px"}>
                <Grid container columnSpacing={"64px"}>
                    <Grid item xs={12} md={6} mt={"10px"}>
                        <Text size={"md"} textweight={"regular"}>
                            Our founders had seen the crippling effects of debt first-hand and knew there had to be a
                            better, more responsible way to finance travel experiences. To realise this vision, we
                            designed a platform with financial well-being at its core. We rolled out a responsible
                            lay-by system that empowered our customers to book trips using their own money, offering the
                            option to pay in instalments. This innovation enabled effective budgeting, promoted savings,
                            and brought travel dreams to life - all without the need for burdensome debt.
                        </Text>
                    </Grid>
                    <Grid item xs={12} md={6} mt={"10px"}>
                        <Text size={"md"} textweight={"regular"}>
                            Fast forward to today, and we couldn’t be prouder of what we’ve accomplished. Our platform
                            has enabled thousands of customers to explore hundreds of destinations, facilitating
                            countless meaningful connections. Whether it's reuniting families, connecting friends, or
                            unlocking new adventures, we're excited to be creating a more interconnected world, one
                            journey at a time. As we look forward, we recognise our mission contributes to something
                            greater. We're both honoured and invigorated each day by the opportunity to make travel more
                            accessible for everyone and we invite you to join our movement.
                        </Text>
                        <Text size={"md"} textweight={"regular"} mt={"10px"}>
                            As we look forward, we recognise our mission contributes to something greater. We're both
                            honoured and invigorated each day by the opportunity to make travel more accessible for
                            everyone and we invite you to join our movement.
                        </Text>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default WhyUsSection;
