import React from "react";

const Amount = ({ strikePrice, price, currency, showStrikePrice }) => (
    <>
        {showStrikePrice && (
            <small style={{ fontWeight: "lighter", fontSize: "15px" }}>
                <del>{strikePrice}</del>
            </small>
        )}{" "}
        {parseFloat(price).toLocaleString() || 0} <sup>{currency}</sup>
    </>
);

export default Amount;
