import { Box, Grid } from "@mui/material";
import React from "react";
import { DisplayText, Text } from "../../../components/common/typography/Style";

const HomeFeatureCard = ({ icon, title, description }) => {
    return (
        <Grid container>
            <Grid item xs={12}>
                <Box display={"flex"} justifyContent={"center"}>
                    <img src={icon} alt={title} />
                </Box>
            </Grid>
            <Grid item xs={12}>
                <DisplayText align="center" size={"xs"} textweight={"bold"}>
                    {title}
                </DisplayText>
                <Text textcolor={"#4D5761"} size={"md"} align="center" textweight={"regular"}>
                    {description}
                </Text>
            </Grid>
        </Grid>
    );
};

export default HomeFeatureCard;
