import { Box, Grid } from "@mui/material";
import { addWeeks, differenceInDays, format } from "date-fns";
import moment from "moment";
import React, { useMemo, useState } from "react";
import { useGetWindowSize } from "../../../components/common/hook/WindowSize";
import { LinkText, Text } from "../../../components/common/typography/Style";
import { _formatMoney, _url } from "../../../config/utils";
import { ColorTheme } from "../../../styles/color";
import { logAmplitudeEvent } from "../../../utils/log-amplitude-event";
import PaymentLinkDatePicker from "./PaymentLinkDatePicker";
import { getSiteValueByLang } from "../../../utils/SiteUtils";

export const PaymentStartDate = ({ reviewData, setReviewData, onUpdatePaymentPlan, paymentPlan, frequency }) => {
    const [visibleDatepicker, setVisibleDatepicker] = useState(null);
    const { mobileView } = useGetWindowSize();

    const paymentsDisplayData = useMemo(() => {
        if (reviewData.plan === 1) {
            return { count: paymentPlan.weeks, installmentAmount: paymentPlan.pricePerWeek / 100 };
        }
        return { count: paymentPlan.fortnights, installmentAmount: paymentPlan.pricePerFortnight / 100 };
    }, [reviewData.plan, paymentPlan]);

    const onChange = (startDate) => {
        const now = new Date();
        logAmplitudeEvent("paymentStartDateFilled", {
            daysFromToday: differenceInDays(new Date(startDate), now)
        });
        setReviewData({
            ...reviewData,
            startPaymentDate: moment(startDate).format("YYYY-MM-DD"),
            error: {
                startPayment: false
            }
        });
        onUpdatePaymentPlan(
            {
                ...reviewData,
                startPaymentDate: moment(startDate).format("YYYY-MM-DD"),
                error: {
                    startPayment: false
                }
            },
            false
        );
        setVisibleDatepicker(null);
    };
    const handleClick = (event) => {
        setVisibleDatepicker(event.currentTarget);
    };

    return (
        <>
            <Grid container flexWrap={"nowrap"} gap={"10px"} alignItems={"stretch"}>
                <Grid item ml={"7px"}>
                    <Box
                        marginTop={"8px"}
                        height={"84%"}
                        gap={"5px"}
                        display={"flex"}
                        justifyContent={"space-between"}
                        flexDirection={"column"}
                    >
                        <img style={{ height: 8, width: 8 }} src={_url("assets/icons/blue_dot.svg")} />
                        <Box
                            height={"100%"}
                            borderRight={`1px ${ColorTheme.grayNeutral[300]} dashed`}
                            position={"relative"}
                            right={"4px"}
                        />
                        <img style={{ height: 8, width: 8 }} src={_url("assets/icons/dot_hole.svg")} />
                    </Box>
                </Grid>
                <Grid item>
                    <Grid container>
                        <Grid item xs={12}>
                            <Grid container flexWrap={"nowrap"} justifyContent={"space-between"}>
                                <Grid item>
                                    <Text size={mobileView ? "sm" : "md"} textweight={"bold"}>
                                        Starting {format(new Date(reviewData.startPaymentDate), "MMMM dd")}{" "}
                                        <LinkText
                                            itemType="button"
                                            onClick={handleClick}
                                            textsize={mobileView ? "14px" : "16px"}
                                        >
                                            Edit
                                        </LinkText>
                                    </Text>
                                    {!!visibleDatepicker && (
                                        <PaymentLinkDatePicker
                                            isError={reviewData.error.startPayment}
                                            handleClose={() => setVisibleDatepicker(null)}
                                            label="Start date:"
                                            anchorEl={visibleDatepicker}
                                            date={reviewData.startPaymentDate}
                                            numberOfMonths={1}
                                            type={"secondary"}
                                            errorMessage={"You must select a start date"}
                                            handleDateChange={onChange}
                                            maxPaymentStartDate={paymentPlan.repayments?.maxPaymentStartDate}
                                            isDisabled
                                        />
                                    )}
                                </Grid>
                                <Grid item>
                                    <Text size={"md"} textweight={"bold"} textAlign={"right"}>
                                        {getSiteValueByLang("stateOrigin")}$
                                        {_formatMoney(paymentsDisplayData.installmentAmount)}
                                    </Text>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} mt={"10px"}>
                            <Text
                                visibility={paymentsDisplayData.count - 1 !== 0 ? "initial" : "hidden"}
                                size={mobileView ? "xs" : "sm"}
                                textweight={"regular"}
                                mb={"10px"}
                            >
                                + {paymentsDisplayData.count - 1} more payments
                            </Text>
                        </Grid>
                        <Grid item xs={12} mt={"10px"}>
                            <Grid container flexWrap={"nowrap"} justifyContent={"space-between"}>
                                <Grid item xs={10}>
                                    <Text size={"sm"} textweight={"regular"}>
                                        Final Payment{" "}
                                        {paymentsDisplayData.count - 1 !== 0
                                            ? format(
                                                  addWeeks(
                                                      new Date(reviewData.startPaymentDate),
                                                      frequency === "Weekly"
                                                          ? paymentPlan.weeks - 1
                                                          : (paymentPlan.fortnights - 1) * 2
                                                  ),
                                                  "MMM dd"
                                              )
                                            : format(new Date(reviewData.startPaymentDate), "MMM dd")}
                                    </Text>
                                </Grid>
                                <Grid item xs={2}>
                                    <Text size={mobileView ? "sm" : "md"} textweight={"regular"} textAlign={"right"}>
                                        {getSiteValueByLang("stateOrigin")}$
                                        {_formatMoney(paymentsDisplayData.installmentAmount)}
                                    </Text>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};
