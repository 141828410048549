import { createAction } from "../utils";

export const UPDATE_LOADING = "plt-web/loading/UPDATE_LOADING";

const initialState = {
    status: false
};

export default function reducer(state = initialState, { type, ...action }) {
    switch (type) {
        case UPDATE_LOADING: {
            return { status: action.payload };
        }
        default:
            return state;
    }
}

export const updateLoading =
    (data = false) =>
    (dispatch) => {
        dispatch(createAction(UPDATE_LOADING, data));
    };
