import { Box, Grid } from "@mui/material";
import { DisplayText, Text } from "../../../components/common/typography/Style";
import { _url } from "../../../config/utils";
import { ColorTheme } from "../../../styles/color";

const TestimonialContentSection = () => {
    return (
        <Grid container py={["56px", "56px", "0px"]} my={"32px"} justifyContent={"center"}>
            <Grid item xs={12}>
                <Text textAlign={"center"} textcolor={ColorTheme.grayNeutral[700]}>
                    PEOPLE LOVE US
                </Text>
            </Grid>
            <Grid item xs={12} mt={"40px"}>
                <Grid container justifyContent={"center"}>
                    <Grid item xs={12} md={3}>
                        <Box>
                            <DisplayText textAlign={"center"} size={"md"} textweight={"bold"}>
                                10,000+
                            </DisplayText>
                            <Text textAlign={"center"} size={"md"} textweight={"regular"}>
                                E-Tickets sent
                            </Text>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={3} mt={["40px", "40px", "0px"]}>
                        <Box>
                            <DisplayText textAlign={"center"} size={"md"} textweight={"bold"}>
                                1000+
                            </DisplayText>
                            <Text textAlign={"center"} size={"md"} textweight={"regular"}>
                                Reviews on Trustpilot
                            </Text>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={3} mt={["40px", "40px", "0px"]}>
                        <Box>
                            <Box mb={"5px"} mt={"15px"} display={"flex"} justifyContent={"center"}>
                                <img src={_url("assets/redesign/icons/review_img.svg")} />
                            </Box>
                            <Text textAlign={"center"} size={"md"} textweight={"regular"}>
                                Ratings on Trustpilot
                            </Text>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default TestimonialContentSection;
