export const problemSolvingCardsInfo = [
    {
        id: 1,
        title: "Rising travel costs",
        description:
            "The cost of travel often skyrockets the longer you wait, presenting a hefty upfront expense. At PLT, we combat this by securing today's rates, allowing customers to pay the remainder in comfortable instalments.",
        img: "rising_travel.png"
    },
    {
        id: 2,
        title: "Budgeting is challenging",
        description:
            "Balancing life's expenses, especially with travel aspirations in the mix, is hard. At PLT, we've designed our platform to seamlessly fit travel dreams into regular budgeting, accommodating both weekly and fortnightly rhythms.",
        img: "budget_challenge.png"
    },
    {
        id: 3,
        title: "Inaccessible financial solutions",
        description:
            "Our product is designed to be fair and accessible for all, without basing decisions on credit scores. We realised that turning to expensive debt can have lasting negative impacts, so we designed our responsible lay-by system to as an effective alternative to credit cards and loans.",
        img: "financial.png"
    }
];

export const craftedBySectionCard = [
    {
        id: "crafted_1",
        title: "Genuine care",
        description:
            "We care for our customers, their financial wellness and we always aim to uphold the highest standards.",
        img: "customer_care_1.svg"
    },
    {
        id: "crafted_2",
        title: "Partnership & advocacy",
        description:
            "We acknowledge the important role we play and we’re proud to partner with our customers to achieve their goals.",
        img: "agreement_1.svg"
    },
    {
        id: "crafted_3",
        title: "Hassle-free",
        description:
            "We know how daunting booking a trip can be. We aim to make the experience an easy and stress free process.",
        img: "excellence_1.svg"
    }
];

export const JoinTeamImage = [
    {
        id: "jpin_team_1",
        img: "join_team_1.png"
    },

    {
        id: "join_team_2",
        img: "joint_team_2.png"
    },
    {
        id: "join_team_3",
        img: "joint_team_3.png"
    }
];

export const OurStoryInfo = [
    {
        id: "ourStory_1",
        type: "text",
        title: "Origins",
        description1:
            "The spark was ignited when one of our founders had a desire to travel but felt the pinch of the upfront cost.",
        description2:
            "Realising others may also have this need, it began a journey of discovery and soon after, Pay Later Travel was born."
    },
    {
        id: "ourStory_2",
        type: "img",
        imgSrc: "paylater_logo.svg"
    },
    {
        id: "ourStory_3",
        type: "img",
        imgSrc: "aircraft.png"
    },
    {
        id: "ourStory_4",
        type: "text",
        title: "Taking flight",
        description1:
            "We launched our innovative lay-by product for flights in 2017 and it quickly resonated with customers in Australia. The response confirmed we were addressing a real problem that many faced.",
        description2:
            "Our footprint expanded as we grew in Australia, and by 2020, we had expanded to the United States."
    },

    {
        id: "ourStory_5",
        type: "text",
        title: "Connecting the world",
        description1:
            "Every day, we're inspired by the stories we're a part of. We feel an immense pride and responsibility in facilitating global connections.",
        description2:
            "Our committed team continually strives to uphold the highest standards of service and attention for our customers."
    },
    {
        id: "ourStory_6",
        type: "img",
        imgSrc: "around_the_wrold.png"
    },
    {
        id: "ourStory_7",
        type: "img",
        imgSrc: "study_abroad.png"
    },
    {
        id: "ourStory_8",
        type: "text",
        title: "The future",
        description1:
            "We’re excited for what the future holds. Equipped with an ambitious roadmap, our commitment is to continually enhance travel experiences, ensuring our customers always come first in every innovation.",
        description2: ""
    }
];

export const OurStoryMobileInfo = [
    {
        id: "ourStory_2",
        type: "img",
        imgSrc: "paylater_logo.svg"
    },
    {
        id: "ourStory_1",
        type: "text",
        title: "Origins",
        description1:
            "The spark was ignited when one of our founders had a desire to travel but felt the pinch of the upfront cost.",
        description2:
            "Realising others may also have this need, it began a journey of discovery and soon after, Pay Later Travel was born."
    },

    {
        id: "ourStory_3",
        type: "img",
        imgSrc: "aircraft.png"
    },
    {
        id: "ourStory_4",
        type: "text",
        title: "Taking flight",
        description1:
            "We launched our innovative lay-by product for flights in 2017 and it quickly resonated with customers in Australia. The response confirmed we were addressing a real problem that many faced.",
        description2:
            "Our footprint expanded as we grew in Australia, and by 2020, we had expanded to the United States."
    },
    {
        id: "ourStory_6",
        type: "img",
        imgSrc: "around_the_wrold.png"
    },
    {
        id: "ourStory_5",
        type: "text",
        title: "Connecting the world",
        description1:
            "Every day, we're inspired by the stories we're a part of. We feel an immense pride and responsibility in facilitating global connections.",
        description2:
            "Our committed team continually strives to uphold the highest standards of service and attention for our customers."
    },

    {
        id: "ourStory_7",
        type: "img",
        imgSrc: "study_abroad.png"
    },
    {
        id: "ourStory_8",
        type: "text",
        title: "The future",
        description1:
            "We’re excited for what the future holds. Equipped with an ambitious roadmap, our commitment is to continually enhance travel experiences, ensuring our customers always come first in every innovation.",
        description2: ""
    }
];
