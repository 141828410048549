import { Box, Grid } from "@mui/material";
import { useGetWindowSize } from "../../../components/common/hook/WindowSize";
import { DisplayText, Text } from "../../../components/common/typography/Style";
import { ColorTheme } from "../../../styles/color";
import { TestimonialSectionCards } from "../util";
import SectionCard from "./SectionCard";

const TestimonialSection = () => {
    const { desktopView } = useGetWindowSize();
    return (
        <Grid container py={"72px"}>
            <Grid item xs={12}>
                <Box display={"flex"} justifyContent={"center"}>
                    <DisplayText
                        textheight={desktopView ? undefined : "32px"}
                        width={["271px", "100%", "100%"]}
                        textAlign={"center"}
                        size={desktopView ? "sm" : "xs"}
                        textweight={"bold"}
                    >
                        Why people use Pay Travel Later
                    </DisplayText>
                </Box>
                <Text textAlign={"center"} size={"md"} textweight={"regular"} textcolor={ColorTheme.grayNeutral[600]}>
                    Here is why our customers choose us!
                </Text>
            </Grid>
            <Grid item xs={12} mt={"40px"}>
                <Grid container alignItems={"stretch"} columnSpacing={"20px"} justifyContent={"center"}>
                    {TestimonialSectionCards.map((item) => (
                        <Grid item xs={12} md={3} mt={["24px", "24px", "0px"]}>
                            <SectionCard
                                description={item.description}
                                icon={item.icon}
                                title={item.title}
                                key={item.id}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default TestimonialSection;
