import { Box } from "@mui/material";
import { LinkText, Text } from "../../../components/common/typography/Style";
import { ColorTheme } from "../../../styles/color";
import { _url } from "../../../config/utils";

const CustomerFiveStarCard = ({ review }) => {
    const { user, testimonial, link } = review;
    return (
        <Box
            borderRadius={"8px"}
            bgcolor={ColorTheme.base.white}
            boxShadow={"0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)"}
            px={"24px"}
            py={"20px"}
        >
            <Text
                dangerouslySetInnerHTML={{
                    __html: testimonial
                }}
                className="testimonial_text"
                size={"sm"}
                textweight={"regular"}
            />
            <Box py={"16px"} display={"flex"} alignItems={"center"} gap={"8px"}>
                <Box>
                    <img style={{ objectFit: "contain" }} src={_url("assets/redesign/icons/five_stars.svg")} />
                </Box>
                <Box>
                    <Text
                        className="user_text"
                        size={"xs"}
                        textcolor={ColorTheme.grayNeutral[800]}
                        textweight={"medium"}
                    >
                        by {user}
                    </Text>
                </Box>
            </Box>
            <Box display={"flex"} justifyContent={"space-between"}>
                <Box display={"flex"} alignItems={"center"} gap={"2px"}>
                    <LinkText
                        onClick={() => window.open(link, "_blank")}
                        textweight={500}
                        className="link_text"
                        textcolor={ColorTheme.grayNeutral[500]}
                    >
                        Check this on
                    </LinkText>
                    <img
                        onClick={() => window.open(link, "_blank")}
                        style={{ cursor: "pointer" }}
                        height={"17px"}
                        src={_url("assets/redesign/icons/trustpilot_story.svg")}
                    />
                </Box>
                <Box>
                    <img
                        style={{ cursor: "pointer" }}
                        height={"15px"}
                        width={"15px"}
                        onClick={() => window.open(link, "_blank")}
                        src={_url("assets/redesign/icons/arrow_export.svg")}
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default CustomerFiveStarCard;
