/* eslint-disable prefer-destructuring */
/* eslint-disable dot-notation */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable camelcase */
import { useEffect, useState } from "react";
import useOnclickOutside from "react-cool-onclickoutside";
import { useTranslation } from "react-i18next";
import usePlacesAutocomplete, { getGeocode, getLatLng } from "use-places-autocomplete";
import countries from "../config/countries";
import states from "../config/states";
import { getSiteValueByLang } from "../utils/SiteUtils";

const PlacesAutocompleteInput = ({ longLatBias, onSelect, onManualEntryClick }) => {
    const {
        ready,
        value,
        suggestions: { status, data },
        setValue,
        clearSuggestions
    } = usePlacesAutocomplete({
        requestOptions: {
            /* Define search scope here */
            location: new window.google.maps.LatLng(
                getSiteValueByLang("placesAutocompletedLocation").lat,
                getSiteValueByLang("placesAutocompletedLocation").lng
            ),
            radius: 200,
            types: ["address"]
        },
        debounce: 150
    });

    const ref = useOnclickOutside(() => {
        // When user clicks outside of the component, we can dismiss
        // the searched suggestions by calling this method
        clearSuggestions();
    });

    const handleInput = (e) => {
        // Update the keyword of the input element
        setValue(e.target.value);
    };

    const handleSelect =
        ({ description }) =>
        () => {
            // When user selects a place, we can replace the keyword without request data from API
            // by setting the second parameter to "false"
            setValue(description, false);
            clearSuggestions();

            // Get latitude and longitude via utility functions
            getGeocode({ address: description })
                .then((results) => {
                    const { address_components: addressComponents } = results[0];
                    if (!addressComponents) return false;

                    const streetNumber = addressComponents.find((comp) => comp.types.includes("street_number")) || {};
                    const streetName = addressComponents.find((comp) => comp.types.includes("route")) || {};
                    const city =
                        addressComponents.find((comp) => comp.types.includes("locality")) ||
                        addressComponents.find((comp) => comp.types.includes("sublocality")) ||
                        {};
                    const state =
                        addressComponents.find((comp) => comp.types.includes("administrative_area_level_1")) || {};
                    const country = addressComponents.find((comp) => comp.types.includes("country")) || {};
                    const postcode = addressComponents.find((comp) => comp.types.includes("postal_code")) || {};
                    onSelect({
                        addressLine1: `${streetNumber.long_name ? streetNumber.long_name : ""} ${
                            streetName.long_name
                        }`.trim(),
                        addressCity: city.long_name,
                        addressPostcode: postcode.long_name,
                        addressState: state.long_name,
                        addressCountry: country.short_name
                    });
                    if (
                        !streetName.long_name ||
                        !city.long_name ||
                        !postcode.long_name ||
                        !state.long_name ||
                        !country.short_name
                    )
                        onManualEntryClick();
                    return getLatLng(results[0]);
                })
                .then(({ lat, lng }) => {
                    // console.log('📍 Coordinates: ', { lat, lng });
                })
                .catch((error) => {
                    console.log("😱 Error: ", error);
                });
        };

    const renderSuggestions = () =>
        data.map((suggestion) => {
            const {
                place_id,
                structured_formatting: { main_text, secondary_text }
            } = suggestion;

            return (
                <li key={place_id} onClick={handleSelect(suggestion)}>
                    <strong>{main_text}</strong> <small>{secondary_text}</small>
                </li>
            );
        });

    return (
        <div className="PlacesAutocomplete" ref={ref}>
            <input
                value={value}
                onChange={handleInput}
                disabled={!ready}
                // eslint-disable-next-line react/no-unknown-property
                autoComplete="chrome-off"
                placeholder="Billing address"
            />
            {status === "OK" && (
                <ul>
                    {renderSuggestions()}
                    <a className="manual-entry" onClick={onManualEntryClick}>
                        Can&apos;t find your address? Enter manually
                    </a>
                </ul>
            )}
        </div>
    );
};

const BillingAddressForm = ({ onChange, ...rest }) => {
    const [manualInput, setManualInput] = useState(false);
    const [addressLine1, setAddressLine1] = useState();
    const [addressCity, setAddressCity] = useState();
    const [addressPostcode, setAddressPostcode] = useState();
    const [addressState, setAddressState] = useState();
    const [addressCountry, setAddressCountry] = useState(getSiteValueByLang("addressCountry"));
    const { t } = useTranslation();

    useEffect(() => {
        onChange({
            addressLine1,
            addressCity,
            addressPostcode,
            addressState,
            addressCountry
        });
    }, [addressLine1, addressCity, addressCity, addressPostcode, addressState, addressCountry]);

    return (
        <div className="BillingAddressForm">
            {!manualInput && (
                <PlacesAutocompleteInput
                    {...rest}
                    onSelect={(result) => {
                        setAddressLine1(result.addressLine1);
                        setAddressCity(result.addressCity);
                        setAddressPostcode(result.addressPostcode);
                        setAddressState(result.addressState);
                        setAddressCountry(result.addressCountry);
                    }}
                    onManualEntryClick={() => setManualInput(true)}
                />
            )}
            {manualInput && (
                <form onChange={(change) => console.log(change)} className="BillingAddressForm__manualInputs">
                    <div
                        style={{
                            fontWeight: 600,
                            fontSize: "14ox",
                            marginTop: "5px",
                            marginBottom: "10px"
                        }}
                    >
                        Billing details
                    </div>
                    <input
                        type="text"
                        name="addressLine1"
                        placeholder="Street Address*"
                        value={addressLine1}
                        onChange={(e) => setAddressLine1(e.target.value)}
                    />
                    <input
                        type="text"
                        name="addressCity"
                        placeholder="City*"
                        value={addressCity}
                        onChange={(e) => setAddressCity(e.target.value)}
                    />
                    <select
                        name="addressCountry"
                        value={addressCountry}
                        onChange={(e) => setAddressCountry(e.target.value)}
                    >
                        <option disabled>Select Country</option>
                        {countries.map((country) => (
                            <option
                                value={country.code}
                                selected={country.name === getSiteValueByLang("addressCountry")}
                            >
                                {country.name}
                            </option>
                        ))}
                    </select>
                    <select name="addressState" value={addressState} onChange={(e) => setAddressState(e.target.value)}>
                        <option selected disabled>
                            Select State
                        </option>
                        {states[getSiteValueByLang("billingAddressSelectStates")].map((state) => (
                            <option value={state}>{state}</option>
                        ))}
                    </select>
                    <input
                        type="text"
                        name="addressPostcode"
                        placeholder={`${t("zip")}*`}
                        value={addressPostcode}
                        onChange={(e) => setAddressPostcode(e.target.value)}
                    />
                </form>
            )}
        </div>
    );
};

export default BillingAddressForm;
