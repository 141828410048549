import { Box, Grid } from "@mui/material";
import React from "react";
import { useGetWindowSize } from "../../../components/common/hook/WindowSize";
import { DisplayText, Text } from "../../../components/common/typography/Style";
import { ColorTheme } from "../../../styles/color";
import { getSiteValueByLang } from "../../../utils/SiteUtils";
import { DestinationImageCard } from "./style";

const HomePopularDestination = () => {
    const { mobileView } = useGetWindowSize();
    return (
        <Grid container>
            <Grid item xs={12}>
                <Text
                    textcolor={ColorTheme.grayNeutral[500]}
                    textsize={mobileView ? "14px" : undefined}
                    size={"md"}
                    align="center"
                    textweight={"regular"}
                >
                    POPULAR DESTINATIONS
                </Text>
                <DisplayText
                    textheight={mobileView ? "32px" : undefined}
                    textsize={mobileView ? "24px" : ""}
                    size={"md"}
                    align="center"
                    textweight={"bold"}
                >
                    Ready for an adventure?
                </DisplayText>
            </Grid>
            <Grid item xs={12} mt={"40px"} overflow={"auto"}>
                <Grid container display={"flex"} flexWrap={"nowrap"} gap={"9px"}>
                    {getSiteValueByLang("popularDestination").map((item) => (
                        <DestinationImageCard key={item.id} bgimage={item.image}>
                            <DisplayText
                                position={"relative"}
                                zIndex={2}
                                align="center"
                                top={"50%"}
                                textcolor={ColorTheme.base.white}
                                size={"xs"}
                                textweight={"bold"}
                            >
                                {item.title}
                            </DisplayText>
                            <Box
                                className="filter_box"
                                position={"relative"}
                                bottom={"72px"}
                                borderRadius={"4px"}
                                height={"302px"}
                            ></Box>
                        </DestinationImageCard>
                    ))}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default HomePopularDestination;
