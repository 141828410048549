import Styled from "@emotion/styled";
import { Box } from "@mui/material";
import { _url } from "../../../config/utils";
import { ColorTheme } from "../../../styles/color";

export const HomeBackgroundImageContainer = Styled(Box)`
    background-image: ${(props) => `url(${_url(props.bgimage)})`} ;
    background-size: cover;
    min-height: 610px;
    @media (max-width: 900px) {
        background-image: ${(props) => `url(${_url(props.bgimage)})`} ;
        background-size: contain;
        background-repeat: no-repeat;
        background-color: ${ColorTheme.base.white};
    }
`;

export const TicketContainer = Styled(Box)`
     background-image: url("assets/redesign/icons/ticket_icon.svg");
     height: 127px;
     width: 272px;`;

export const BlurDiv = Styled(Box)`
    height: 102px;
    width: 100%;
    position: relative;
    background: linear-gradient(180deg, rgba(233, 248, 251, 0.00) 0%, #EFFAFF 74.48%);
`;

export const DestinationImageCard = Styled(Box)`
    width: 213px;
    height: 302px;
    background-size: cover;
    border-radius: 4px;
    ${(props) =>
        props.bgimage
            ? `
        background-image: url(${props.bgimage});
    `
            : `
        background-color: ${ColorTheme.base.lightWhite};
    `};


    & .filter_box {
        width: 213px;
        filter: opacity(0.1);
        background-color:  ${(props) => (props.bgimage ? ColorTheme.base.black : ColorTheme.base.lightWhite)};
    }
    
    &:hover {
        & .filter_box {
            filter: opacity(0.5);
        }
    }
`;
