import React, { forwardRef } from "react";
import { CustomDateRangePickerWrapper } from "./search/form/Styled";
import DatePicker from "react-datepicker";
import { CustomDatePickerContainer, CustomDatePickerDate, CustomDatePickerLabel } from "./common/Style";
import moment from "moment";

const CustomSingleDatePicker = ({ label, date, handleDateChange, minimumDate }) => {
    const CustomDatePickerInput = forwardRef(({ value, onClick, dateLabel }, ref) => (
        <CustomDatePickerContainer onClick={onClick} ref={ref}>
            <CustomDatePickerLabel>{dateLabel}</CustomDatePickerLabel>
            <CustomDatePickerDate>{value ? moment(value).format("DD MMM") : "Add date"}</CustomDatePickerDate>
        </CustomDatePickerContainer>
    ));

    return (
        <CustomDateRangePickerWrapper>
            <DatePicker
                selected={date ? new Date(date) : null}
                onChange={(date) => {
                    handleDateChange({
                        startDate: date
                    });
                }}
                minDate={new Date(minimumDate)}
                monthsShown={1}
                customInput={<CustomDatePickerInput dateLabel={label} />}
            />
        </CustomDateRangePickerWrapper>
    );
};

export default CustomSingleDatePicker;
