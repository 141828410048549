import React, { useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import { countrySites, getGeoInfo } from "./util";

const CountryModal = () => {
    const [countryCode, setCountryCode] = useState("AU");
    const [countryName, setCountryName] = useState("Australia");
    const [countryModal, setCountryModal] = useState(false);

    useEffect(() => {
        getGeoInfo().then((res) => {
            if (!res || !res.countryCode) {
                return;
            }
            setCountryCode(res.countryCode);
            setCountryName(res.countryName);
            setCountryModal(true);
        });
    }, []);

    if (!countryModal) return null;

    let { hostname, search } = window.location;
    search += `${search === "" ? "?" : "&"}redirectedFrom=${hostname}`;

    return (
        <Modal
            isOpen={true}
            zIndex={1200}
            toggle={() => setCountryModal(false)}
            centered
            className="country-modal text-center"
        >
            <ModalBody>
                <h3>Hello!</h3>
                <p>Seems like you are visiting from {countryName}.</p>
                <a
                    className="btn btn-primary"
                    href={`https://${countrySites[countryCode]}${window.location.pathname}${search}`}
                >
                    Go to the{" "}
                    <b>
                        {countryName} ({countryCode})
                    </b>{" "}
                    site
                </a>
            </ModalBody>
        </Modal>
    );
};

export default CountryModal;
