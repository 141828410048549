import Styled from "@emotion/styled";
import { Button, Box } from "@mui/material";
import { ColorTheme } from "./ColorTheme";

const StandardButton = Styled(Button)`
    border-radius: 8px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    display: flex;
    flex: 1 0 0;
    gap: 8px;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    text-transform: none;
    padding: 12px 20px;
    align-items: center;
    line-height: 24px;
    justify-content: center;
    height: 56px;
`;

const PrimaryButton = Styled(StandardButton)`
    border: 1px solid ${ColorTheme.secondary[500]};
    background: ${ColorTheme.secondary[500]};
    color: #fff;
    &:hover {
        background: ${ColorTheme.secondary[500]};
        opacity: 0.8;
    }
`;

const SecondaryButton = Styled(StandardButton)`
    border: 1px solid ${ColorTheme.grayNeutral[700]};
    background: #fff;
    color: ${ColorTheme.grayNeutral[700]}};
`;

const StyledAction = Styled(Box)`
    margin-top: 10px;
    margin-bottom: 10px;
`;

export const ViewActions = ({ actions, onClick }) => {
    return (
        <>
            {actions.map((action, index) => {
                const Button = index === 0 ? PrimaryButton : SecondaryButton;
                return (
                    <StyledAction key={action.href}>
                        <Button className="action_element" onClick={() => onClick(action)} fullWidth>
                            {action.label}
                        </Button>
                    </StyledAction>
                );
            })}
        </>
    );
};
