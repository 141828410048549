import CloseIcon from "@mui/icons-material/Close";
import { Box, Grid, Modal } from "@mui/material";
import { useCallback, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { brokenImage } from "../../../constants/AppConstants";
import { GallerCarousel } from "./Style";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24
};

const GalleryView = ({ handleClose, propertyImage }) => {
    const [carouselImage, setCarouselImage] = useState(propertyImage);
    const [resfreshKey, setRefreshKey] = useState(Math.random());

    const renderCarousel = useCallback(
        () =>
            carouselImage.map((image, index) => (
                <div key={image.id}>
                    <img
                        src={image.imageUrl}
                        alt={"sildeshow"}
                        onError={(event) => {
                            event.target.src = brokenImage;
                            event.onerror = null;
                            const copyImage = carouselImage;
                            copyImage.splice(index, 1);
                            setCarouselImage(copyImage);
                            setRefreshKey(Math.random());
                        }}
                    />
                </div>
            )),
        [carouselImage]
    );
    return (
        <Modal
            open={true}
            size={"large"}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <div>
                    <Grid container zIndex={10} position={"absolute"} marginTop={"50px"} justifyContent={"end"}>
                        <Grid item marginRight={"50px"}>
                            <CloseIcon
                                style={{
                                    background: "white",
                                    borderRadius: "50%",
                                    fontSize: 40,
                                    cursor: "pointer"
                                }}
                                onClick={() => handleClose()}
                            />
                        </Grid>
                    </Grid>
                    <GallerCarousel key={resfreshKey} autoPlay interval="5000" transitionTime="1000">
                        {renderCarousel()}
                    </GallerCarousel>
                </div>
            </Box>
        </Modal>
    );
};

export default GalleryView;
