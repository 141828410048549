import React from "react";
import { Grid, Typography } from "@mui/material";
import Map from "../../common/Map";
import { HeadingTypography } from "../../common/CommonStyle";
import CardContainerView from "../../common/CardContainer";
import ToggleTextWrapper from "../../common/ToggleTextWrapper";
import { compact } from "lodash";
import CircleIcon from "@mui/icons-material/Circle";

const PropertyDetailOverview = ({ propertyDetail }) => {
    console.log("propertyDetail", propertyDetail);
    const starRating = parseInt(propertyDetail.ratings.property.rating, 10);
    const starIcon = <CircleIcon style={{ fontSize: 20, color: "#19b4d1" }} />;
    const starIcons = Array(starRating).fill(starIcon);
    return (
        <CardContainerView>
            <Grid container spacing={2} style={{ padding: 10 }}>
                <Grid item md={7}>
                    <HeadingTypography fontWeight={"bold"} variant="h4" gutterBottom>
                        {propertyDetail.hotelName}
                    </HeadingTypography>
                    {starIcons}
                    <Grid mt={"20px"}>
                        <Typography fontWeight={"bold"} variant={"h6"}>
                            Popular Amenities
                        </Typography>
                        <Grid mt={"5px"} container spacing={"20px"}>
                            <ToggleTextWrapper
                                readLessText={"Show Less"}
                                readMoreText={"Show More"}
                                boxHeight={200}
                                showReadMoreLess={propertyDetail.amenities.length > 6}
                            >
                                <Grid container spacing={"5px"} style={{ marginBottom: 30 }}>
                                    {compact(propertyDetail.amenities).map((amenity) => (
                                        <Grid item xs={12} md={6} key={amenity}>
                                            <Typography variant={"body1"}>
                                                <span style={{ color: "grey" }}>&#10003;</span> {amenity}
                                            </Typography>
                                        </Grid>
                                    ))}
                                </Grid>
                            </ToggleTextWrapper>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={5}>
                    <Map
                        height={200}
                        latitude={propertyDetail.direction.latitude}
                        longitude={propertyDetail.direction.longitude}
                    />
                    <Typography marginTop="20px" fontWeight="bold">
                        Address
                    </Typography>
                    <Typography variant="body1">
                        {propertyDetail.fullAddress.streetAddress}
                        {propertyDetail.fullAddress?.streetAddress && ", "}
                        {propertyDetail.fullAddress?.city}
                        {propertyDetail.fullAddress?.city && ", "}
                        {propertyDetail.fullAddress?.state}
                        {propertyDetail.fullAddress?.state && ", "}
                        {propertyDetail.fullAddress?.zipCode}
                        {propertyDetail.fullAddress?.zipCode && ", "}
                        {propertyDetail.fullAddress?.country}
                    </Typography>
                </Grid>
            </Grid>
        </CardContainerView>
    );
};

export default PropertyDetailOverview;
