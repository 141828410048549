import { Grid } from "@mui/material";
import { _url } from "../../../../config/utils";
import { DisplayText, Text } from "../../../../components/common/typography/Style";
import { ColorTheme } from "../../../../styles/color";
import { craftedBySectionCard } from "../util";
import CraftedSectionCard from "./CraftedSectionCard";
import { useGetWindowSize } from "../../../../components/common/hook/WindowSize";

const CraftedBySection = () => {
    const { desktopView } = useGetWindowSize();
    return (
        <Grid container>
            <Grid item xs={12}>
                <DisplayText
                    textheight={"42px"}
                    textAlign={"center"}
                    size={desktopView ? "md" : "sm"}
                    textweight={"bold"}
                >
                    Crafted by adventurers, for adventurers
                </DisplayText>
                <Text
                    mt={"16px"}
                    textheight={"24px"}
                    textAlign={"center"}
                    textcolor={ColorTheme.grayNeutral[600]}
                    size={"md"}
                    textweight={"regular"}
                >
                    We’re building our product with our customers needs in mind.
                </Text>
            </Grid>
            <Grid item xs={12} py={"56px"}>
                <img
                    width={"100%"}
                    src={_url(
                        `assets/redesign/icons/${desktopView ? "craft_section.png" : "craft_section_mobile.png"}`
                    )}
                />
            </Grid>
            <Grid item xs={12}>
                <Grid container justifyContent={"center"} columnSpacing={"80px"}>
                    {craftedBySectionCard.map((info) => (
                        <Grid item xs={12} mt={"34px"} md={4} key={info.id}>
                            <CraftedSectionCard info={info} />
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default CraftedBySection;
