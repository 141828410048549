import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import * as moment from "moment";
import { Component } from "react";
import { Trans } from "react-i18next";
import { connect } from "react-redux";
import {
    Button,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Form,
    Label,
    Nav,
    NavItem,
    NavLink,
    Row,
    UncontrolledDropdown
} from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { DropdownHotelGuests, DropdownPassengers } from ".";
import internalApi from "../config/internalApi";
import { FLIGHT_SEARCH_MIN_DAYS_FROM_TODAY, IS_USA_SITE, STAYS_2_0, STAYS_NEW_STICKER } from "../constants";
import { withRouter } from "../hooks/CommonHooks";
import { updateLoading } from "../redux/modules/loading";
import { getReferrals } from "../redux/modules/referrals";
import { updateSearch } from "../redux/modules/search";
import AirportCityTypeahead from "./AirportCityTypeahead";
import { default as CustomSingleDatePicker, default as DatePicker } from "./DatePicker";
import CustomDateRangePicker from "./common/CustomDateRangePicker";
import { NewSticker } from "./common/Style";
import StaySearchForm from "./stays/searchform/StaySearchForm";

const australianStates = {
    QLD: "Queensland",
    NSW: "New South Wales",
    WA: "Western Australia",
    VIC: "Victoria",
    SA: "South Australia",
    TAS: "Tasmania",
    NT: "Northern Territory",
    ACT: "Australian Capital Territory"
};

class SearchForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: props.activeTab,
            isFromFocus: false,
            isToFocus: false,
            hotelDestinations: [],
            hotelDestinationsSearchFilter: "",
            searchQuery: props.searchQuery,
            form: {
                origin: { isTouched: false },
                destination: { isTouched: false },
                departureDate: { isTouched: false },
                returnDate: { isTouched: false },
                adults: { isTouched: false }
            },
            hotelsForm: {
                checkInDate: { isTouched: false },
                checkOutDate: { isTouched: false },
                adults: { isTouched: false }
            }
        };
    }

    componentDidMount() {
        if (this.props.auth.isAuthenticated) this.props.getReferrals("flight");

        // load all hotel destinations
        internalApi.get("/v2/hotel-destinations").then((response) => {
            this.setState({
                hotelDestinations: response.data
            });

            if (this.props.match.params.location && this.props.match.params.state) {
                this.setState({
                    searchQuery: {
                        ...this.state.searchQuery,
                        hotels: {
                            ...this.state.searchQuery.hotels,
                            destination: response.data.find(
                                (x) =>
                                    x.split("_")[0].toLowerCase() ===
                                        this.props.match.params.location.replace("-", " ") &&
                                    x.split("_")[1].replace("/", "").toLowerCase() === this.props.match.params.state
                            )
                        }
                    }
                });
            }
        });

        window.addEventListener("resize", this.updateWindowDimensions);

        const multiCityLegsForm = [];

        if (this.state.searchQuery.flights.multiCityLegs) {
            this.state.searchQuery.flights.multiCityLegs.forEach(() => {
                multiCityLegsForm.push({
                    origin: { isTouched: false },
                    destination: { isTouched: false },
                    departureDate: { isTouched: false }
                });
            });
        }

        this.setState({
            ...this.state,
            form: {
                ...this.state.form,
                multiCityLegs: multiCityLegsForm
            },
            windowWidth: window.innerWidth
        });
    }

    // remove annoying tabbing on multi city because of the clear typeahead buttons
    componentDidUpdate = () =>
        document.querySelectorAll(".rbt-close").forEach((btn) => {
            btn.tabIndex = -1;
        });

    // for multicity location switching to id instead of full name on mobile
    updateWindowDimensions = () => this.setState({ windowWidth: window.innerWidth });

    changeSearchType = (type) =>
        this.setState({
            searchQuery: {
                ...this.state.searchQuery,
                flights: {
                    ...this.state.searchQuery.flights,
                    type
                }
            }
        });

    updateLocation = ({ field, location }) => {
        this.setState({
            searchQuery: {
                ...this.state.searchQuery,
                flights: {
                    ...this.state.searchQuery.flights,
                    [field]: location
                }
            },
            form: {
                ...this.state.form,
                [field]: { isTouched: true }
            }
        });
    };

    addMultiCityLeg = () => {
        const { multiCityLegs } = this.state.searchQuery.flights;
        if (multiCityLegs.length < 5) {
            this.setState({
                searchQuery: {
                    ...this.state.searchQuery,
                    flights: {
                        ...this.state.searchQuery.flights,
                        multiCityLegs: [
                            ...multiCityLegs,
                            {
                                origin: multiCityLegs[multiCityLegs.length - 1].destination,
                                destination: null,
                                departureDate: ""
                            }
                        ]
                    }
                },
                form: {
                    ...this.state.form,
                    multiCityLegs: [
                        ...this.state.form.multiCityLegs,
                        {
                            origin: { isTouched: false },
                            destination: { isTouched: false },
                            departureDate: { isTouched: false }
                        }
                    ]
                }
            });
        }
    };

    removeMultiCityLeg = (index) => {
        const { multiCityLegs } = this.state.searchQuery.flights;
        const multiCityLegsForm = this.state.form.multiCityLegs;

        if (multiCityLegs.length > 2) {
            multiCityLegs.splice(index, 1);
            multiCityLegsForm.splice(index, 1);
            this.setState({
                searchQuery: {
                    ...this.state.searchQuery,
                    flights: {
                        ...this.state.searchQuery.flights,
                        multiCityLegs
                    }
                },
                form: {
                    ...this.state.form,
                    multiCityLegs: multiCityLegsForm
                }
            });
        }
    };

    updateMultiCityLocation = ({ field, location, index }) => {
        const { multiCityLegs } = this.state.searchQuery.flights;
        const multiCityLegsForm = this.state.form.multiCityLegs;

        multiCityLegs[index][field] = location || null;
        multiCityLegsForm[index][field].isTouched = true;

        this.setState({
            searchQuery: {
                ...this.state.searchQuery,
                flights: {
                    ...this.state.searchQuery.flights,
                    multiCityLegs
                }
            },
            form: {
                ...this.state.form,
                multiCityLegs: multiCityLegsForm
            }
        });
    };

    updateMultiCityDepartureDate = (index, date) => {
        if (!date) return;
        const { multiCityLegs } = this.state.searchQuery.flights;
        // start looping from the selected leg
        for (let i = index; i < multiCityLegs.length; i++) {
            // on the first loop, if we aren't changing the last leg date
            if (i === index && i + 1 < multiCityLegs.length) {
                if (date <= new Date(multiCityLegs[index + 1].departureDate)) {
                    multiCityLegs[i].departureDate = moment(date).format("YYYY-MM-DD");
                    break;
                }
            }
            multiCityLegs[i].departureDate = moment(date).format("YYYY-MM-DD");
        }
        this.setState({
            searchQuery: {
                ...this.state.searchQuery,
                flights: {
                    ...this.state.searchQuery.flights,
                    multiCityLegs
                }
            }
        });
    };

    onHotelsSearch = () => {
        const searchQuery = this.state.searchQuery.hotels;

        if (!searchQuery.destination) return toast.error("Please select a destination");

        if (searchQuery.destination) {
            this.props.router.navigate({
                pathname: "/stays/search",
                search: `?${Object.keys(searchQuery)
                    .map((key) => `${key}=${searchQuery[key]}`)
                    .join("&")}`
            });

            if (this.props.editSearch) this.props.editSearch(searchQuery);
        }
    };

    onFlightSearch = () => {
        let isError = false;
        const { form } = this.state;
        const searchQuery = this.state.searchQuery.flights;

        // basic validation checks single destination
        if (searchQuery.type !== "multi-city") {
            // Validate
            const required = ["origin", "destination", "departureDate", "adults"];

            if (searchQuery.type === "return") required.push("returnDate");
            if (searchQuery.type === "one-way") searchQuery.returnDate = undefined;

            required.forEach((field) => {
                if (!searchQuery[field]) {
                    isError = true;
                    toast.error(`Please select a ${field}.`);
                }
            });
        } else if (searchQuery.type === "multi-city") {
            form.multiCityLegs.forEach((leg, index) => {
                Object.keys(form.multiCityLegs[index]).forEach((key) => {
                    if (
                        searchQuery.multiCityLegs[index][key] === null ||
                        searchQuery.multiCityLegs[index][key] === ""
                    ) {
                        form.multiCityLegs[index][key].isTouched = true;
                        isError = true;
                    }
                });
            });
        }

        // update the form state for validation
        this.setState({ form });

        if (!isError) {
            const {
                origin,
                destination,
                departureDate,
                returnDate,
                multiCityLegs,
                currencyCode,
                type,
                c,
                includedAirlineCodes,
                ...others
            } = searchQuery;
            let pathname = "/flights/s/";
            if (type === "multi-city") {
                // multi city search
                multiCityLegs.forEach((x) => {
                    pathname += `${x.origin.id}/${x.destination.id}/${x.departureDate}/`;
                });
            } else {
                // one way or return
                pathname = `/flights/s/${origin.id}/${destination.id}/${departureDate}${
                    returnDate ? `/${returnDate}` : ""
                }`;
            }

            let newOthers = others;
            const usingCredit = this.props.auth.isAuthenticated && this.props.balance > 0;
            if (usingCredit) newOthers = { ...others, c: "t" };

            this.props.router.navigate({
                pathname,
                search: `?${Object.keys(newOthers)
                    .map((key) => `${key}=${newOthers[key]}`)
                    .join("&")}`
            });

            // if edit search is active then we need to update the search
            if (this.props.editSearch) {
                this.props.editSearch(
                    {
                        ...searchQuery,
                        c: usingCredit ? "t" : undefined
                    },
                    this.props.auth
                );
            }
        }
    };

    formatHotelDestination = (destination) =>
        destination.includes("_") ? `${destination.split("_")[0]}, ${destination.split("_")[1].replace("/", "")}` : "";

    useCredit = () => {
        this.setState({ usingCredit: true }, () => {
            toast.success("All flight dates have been enabled.");
        });
    };

    renderFlightPassengerForm = (query) => (
        <DropdownPassengers
            adults={query.adults}
            child={query.children}
            infants={query.infants}
            onChangeAdults={(e) =>
                this.setState({
                    searchQuery: {
                        ...this.state.searchQuery,
                        flights: {
                            ...query,
                            adults: e
                        }
                    },
                    form: {
                        ...this.state.form,
                        adults: { isTouched: true }
                    }
                })
            }
            onChangeChildren={(e) =>
                this.setState({
                    searchQuery: {
                        ...this.state.searchQuery,
                        flights: {
                            ...query,
                            children: e
                        }
                    }
                })
            }
            onChangeInfants={(e) =>
                this.setState({
                    searchQuery: {
                        ...this.state.searchQuery,
                        flights: {
                            ...query,
                            infants: e
                        }
                    }
                })
            }
        />
    );

    renderHotelControls = () => {
        const hotelsQuery = this.state.searchQuery.hotels;
        if (this.props.selectedLocation) hotelsQuery.destination = this.props.selectedLocation;
        const destinations = this.state.hotelDestinations;
        const states = [...new Set(destinations.map((destination) => destination.split("_")[1].replace("/", "")))];
        const renderDestinations = [];
        // eslint-disable-next-line no-restricted-syntax
        for (const [key, value] of Object.entries(australianStates)) {
            if (states.includes(key)) {
                const items = destinations
                    .filter(
                        (destination) =>
                            destination.includes(key) &&
                            (destination
                                .toLowerCase()
                                .includes(this.state.hotelDestinationsSearchFilter.toLowerCase()) ||
                                value.toLowerCase().includes(this.state.hotelDestinationsSearchFilter.toLowerCase()))
                    )
                    .map((destination) => (
                        <DropdownItem
                            onClick={() => {
                                this.setState({
                                    searchQuery: {
                                        ...this.state.searchQuery,
                                        hotels: {
                                            ...hotelsQuery,
                                            destination
                                        }
                                    }
                                });
                                if (this.props.resetSelectedLocation) this.props.resetSelectedLocation();
                            }}
                        >
                            {destination && this.formatHotelDestination(destination)}
                        </DropdownItem>
                    ));
                if (items.length > 0) renderDestinations.push(<DropdownItem header>{value}</DropdownItem>);
                renderDestinations.push(items);
            }
        }

        return (
            <div className="SearchForm__stays" style={{ paddingBottom: 20 }}>
                <UncontrolledDropdown className="SearchForm__dropdown SearchForm__staysDestination">
                    <DropdownToggle caret className="SearchForm__dropdownToggleButton btn-block">
                        <div className="SearchForm__label">Location</div>
                        <div className="SearchForm__fieldValue">
                            {hotelsQuery.destination
                                ? this.formatHotelDestination(hotelsQuery.destination)
                                : "Select your destination"}
                        </div>
                    </DropdownToggle>
                    <DropdownMenu
                        className="SearchForm__dropdownMenu"
                        modifiers={{
                            setMaxHeight: {
                                enabled: true,
                                order: 890,
                                fn: (data) => ({
                                    ...data,
                                    styles: {
                                        ...data.styles,
                                        overflow: "auto",
                                        maxHeight: "300px"
                                    }
                                })
                            }
                        }}
                    >
                        <input
                            type="text"
                            placeholder="Enter your search..."
                            value={this.state.hotelDestinationsSearchFilter}
                            onChange={(e) => this.setState({ hotelDestinationsSearchFilter: e.target.value })}
                            autoFocus
                        />
                        {renderDestinations}
                    </DropdownMenu>
                </UncontrolledDropdown>
                <DropdownHotelGuests
                    adults={hotelsQuery.adults}
                    childAges={hotelsQuery.childAges}
                    rooms={hotelsQuery.rooms}
                    onChangeAdults={(e) => {
                        if (e >= hotelsQuery.rooms) {
                            this.setState({
                                searchQuery: {
                                    ...this.state.searchQuery,
                                    hotels: {
                                        ...hotelsQuery,
                                        adults: e
                                    }
                                },
                                hotelsForm: {
                                    ...this.state.hotelsForm,
                                    adults: { isTouched: true }
                                }
                            });
                        }
                    }}
                    onChangeChildren={(e) => {
                        const { childAges } = hotelsQuery;
                        this.setState({
                            searchQuery: {
                                ...this.state.searchQuery,
                                hotels: {
                                    ...hotelsQuery,
                                    childAges
                                }
                            }
                        });
                    }}
                    addChild={() => {
                        const { childAges } = hotelsQuery;
                        childAges.push(0);
                        this.setState({
                            searchQuery: {
                                ...this.state.searchQuery,
                                hotels: {
                                    ...hotelsQuery,
                                    childAges
                                }
                            }
                        });
                    }}
                    removeChild={() => {
                        const { childAges } = hotelsQuery;
                        childAges.pop(0);
                        this.setState({
                            searchQuery: {
                                ...this.state.searchQuery,
                                hotels: {
                                    ...hotelsQuery,
                                    childAges
                                }
                            }
                        });
                    }}
                    updateChildAge={(e, index) => {
                        const { childAges } = hotelsQuery;
                        childAges[index] = parseInt(e.target.value);
                        this.setState({
                            searchQuery: {
                                ...this.state.searchQuery,
                                hotels: {
                                    ...hotelsQuery,
                                    childAges
                                }
                            }
                        });
                    }}
                    onChangeRooms={(e) => {
                        let { adults } = hotelsQuery;
                        if (e > adults && adults < 5) adults++;
                        this.setState({
                            searchQuery: {
                                ...this.state.searchQuery,
                                hotels: {
                                    ...hotelsQuery,
                                    rooms: e,
                                    adults
                                }
                            }
                        });
                    }}
                />
                <Button onClick={this.onHotelsSearch} type="button" className="SearchForm__submit btn-search">
                    {!this.props.editSearch ? (
                        "Search stays"
                    ) : (
                        <div className="edit-search__icon">
                            <FontAwesomeIcon icon="search" />
                        </div>
                    )}
                </Button>
            </div>
        );
    };

    renderSingleDestinationControls = () => {
        const flightsQuery = this.state.searchQuery.flights;
        const { isAuthenticated } = this.props.auth;
        const { balance } = this.props;

        return (
            <div className="SearchForm__flights">
                <Row>
                    <Col>
                        <div className="SearchForm__flightsSingleDestination">
                            <AirportCityTypeahead
                                id="origin"
                                placeholder="Add location"
                                fieldName="origin"
                                updateLocation={this.updateLocation}
                                selected={flightsQuery.origin ? [flightsQuery.origin] : null}
                                label="Leaving from"
                            />
                            <AirportCityTypeahead
                                id="destination"
                                fieldName="destination"
                                placeholder="Add location"
                                updateLocation={this.updateLocation}
                                label="Going to"
                                selected={flightsQuery.destination ? [flightsQuery.destination] : null}
                            />

                            {flightsQuery.type === "return" && (
                                <CustomDateRangePicker
                                    startDateLabel="Depart"
                                    endDateLabel="Return"
                                    startDate={flightsQuery.departureDate ? moment(flightsQuery.departureDate) : null}
                                    endDate={flightsQuery.returnDate ? moment(flightsQuery.returnDate) : null}
                                    handleDateChange={({ startDate, endDate }) =>
                                        this.setState({
                                            searchQuery: {
                                                ...this.state.searchQuery,
                                                flights: {
                                                    ...this.state.searchQuery.flights,
                                                    departureDate: startDate
                                                        ? moment(startDate).format("YYYY-MM-DD")
                                                        : null,
                                                    returnDate: endDate ? moment(endDate).format("YYYY-MM-DD") : null
                                                }
                                            }
                                        })
                                    }
                                    orientation="verticalScrollable"
                                    minimumNights={0}
                                    minimumDate={
                                        isAuthenticated && balance > 0
                                            ? moment().add("3", "days")
                                            : moment().add(FLIGHT_SEARCH_MIN_DAYS_FROM_TODAY, "days")
                                    }
                                />
                            )}

                            {flightsQuery.type === "one-way" && (
                                <CustomSingleDatePicker
                                    id="single-date"
                                    label="Depart"
                                    date={flightsQuery.departureDate ? moment(flightsQuery.departureDate) : null}
                                    handleDateChange={({ startDate }) =>
                                        this.setState({
                                            searchQuery: {
                                                ...this.state.searchQuery,
                                                flights: {
                                                    ...this.state.searchQuery.flights,
                                                    departureDate: startDate
                                                        ? moment(startDate).format("YYYY-MM-DD")
                                                        : null,
                                                    returnDate: null
                                                }
                                            }
                                        })
                                    }
                                    orientation="verticalScrollable"
                                    minimumDate={
                                        isAuthenticated && balance > 0
                                            ? moment().add("3", "days")
                                            : moment().add(FLIGHT_SEARCH_MIN_DAYS_FROM_TODAY, "days")
                                    }
                                    // calendarInfo={(isAuthenticated && balance > 0 && !this.state.usingCredit) ? () => <div className="using-plt-credit"><a onClick={this.useCredit}>Using PLT Credit? Click here</a></div> : null}
                                />
                            )}
                            {this.renderFlightPassengerForm(flightsQuery)}
                        </div>
                    </Col>
                </Row>
                <Row form>
                    <Col>
                        <Button onClick={this.onFlightSearch} type="button" className="SearchForm__submit btn-search">
                            Search flights
                        </Button>
                    </Col>
                </Row>
            </div>
        );
    };

    renderMultiCityControls = () => {
        const flightsQuery = this.state.searchQuery.flights;
        return (
            <div className="SearchForm__multiCity">
                {flightsQuery.multiCityLegs.map((leg, index) => {
                    console.log(leg.departureDate);
                    return (
                        <div className="SearchForm__multiCity__leg" key={uuidv4()}>
                            <AirportCityTypeahead
                                index={index}
                                id={`multiCityOrigin_${index}`}
                                fieldName="origin"
                                placeholder={this.state.windowWidth < 768 ? "Add" : "Add location"}
                                label={this.state.windowWidth < 768 ? "From" : "Leaving from"}
                                updateLocation={this.updateMultiCityLocation}
                                selected={
                                    leg.origin ? (this.state.windowWidth > 768 ? [leg.origin] : [leg.origin.id]) : null
                                }
                            />
                            <AirportCityTypeahead
                                index={index}
                                id={`multiCityDestination_${index}`}
                                fieldName="destination"
                                placeholder={this.state.windowWidth < 768 ? "Add" : "Add location"}
                                label={this.state.windowWidth < 768 ? "To" : "Going to"}
                                updateLocation={this.updateMultiCityLocation}
                                selected={
                                    leg.destination
                                        ? this.state.windowWidth > 768
                                            ? [leg.destination]
                                            : [leg.destination.id]
                                        : null
                                }
                            />
                            <DatePicker
                                id={`multiCityDate_${index}`}
                                label="Depart"
                                date={leg.departureDate ? moment(leg.departureDate) : null}
                                handleDateChange={({ startDate }) =>
                                    this.updateMultiCityDepartureDate(index, startDate)
                                }
                                minimumDate={
                                    index > 0 && flightsQuery.multiCityLegs[index - 1].departureDate
                                        ? moment(flightsQuery.multiCityLegs[index - 1].departureDate)
                                        : moment().add(FLIGHT_SEARCH_MIN_DAYS_FROM_TODAY, "days")
                                }
                            />
                            <FontAwesomeIcon
                                icon="times"
                                size="lg"
                                className={flightsQuery.multiCityLegs.length < 3 ? "disabled" : ""}
                                onClick={() => this.removeMultiCityLeg(index)}
                            />
                        </div>
                    );
                })}

                {flightsQuery.multiCityLegs.length !== 5 && (
                    <div>
                        <a onClick={this.addMultiCityLeg} className="SearchForm__multiCityAddFlight">
                            + Add another flight
                        </a>
                    </div>
                )}
                <div className="SearchForm__multiCity__footer">
                    {this.renderFlightPassengerForm(flightsQuery)}
                    <Button onClick={this.onFlightSearch} type="button" className="SearchForm__submit btn-search">
                        Search flights
                    </Button>
                </div>
            </div>
        );
    };

    render() {
        const activeTab = this.props.selectedTab || this.state.activeTab;

        const flightsType = this.state.searchQuery.flights.type;

        return (
            <div className="SearchForm">
                {!this.props.editSearch && (
                    <Nav className="SearchForm__tabs" tabs>
                        <NavItem>
                            <NavLink
                                className={classNames({ active: activeTab === 1 })}
                                onClick={() => {
                                    if (this.props.resetSelectedTab) this.props.resetSelectedTab();
                                    this.setState({ activeTab: 1 });
                                }}
                            >
                                Flights
                            </NavLink>
                        </NavItem>
                        {(STAYS_2_0 || (!IS_USA_SITE && !STAYS_2_0)) && (
                            <NavItem>
                                <NavLink
                                    className={classNames({ active: activeTab === 2 })}
                                    onClick={() => {
                                        if (this.props.resetSelectedTab) this.props.resetSelectedTab();
                                        this.setState({ activeTab: 2 });
                                    }}
                                >
                                    {STAYS_NEW_STICKER && <NewSticker>New</NewSticker>}
                                    Stays
                                </NavLink>
                            </NavItem>
                        )}
                    </Nav>
                )}

                {activeTab === 1 && (
                    <Form className="SearchForm__content">
                        <Label className="SearchForm__radio-label">
                            <input
                                type="radio"
                                name="flight-type"
                                onClick={() => this.changeSearchType("return")}
                                checked={flightsType === "return"}
                            />
                            <Trans>roundTrip</Trans>
                        </Label>
                        <Label className="SearchForm__radio-label">
                            <input
                                type="radio"
                                name="flight-type"
                                onClick={() => this.changeSearchType("one-way")}
                                checked={flightsType === "one-way"}
                            />
                            One Way
                        </Label>
                        <Label className="SearchForm__radio-label">
                            <input
                                type="radio"
                                name="flight-type"
                                onClick={() => this.changeSearchType("multi-city")}
                                checked={flightsType === "multi-city"}
                            />
                            Multi City
                        </Label>
                        {flightsType !== "multi-city" && this.renderSingleDestinationControls()}
                        {flightsType === "multi-city" && this.renderMultiCityControls()}
                    </Form>
                )}
                {activeTab === 2 && (
                    <Form style={{ paddingBottom: 0 }} className="SearchForm__content">
                        {STAYS_2_0 ? <StaySearchForm /> : this.renderHotelControls()}
                    </Form>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    searchQuery: state.search.query,
    auth: state.auth,
    balance: state.referrals.balance,
    activeTab: state.search.activeTab || 2
});

const mapDispatchToProps = (dispatch) => ({
    updateSearch: (data, productType) => {
        dispatch(updateSearch(data, productType));
    },
    updateLoading: (status) => {
        dispatch(updateLoading(status));
    },
    getReferrals: (productType) => {
        dispatch(getReferrals(productType));
    }
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchForm));
