import { Box, Grid } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet";
import { Trans } from "react-i18next";
import { useGetWindowSize } from "../../../components/common/hook/WindowSize";
import { DisplayText, Text } from "../../../components/common/typography/Style";
import { ColorTheme } from "../../../styles/color";
import SearchForm from "../searchform/SearchForm";
import { HomeBackgroundImageContainer } from "./style";

const HomepageHeader = ({ selectedTab, selectedLocation, setSelectedLocation, setSelectedTab }) => {
    const { mobileView } = useGetWindowSize();
    return (
        <>
            <Helmet>
                <title>Pay Later Travel | Book Now, Pay Later</title>
            </Helmet>
            <HomeBackgroundImageContainer
                pt={["42px", "42px", "160px"]}
                bgimage={mobileView ? "assets/redesign/home_bg_mobile.png" : "assets/redesign/home_bg.png"}
            >
                <Box px={"16px"}>
                    <DisplayText
                        textAlign={"center"}
                        textsize={mobileView ? "28px" : undefined}
                        textcolor={ColorTheme.base.white}
                        size={"lg"}
                        textweight={"bold"}
                    >
                        <Trans>booknow</Trans>
                    </DisplayText>
                    <Text
                        size={mobileView ? "md" : "xl"}
                        textcolor={ColorTheme.base.white}
                        textAlign={"center"}
                        textweight={"regular"}
                    >
                        <Trans>spreadCost</Trans>
                    </Text>
                </Box>
                <Grid width={"100%"} container mt={"40px"} justifyContent={"center"}>
                    <Grid item width={"100%"} maxWidth={"1140px"}>
                        <SearchForm
                            selectedTab={selectedTab}
                            selectedLocation={selectedLocation}
                            setSelectedTab={setSelectedTab}
                            resetSelectedLocation={() => setSelectedLocation(null)}
                        />
                    </Grid>
                </Grid>
            </HomeBackgroundImageContainer>
        </>
    );
};

export default HomepageHeader;
