/* eslint-disable no-unreachable */
import { Add, AddCircleOutline, Close } from "@mui/icons-material";
import { Box, Grid, IconButton } from "@mui/material";
import moment from "moment";
import React from "react";
import FlightAutocompleteSearch from "../../../../components/common/FlightAutocompleteSearch";
import { PrimaryButton, SecondaryButton, SecondaryTextButton } from "../../../../components/common/button/ButtonStyle";
import MuiSingleDatePicker from "../../../../components/common/datepicker/redesign/MuiSingleDatePicker";
import { useGetWindowSize } from "../../../../components/common/hook/WindowSize";
import { LinkText } from "../../../../components/common/typography/Style";
import { _url } from "../../../../config/utils";
import { FLIGHT_SEARCH_MIN_DAYS_FROM_TODAY } from "../../../../constants";
import { ColorTheme } from "../../../../styles/color";
import FlightSearchTextField from "../mobile/FlightSearchTextField";
import FlightPassengerDropdown from "./FlightPassengerDropdown";

const FlightMultiCityForm = ({
    windowWidth,
    searchQuery,
    updateMultiCityLocation,
    removeMultiCityLeg,
    setSearchQuery,
    setForm,
    onFlightSearch,
    updateMultiCityDepartureDate,
    addMultiCityLeg
}) => {
    const flightsQuery = searchQuery.flights;
    const { mobileView } = useGetWindowSize();

    const renderFlightPassengerForm = () => (
        <Box mt={["10px", "0px"]}>
            <FlightPassengerDropdown
                adults={flightsQuery.adults}
                child={flightsQuery.children}
                infants={flightsQuery.infants}
                cabinClass={flightsQuery.cabinClass || "Y"}
                onChangePassenger={(e) => {
                    setSearchQuery((prev) => ({
                        ...prev,
                        flights: {
                            ...flightsQuery,
                            adults: e.adults,
                            children: e.child,
                            infants: e.infants,
                            cabinClass: e.cabinClass
                        }
                    }));
                    setForm((prev) => ({
                        ...prev,
                        adults: {
                            isTouched: true
                        }
                    }));
                }}
                onChangeAdults={(e) => {
                    setSearchQuery((prev) => ({
                        ...prev,
                        flights: {
                            ...flightsQuery,
                            adults: e
                        }
                    }));
                    setForm((prev) => ({
                        ...prev,
                        adults: {
                            isTouched: true
                        }
                    }));
                }}
                onChangeChildren={(e) => {
                    setSearchQuery((prev) => ({
                        ...prev,
                        flights: {
                            ...flightsQuery,
                            children: e
                        }
                    }));
                }}
                onChangeInfants={(e) => {
                    setSearchQuery((prev) => ({
                        ...prev,
                        flights: {
                            ...flightsQuery,
                            infants: e
                        }
                    }));
                }}
            />
        </Box>
    );

    const renderAutoCompleteSearch = (leg, index) => {
        if (mobileView) {
            return (
                <FlightSearchTextField
                    destination={leg.destination}
                    leaving={leg.origin}
                    id={`multiCity_${index}`}
                    placeholderDestination={"Going to"}
                    placeholderLeaving={"Leaving from"}
                    updateDestination={(data) =>
                        updateMultiCityLocation({
                            ...data,
                            index: index
                        })
                    }
                    updateLeavingFrom={(data) =>
                        updateMultiCityLocation({
                            ...data,
                            index: index
                        })
                    }
                />
            );
        }

        return (
            <Grid container gap={"5px"} alignItems={"center"} flexWrap={"nowrap"}>
                <Grid item width={"50%"}>
                    <FlightAutocompleteSearch
                        index={index}
                        id={`multiCityOrigin_${index}`}
                        fieldName="origin"
                        startIcon={_url("assets/redesign/icons/flight_depart.svg")}
                        placeholder={"Leaving from"}
                        label={"Leaving from"}
                        updateLocation={(data) =>
                            updateMultiCityLocation({
                                ...data,
                                index: index
                            })
                        }
                        selected={leg.origin ? (windowWidth > 768 ? [leg.origin] : [leg.origin.id]) : null}
                    />
                </Grid>
                <Grid item width={"6%"} display={"flex"} justifyContent={"center"}>
                    <img
                        src={_url("assets/redesign/icons/arrow-left-right.svg")}
                        onClick={() => {
                            const origin = leg.destination;
                            const destination = leg.origin;
                            updateMultiCityLocation({
                                index: index,
                                field: "destination",
                                location: destination
                            });
                            updateMultiCityLocation({
                                index: index,
                                field: "origin",
                                location: origin
                            });
                        }}
                        style={{ cursor: "pointer" }}
                    />
                </Grid>
                <Grid item width={"50%"}>
                    <FlightAutocompleteSearch
                        index={index}
                        id={`multiCityDestination_${index}`}
                        fieldName="destination"
                        startIcon={_url("assets/redesign/icons/flight_land.svg")}
                        placeholder={"Going to"}
                        updateLocation={(data) =>
                            updateMultiCityLocation({
                                ...data,
                                index: index
                            })
                        }
                        selected={
                            leg.destination ? (windowWidth > 768 ? [leg.destination] : [leg.destination.id]) : null
                        }
                    />
                </Grid>
            </Grid>
        );
    };
    return (
        <Grid container px={"20px"}>
            {flightsQuery.multiCityLegs.map((leg, index) => (
                <Grid item xs={12} key={index}>
                    <Grid container columnSpacing={"8px"} mt={"20px"}>
                        {index >= 2 && mobileView && (
                            <Grid item xs={12} my={"8px"} display={"flex"} justifyContent={"end"}>
                                <LinkText
                                    onClick={() => removeMultiCityLeg(index)}
                                    textcolor={ColorTheme.system.error[300]}
                                >
                                    Remove
                                </LinkText>
                            </Grid>
                        )}
                        <Grid item xs={12} md={7}>
                            {renderAutoCompleteSearch(leg, index)}
                        </Grid>
                        <Grid item xs={12} md={4} mt={["12px", "12px", "0px"]}>
                            <MuiSingleDatePicker
                                date={
                                    leg.departureDate
                                        ? moment(leg.departureDate)
                                        : moment().add(FLIGHT_SEARCH_MIN_DAYS_FROM_TODAY, "days").format("YYYY-MM-DD")
                                }
                                id={`multiCityDate_${index}`}
                                placeholder={"Depart"}
                                handleDateChange={(startDate) => {
                                    updateMultiCityDepartureDate(index, startDate);
                                }}
                                maximumDate={moment().add("11", "months")}
                                minimumDate={
                                    index > 0 && flightsQuery.multiCityLegs[index - 1].departureDate
                                        ? moment(flightsQuery.multiCityLegs[index - 1].departureDate)
                                        : moment().add(FLIGHT_SEARCH_MIN_DAYS_FROM_TODAY, "days")
                                }
                            />
                        </Grid>
                        {index >= 2 && !mobileView && (
                            <Grid item xs={1}>
                                <IconButton onClick={() => removeMultiCityLeg(index)}>
                                    <Close />
                                </IconButton>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            ))}
            {flightsQuery.multiCityLegs.length !== 5 && (
                <Grid item xs={12} mt={"20px"}>
                    {mobileView ? (
                        <SecondaryTextButton onClick={addMultiCityLeg} startIcon={<AddCircleOutline />}>
                            Add another flight
                        </SecondaryTextButton>
                    ) : (
                        <SecondaryButton onClick={addMultiCityLeg} startIcon={<Add />}>
                            Add Flight
                        </SecondaryButton>
                    )}
                </Grid>
            )}
            <Grid item xs={12} mt={"20px"}>
                <Grid container>
                    <Grid item xs={12} md={3}>
                        {renderFlightPassengerForm(flightsQuery)}
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} mt={"20px"} mb={"24px"}>
                <Grid container justifyContent={"flex-end"}>
                    <Grid item xs={12} md={3}>
                        <PrimaryButton fullWidth onClick={onFlightSearch}>
                            Search flights
                        </PrimaryButton>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default FlightMultiCityForm;
