import Styled from "@emotion/styled";
import { Box } from "@mui/material";
import { ColorTheme } from "../../../styles/color";

export const InstallmentDateContainer = Styled.div`
    cursor: pointer;
    border-radius: 4px;
    border: 2px solid  ${(props) => (props.iserror ? "red" : ColorTheme.primary[500])};
    padding: 13px 20px;
    font-size: 15px;
    display: flex;
`;

export const InstallmentDatePickerLabel = Styled.div`
    margin-right: 10px;
    font-weight: 600;
    color: #47495f;
    font-size: 15px;
    cursor: pointer;
`;

export const InstallmentDateInputValue = Styled.div`
    flex: 1 0;
    font-size: 15px;    
`;

export const DepositAmountButton = Styled(Box)`
    border-radius: 4px;
    border: 1px solid #E5E7EB;
    background: ${(props) => (props.isactive ? ColorTheme.primary[500] : ColorTheme.grayNeutral[50])};
    display: flex;
    padding: 8px 32px;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    align-items: center;
    cursor: pointer;
`;

export const PaymentPlanButton = Styled(Box)`
    border-radius: 8px;
    border: 1px solid ${(props) => (props.isactive ? ColorTheme.primary[500] : ColorTheme.grayNeutral[300])};
    background: ${(props) => (props.isactive ? "#EEF6EC" : ColorTheme.base.white)};
    padding: 16px 8px;
    cursor: pointer;
`;
