import { debounce } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useBooleanState } from "../../../hooks/CommonHooks";
import SearchTextfieldWrapper from "../../common/SearchTextfieldWrapper";
import { getSearchedLocation } from "./Action";
import { SearchLocationContainer } from "./Style";

const SearchLocationView = ({ fromLocation, setFromLocation, destination }) => {
    const [location, setLocation] = useState(fromLocation);
    const [fromSearchedAirports, setFromSearchedAirports] = useState([]);
    const isFromSearchLoading = useBooleanState(false);

    useEffect(() => {
        setLocation(fromLocation);
    }, [fromLocation]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const searchFromAirports = useCallback(
        debounce((text) => {
            if (text) {
                isFromSearchLoading.setTrue();
                getSearchedLocation(
                    text,
                    (res) => {
                        console.log("options", res);
                        setFromSearchedAirports(res);
                        isFromSearchLoading.setFalse();
                    },
                    () => {
                        setFromSearchedAirports([]);
                        isFromSearchLoading.setFalse();
                    }
                );
            }
        }, 300),
        []
    );

    const handleFromSearchTextChange = (text) => {
        setLocation(text);
        searchFromAirports(text);
    };

    return (
        <SearchLocationContainer item xs={12} md={3}>
            <SearchTextfieldWrapper
                name={"from"}
                size={"small"}
                value={location}
                selected={destination || null}
                options={fromSearchedAirports || []}
                onInputChange={handleFromSearchTextChange}
                onOptionSelect={(destinationDetail) => {
                    setFromLocation(destinationDetail);
                }}
                isloading={isFromSearchLoading.value}
                label={"Destination"}
            />
        </SearchLocationContainer>
    );
};

export default SearchLocationView;
