const toQuerystring = (fields) => fields.map(field => `${field.name}=${field.value}`).join('&');

export const makeAPIRequest = (makeRequest) => async (method, href, { fields = [] } = {}) => {
    try {
        if (method === "GET") {
            const qs = toQuerystring(fields);
            const response = await makeRequest(method, `${href}${qs.length ? `?${qs}` : ''}`);
            return response;
        }
        const response = await makeRequest(method, `${href}`, { body: JSON.stringify({ fields }) });
        return response;
    } catch(e) {
        return {
            statusCode: 500,
            body: "{}",
            locationRedirect: ""
        }
    }
};

export const usingFetchAPI = async (method, uri, { body } = {}) => {
    const options = {
        method,
        credentials: 'include',
        headers: new Headers({
            "Content-Type": "application/plt+json",
            "Accept": "application/plt+json"
        })
    };
    if (body) {
        options.body = body;
    }
    try {
        const response = await fetch(uri, options);
        return {
            statusCode: response.status,
            body: await response.text(),
            locationRedirect: response.headers.get('x-location-redirect')
        };
    } catch(e) {
        console.error('Request Failure:', e);
        throw e;
    }
};
