import { Grid } from "@mui/material";
import { useState } from "react";
import { useGetWindowSize } from "../../../../components/common/hook/WindowSize";
import { DisplayText, Text } from "../../../../components/common/typography/Style";
import { _url } from "../../../../config/utils";
import { ColorTheme } from "../../../../styles/color";
import { problemSolvingCardsInfo } from "../util";
import ProblemSolvingCard from "./ProblemSolvingCard";

const ProblemSolvingSection = () => {
    const [hoveredProblem, setHoveredProblem] = useState(problemSolvingCardsInfo[0]);
    const { desktopView } = useGetWindowSize();

    if (desktopView) {
        return (
            <Grid container>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={6}>
                            <img
                                src={_url(
                                    `assets/redesign/icons/${hoveredProblem?.img || problemSolvingCardsInfo[0].img}`
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <DisplayText size={"sm"} textweight={"bold"}>
                                        The problem we are solving
                                    </DisplayText>
                                </Grid>

                                {problemSolvingCardsInfo.map((info) => (
                                    <Grid item xs={12} key={info.id}>
                                        <ProblemSolvingCard
                                            setHoveredCard={setHoveredProblem}
                                            isactive={hoveredProblem?.id === info.id}
                                            info={info}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <DisplayText size={"xs"} textweight={"bold"}>
                    The problem we are solving
                </DisplayText>
            </Grid>
            {problemSolvingCardsInfo.map((info) => (
                <Grid item xs={12} key={info.id} mt={"32px"}>
                    <Grid container>
                        <Grid item xs={12}>
                            <img
                                height={"242px"}
                                width={"100%"}
                                src={_url(`assets/redesign/icons/${info.img}`)}
                                alt={info.title}
                            />
                        </Grid>
                        <Grid
                            px={"32px"}
                            py={"12px"}
                            borderLeft={`4px solid ${ColorTheme.secondary[600]}`}
                            item
                            xs={12}
                        >
                            <Text size={"xl"} textweight={"semiBold"}>
                                {info.title}
                            </Text>
                            <Text textcolor={ColorTheme.grayNeutral[700]} mt={"8px"} size={"md"} textweight={"regular"}>
                                {info.description}
                            </Text>
                        </Grid>
                    </Grid>
                </Grid>
            ))}
        </Grid>
    );
};

export default ProblemSolvingSection;
