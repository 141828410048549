import React from "react";
import { searchFormTitleColor } from "../../../constants/ColorConstants";
import { renderDetailFlight } from "../results/SearchResultHooks";
import { getChangedFlightPrice } from "./util";

const ShowChangedFlightPrice = ({ searchResult, currentFlightPrice }) => (
    <div>
        {renderDetailFlight(searchResult)}
        <div>
            <p style={{ fontSize: 23, fontWeight: 800, color: searchFormTitleColor }}>
                ${parseFloat(getChangedFlightPrice(currentFlightPrice, searchResult.totalCompletePrice)).toFixed(2)}
                <sup>{searchResult.currency.slice(0, -1)}</sup>
            </p>
        </div>
    </div>
);

export default ShowChangedFlightPrice;
