import Styled from "@emotion/styled";
import { Box } from "@mui/material";
import { _url } from "../../config/utils";
import { ColorTheme } from "../../styles/color";
import { Text } from "../common/typography/Style";

export const StyledWarning = Styled(Box)` 
    border-radius: 10px;
    background: var(--White, #FFF);
    display: flex;
    /* Shadow md */
    box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.10);
    padding: 18px 16px;
`;

const Warnings = ({ items = [] }) =>
    items.map((warning, index) => (
        <StyledWarning key={index} my={"32px"} maxWidth={"640px"}>
            <img src={_url("assets/icons/info_green.svg")} />
            <Box className="checkout-warning-item">
                <Text
                    className="checkout-warning-title"
                    textcolor={ColorTheme.grayNeutral[700]}
                    size={"md"}
                    textweight={"semiBold"}
                >
                    {warning.title}
                </Text>
                <Text
                    className="checkout-warning-content"
                    size={"sm"}
                    textweight={"regular"}
                    dangerouslySetInnerHTML={{ __html: warning.htmlContent }}
                />
            </Box>
        </StyledWarning>
    ));

export default Warnings;
