import { Button, Grid, IconButton } from "@mui/material";
import React, { useState } from "react";
import FilterListIcon from "@mui/icons-material/FilterList";
import CloseIcon from "@mui/icons-material/Close";
import { DrawerWrapper } from "./Style";
import HotelSearchFilter from "../HotelSearchFilter";

const MobileStaySearchFilter = ({ selectedFilters, setSelectedFilters }) => {
    const [openDrawer, setOpenDrawer] = useState(false);

    return (
        <Grid container justifyContent={"center"} style={{ background: "#ffff", width: "100%" }}>
            <Grid item xs={12}>
                <Button fullWidth startIcon={<FilterListIcon />} onClick={() => setOpenDrawer(true)}>
                    Filter
                </Button>
            </Grid>
            <DrawerWrapper anchor={"right"} open={openDrawer} onClose={() => setOpenDrawer(false)}>
                <Grid container justifyContent={"end"}>
                    <IconButton onClick={() => setOpenDrawer(false)}>
                        <CloseIcon />
                    </IconButton>
                </Grid>
                <Grid padding={"20px 25px"}>
                    <HotelSearchFilter selectedFilters={selectedFilters} setSelectedFilters={setSelectedFilters} />
                </Grid>
            </DrawerWrapper>
        </Grid>
    );
};

export default MobileStaySearchFilter;
