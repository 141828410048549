/* eslint-disable unused-imports/no-unused-imports */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
import FlightSharpIcon from "@mui/icons-material/FlightSharp";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import React, { useRef, useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { debounce } from "throttle-debounce";
import internalApi from "../config/internalApi";
import { FlightOptionStyle, FLightSubOptionStyled } from "./common/CommonStyle";
import Highlighted from "./common/HighlightText";

function titleCase(str) {
    // Step 1. Lowercase the string
    str = str.toLowerCase();
    // str = "I'm a little tea pot".toLowerCase();
    // str = "i'm a little tea pot";

    // Step 2. Split the string into an array of strings
    str = str.split(" ");
    // str = "i'm a little tea pot".split(' ');
    // str = ["i'm", "a", "little", "tea", "pot"];

    // Step 3. Create the FOR loop
    for (let i = 0; i < str.length; i++) {
        str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
    }

    // Step 4. Return the output
    return str.join(" "); // ["I'm", "A", "Little", "Tea", "Pot"].join(' ') => "I'm A Little Tea Pot"
}
/**
 * props
 * - "id": unique indentifier
 * - "updateLocation": callback to do something with the selected location (returns an object {id, name})
 * - "selected": pass down to the Typeahead component
 * - "placeholder"
 * - "isInvalid": TODO validations
 */

const AirportCityTypeahead = ({ label, id, updateLocation, fieldName, index, selected, placeholder }) => {
    const [placesList, setPlacesList] = useState([]);
    const ref = useRef(null);
    const [searchedText, setSearchedText] = useState("");
    const theme = useTheme();
    const mobileView = useMediaQuery(theme.breakpoints.down("769"));

    const search = (keyword) => {
        setSearchedText(keyword);
        if (keyword === "") {
            setPlacesList([]);
            return false;
        }

        internalApi
            .get("/search/airport", {
                keyword,
                subType: "AIRPORT",
                max: 10
            })
            .then((res) => {
                const places = [];
                if (res.data.length > 0) {
                    res.data.forEach((p) => {
                        const city = titleCase(p.address_city_name);
                        const name = titleCase(p.name);
                        const country = titleCase(p.address_country_name);
                        const state = titleCase(p.state);
                        places.push({
                            id: p.iata_code,
                            name,
                            city,
                            country,
                            state
                        });
                    });
                }
                setPlacesList(places);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getOptions = () => {
        if (mobileView || placesList.length !== 0) {
            return placesList;
        }
        return selected || [];
    };

    return (
        <div className="SearchForm__input" onClick={() => ref.current.focus()}>
            <div className="SearchForm__label">{label}</div>
            <Typeahead
                labelKey={(option) =>
                    option.id
                        ? option.city
                            ? `${option?.city || ""} (${option?.id || ""} - ${option?.name || ""}) ${
                                  option?.state || ""
                              }, ${option?.country || ""}`
                            : option.name
                            ? `${option.name} (${option.id})`
                            : option.id
                        : option
                }
                renderMenuItemChildren={(option) => (
                    <div>
                        <Box py={"10px"} display={"flex"} gap={"10px"}>
                            <Box>
                                <FlightSharpIcon style={{ transform: "rotate(45deg)" }} />
                            </Box>
                            <Box>
                                <FlightOptionStyle
                                    style={{
                                        fontWeight: "bold",
                                        marginBottom: 0
                                    }}
                                >
                                    <Highlighted highlight={searchedText} text={option.city} /> (
                                    <Highlighted highlight={searchedText} text={option.id} />
                                    {" - "}
                                    <Highlighted highlight={searchedText} text={option.name} />)
                                </FlightOptionStyle>
                                <FLightSubOptionStyled textsize={"12px"} style={{ marginBottom: 0 }}>
                                    <Highlighted highlight={searchedText} text={option.state} />,{" "}
                                    <Highlighted highlight={searchedText} text={option.country} />
                                </FLightSubOptionStyled>
                            </Box>
                        </Box>
                    </div>
                )}
                id={id}
                clearButton
                options={getOptions()}
                maxHeight="300px"
                placeholder={placeholder}
                onInputChange={debounce(200, search)}
                onChange={(selectedLocation) =>
                    updateLocation({
                        field: fieldName,
                        location: selectedLocation[0],
                        index: index
                    })
                }
                ref={ref}
                filterBy={["id", "airport", "city", "country", "state"]}
                selected={selected || []}
                className={`SearchForm__typeahead ${id}-field autoComplete`}
            />
        </div>
    );
};

export default AirportCityTypeahead;
