import { Box } from "@mui/material";
import { _url } from "../../../../config/utils";
import { windowScroll } from "../../../../utils/SiteUtils";
import moment from "moment/moment";
import { logAmplitudeEvent } from "../../../../utils/log-amplitude-event";

const promoStartInclusive = moment("2024-10-01T00:00:00+10:00");
const promoEndExclusive = moment("2024-10-31T00:00:00+10:00");
const isProduction = window.location.hostname === 'www.paylatertravel.com.au';

export const UAAnnouncementBar = () => {
    if (isProduction && !moment().isBetween(promoStartInclusive, promoEndExclusive, undefined, '[)')) {
        return null;
    }

    return (
        <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            gap={"1rem"}
            sx={{
                cursor: "pointer",
                padding: "0 1rem",
                backgroundColor: "#1B8699",
                height: ["52px", "56px", "57px"]
            }}
            onClick={() => {
                logAmplitudeEvent('promotion-announcement-bar-clicked', { promotion: 'united airlines' })
                windowScroll("united-airlines-banner");
            }}
        >
            <Box
                component="img"
                src={_url("assets/redesign/promotions/united-airlines/logo.webp")}
                alt={"United Airlines"}
                sx={{ height: ["24px", "34px", "34px"] }}
            />
            <Box sx={{ fontSize: ["12px", "16px", "16px"], fontWeight: "bold" }}>
                Book with United Airlines for only $49 deposit
            </Box>
        </Box>
    );
};
