import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Box, Grid, IconButton } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { ColorTheme } from "../../../styles/color";
import { customerStories } from "../util";
import StoryCard from "./StoryCard";

const TestimonialCarousel = () => {
    const containerRef = useRef(null);
    const [currentSlide, setCurrentSlide] = useState(0);

    const handleNextSlide = () => {
        const nextSlide = (currentSlide + 1) % customerStories.length;
        setCurrentSlide(nextSlide);
        scrollToSlide(nextSlide);
    };

    const handlePrevSlide = () => {
        const prevSlide = (currentSlide - 1 + customerStories.length) % customerStories.length;
        setCurrentSlide(prevSlide);
        scrollToSlide(prevSlide);
    };

    const scrollToSlide = (slideIndex) => {
        const scrollLeft = slideIndex * 990;
        if (containerRef.current) {
            containerRef.current.scrollTo({
                left: scrollLeft,
                behavior: "smooth"
            });
        }
    };

    useEffect(() => {
        scrollToSlide(currentSlide);
    }, [currentSlide]);

    return (
        <Grid container justifyContent={"center"}>
            <Grid item xs={12} md={10}>
                <Grid container alignItems={"center"}>
                    <Grid item xs={1} display={["none", "none", "flex"]} justifyContent={"flex-end"}>
                        <IconButton onClick={() => handlePrevSlide()}>
                            <ArrowBackIosIcon />
                        </IconButton>
                    </Grid>
                    <Grid item xs={12} md={10}>
                        <Box display={"flex"} justifyContent={"center"}>
                            <Box
                                ref={containerRef}
                                width={["100%", "100%", "957px"]}
                                display={"flex"}
                                gap={"50px"}
                                overflow={["auto", "auto", "hidden"]}
                                flexWrap={"nowrap"}
                            >
                                {customerStories.map((story) => (
                                    <Box key={story.id}>
                                        <StoryCard
                                            link={story.link}
                                            testimonial={story.testimonial}
                                            name={story.user}
                                        />
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={1} display={["none", "none", "block"]}>
                        <IconButton onClick={handleNextSlide}>
                            <ArrowForwardIosIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Box display={"flex"} mt={["20px", "20px", "0px"]} justifyContent={"center"} gap={"5px"}>
                    {Array(customerStories.length)
                        .fill(1)
                        .map((_, index) => (
                            <Box
                                bgcolor={
                                    currentSlide === index ? ColorTheme.secondary[600] : ColorTheme.grayNeutral[200]
                                }
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    setCurrentSlide(index);
                                }}
                                key={index}
                                height={"8px"}
                                width={currentSlide === index ? "120px" : "8px"}
                                borderRadius={currentSlide === index ? "20px" : "50%"}
                            />
                        ))}
                </Box>
            </Grid>
        </Grid>
    );
};

export default TestimonialCarousel;
