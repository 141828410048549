import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Grid, IconButton } from "@mui/material";
import { Formik } from "formik";
import React from "react";
import { PrimaryButton } from "../../../components/common/button/ButtonStyle";
import FormikInputField from "../../../components/common/fields/FormikInputField";
import { DisplayText } from "../../../components/common/typography/Style";
import { ColorTheme } from "../../../styles/color";
import { useFinaliseAccount } from "./hook";
import { FinaliseContainer } from "./style";
import { updatePasswordFormValidation, updatePasswordInitialValue } from "./util";

const FinaliseAccount = () => {
    const {
        updatePassword,
        setShowConfirmPasswordIcon,
        setShowPasswordIcon,
        showConfirmPasswordIcon,
        showPasswordIcon
    } = useFinaliseAccount();
    return (
        <FinaliseContainer p={"20px"} pt={"120px"} container justifyContent={"center"} minHeight={"100vh"}>
            <Grid
                item
                p={"40px"}
                borderRadius={"10px"}
                bgcolor={ColorTheme.base.white}
                height={"fit-content"}
                width={"484px"}
            >
                <Formik
                    initialValues={updatePasswordInitialValue}
                    validationSchema={updatePasswordFormValidation}
                    enableReinitialize={true}
                    onSubmit={updatePassword}
                >
                    {({ handleSubmit }) => (
                        <Grid container>
                            <Grid item xs={12}>
                                <DisplayText textAlign={"center"} size={"xs"} textweight={"bold"}>
                                    Finalise Account
                                </DisplayText>
                            </Grid>
                            <Grid item xs={12} mt={"24px"}>
                                <FormikInputField
                                    inputType={"label"}
                                    name={`password`}
                                    fieldLabel={"Password"}
                                    id={`password`}
                                    style={{ height: 58 }}
                                    isMandatory
                                    type={showPasswordIcon ? "text" : "password"}
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton onClick={() => setShowPasswordIcon((prev) => !prev)}>
                                                {showPasswordIcon ? <RemoveRedEyeIcon /> : <VisibilityOffIcon />}
                                            </IconButton>
                                        )
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} mt={"24px"}>
                                <FormikInputField
                                    name={`confirmPassword`}
                                    id={`confirmPassword`}
                                    inputType={"label"}
                                    fieldLabel={"Confirm Password"}
                                    isMandatory
                                    style={{ height: 58 }}
                                    label={"Confirm Password"}
                                    type={showConfirmPasswordIcon ? "text" : "password"}
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton onClick={() => setShowConfirmPasswordIcon((prev) => !prev)}>
                                                {showConfirmPasswordIcon ? (
                                                    <RemoveRedEyeIcon fontSize="24px" />
                                                ) : (
                                                    <VisibilityOffIcon fontSize="24px" />
                                                )}
                                            </IconButton>
                                        )
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} mt={"24px"}>
                                <PrimaryButton onClick={() => handleSubmit()} fullWidth>
                                    Sign Up
                                </PrimaryButton>
                            </Grid>
                        </Grid>
                    )}
                </Formik>
            </Grid>
        </FinaliseContainer>
    );
};

export default FinaliseAccount;
