import Styled from "@emotion/styled";
import { Box, Button, TextField } from "@mui/material";

export const StyledCustomDatePicker = Styled.div`
 & .react-datepicker {
  background: #FFFFFF;
  border: 1px solid #E5E7EB;
  box-shadow: 0px 4px 6px rgba(17, 24, 39, 0.05), 0px 12px 16px rgba(17, 24, 39, 0.1);
  border-radius: 6px;
  @media (max-width: 450px) {
    margin-top: 50px;
  }
 }
 & .react-datepicker__header {
  background: #FFFFFF;
  border: 0px;
 }
  
 input {
  width: 100%;
  height: 38px;
  border: 1px solid ${(props) => props.isError && "#dc3545"}
}

& .react-datepicker__day {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  padding: 5px;
  line-height: 26px;
  text-align: center;
  color: #262A36;
  }

& .react-datepicker__day--keyboard-selected {
  background: #19B4D1;
  border-radius: 100px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 26px;
  padding: 5px;
  text-align: center;
  color: #FFFFFF;
  }

& .react-datepicker__day--selected:hover, & .react-datepicker__day--selected {
  background: #19B4D1;
  border-radius: 100px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 26px;
  padding: 5px;
  text-align: center;
  color: #FFFFFF;
}
  & .react-datepicker__day-name {
    padding: 5px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 26px;
    align-items: center;
    text-align: center;
    color: #262A36;
  }
`;

export const MonthText = Styled.span`
font-style: normal;
font-weight: 600;
font-size: 21.33px;
line-height: 26px;
align-items: center;
color: #262A36;
`;

export const CancelDateSelectButton = Styled(Button)`
  border: 1px solid #D1D5DB;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #374151;
`;

export const ApplyDateSelectButton = Styled(Button)`
  background: #262A36;
  border-radius: 6px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
  &:hover {
    opacity: 0.8;
    background: #262A36;
  }
`;

export const DatePickerHeaderContainer = Styled.div`
  padding: 20px;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #E5E7EB;
`;

export const StyledSelect = Styled.select`
  background: #fff;
  font-family: "Inter";
  border: 0px;
  font-size: 16px;

  option {
    font-family: "Inter";
  }
`;

export const StyledDatePickerTextField = Styled(TextField)`
 height: 54px;
  ${(props) =>
      props.textfieldtype === "secondary" &&
      `
  & .MuiInputBase-root {
    height: 54px;
  };
  `};


 ${(props) =>
     props.textfieldtype !== "secondary" &&
     `
 border: 1px solid #d1d1d1 !important;
 & .MuiInputLabel-root {
    position: absolute;
    top: 15px;
    font-weight: bold;
    color: #000;
 }

 & .MuiOutlinedInput-notchedOutline {
  border: 0px !important;
 }
 input {
    font-size: 14px!important;
    line-height: 1.5;
    color: #1e1e1e;
    border: 0px;
    border: 0px !important;
 }`}
`;

export const FormikStyledTextField = Styled(TextField)`
  background: #fff; 
  border-radius: 10px;
  width: 100%;
& .MuiInputBase-root{
    height: 33px;
    margin-top: 5px;
     
    ${(props) =>
        props.redesign &&
        `
  height: 52px;
  `}
}


`;

export const StyledDatePickerContainer = Styled(Box)`
  & .MuiPaper-root {
   button {
    background-color: red !important;
   }
    & .Mui-selected {
    background-color: red !important;
  }
  }
`;

export const StyledCalendarPickerContainer = Styled(Box)`
  & .rdrDefinedRangesWrapper {
    display: none;
  }
  & .rdrInfiniteMonths {
    overflow: hidden;
  }
  & .react-datepicker {
    width: 100%;
    border: 0px;
    
  & .react-datepicker__month-container {
    width: 100% !important;
  }

  &.rdrDateRangePickerWrapper {
    display: none !important;
  }
  
  & .react-datepicker__day-name {
    height: 36px;
    width: 36px;
    border-radius: 50%;
    padding-top: 4px;
  }
  & .react-datepicker__week {
    & .react-datepicker__day {
    height: 36px;
    width: 36px;
    border-radius: 50%;
    padding-top: 4px;
  }
  }
  
  }

`;
