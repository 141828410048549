/* eslint-disable camelcase */
import React from "react";
import { _url } from "../config/utils";

const CardDetails = (props) => {
    if (!props.card) return false;
    const { brand, last4, expMonth, expYear, exp_month, exp_year } = props.card.card || props.card;
    return (
        <div className="CardDetails">
            {/** American Express, Diners Club, Discover, JCB, MasterCard, UnionPay, Visa, or Unknown. */}
            <span
                className="CardDetails__image"
                style={{
                    backgroundImage: `url(${_url(`assets/images/cc/${brand.split(" ").join("").toLowerCase()}.png`)})`
                }}
            />
            <span className="CardDetails__last4">&#8226;&#8226;&#8226;&#8226; {last4}</span>
            {!props.simple && (
                <span className="CardDetails__expiry">
                    {exp_month || expMonth}/{exp_year || expYear}
                </span>
            )}
        </div>
    );
};

export default CardDetails;
