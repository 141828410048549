export const whilistEmbedColor = "#19B4D1";

export const ColorTheme = {
    base: {
        white: "#fff",
        lightWhite: "#cccccc",
        black: "#000"
    },
    grayNeutral: {
        10: "#efefef",
        25: "#FCFCFD",
        30: "#EEF6EC",
        50: "#F9FAFB",
        100: "#F3F4F6",
        200: "#E5E7EB",
        300: "#D2D6DB",
        400: "#9DA4AE",
        500: "#6C737F",
        600: "#4D5761",
        700: "#384250",
        800: "#1F2A37",
        900: "#111927",
        1000: "#D0D5DD"
    },
    primary: {
        25: "#FCFCFD",
        50: "#DEEEDA",
        100: "#CDE5C7",
        200: "#BCDDB5",
        300: "#9BCB90",
        400: "#79BA6B",
        500: "#58A946",
        600: "#509A40",
        700: "#3E7832",
        800: "#305D27",
        900: "#25471D"
    },
    secondary: {
        20: "#E9F8FB",
        25: "#EEF6EC",
        30: "#1B8699",
        50: "#E9F8FB",
        100: "#D4F2F7",
        150: "#19B4D1",
        200: "#BEEBF3",
        250: "#26BDD7",
        300: "#A8E5EF",
        400: "#7DD7E7",
        500: "#26BDD7",
        600: "#23ACC4",
        700: "#1B8699",
        800: "#156876",
        900: "#104F5A",
        950: "#19b4d1",
        1000: "#23ACC4"
    },
    system: {
        error: {
            50: "#FEF3F2",
            100: "#FEE4E2",
            200: "#FECDCA",
            300: "#F04438",
            400: "#B42318"
        },
        warning: {
            50: "#FFFCF5",
            100: "#FEF0C7",
            200: "#FEDF89",
            300: "#F79009",
            400: "#DC6803"
        }
    }
};
