import { Grid } from "@mui/material";
import { PrimaryButton } from "../../../../components/common/button/ButtonStyle";
import { useGetWindowSize } from "../../../../components/common/hook/WindowSize";
import { DisplayText, Text } from "../../../../components/common/typography/Style";
import { _url } from "../../../../config/utils";
import { ColorTheme } from "../../../../styles/color";
import { StyledImage } from "../style";
import { JoinTeamImage } from "../util";

const JoinGlobalTeamSection = () => {
    const { desktopView } = useGetWindowSize();
    return (
        <Grid container mb={"56px"} justifyContent={"center"}>
            <Grid item xs={12}>
                <DisplayText
                    textAlign={"center"}
                    textheight={"38px"}
                    size={desktopView ? "md" : "sm"}
                    textweight={"bold"}
                >
                    Join our global dynamic team
                </DisplayText>
            </Grid>
            <Grid item mt={"16px"} xs={12} md={4}>
                <Text textAlign={"center"} size={"md"} textweight={"regular"} textcolor={ColorTheme.grayNeutral[600]}>
                    We strive to create a growth culture both on business and personal level people involved
                </Text>
            </Grid>
            <Grid item xs={12} mt={"40px"}>
                <Grid container justifyContent={"center"}>
                    <Grid item width={"258px"}>
                        <PrimaryButton
                            onClick={() =>
                                window.open("https://www.linkedin.com/company/paylater-travel/jobs/", "_blank")
                            }
                            fullWidth
                        >
                            Join the team
                        </PrimaryButton>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} mt={"40px"} p={"20px"} overflow={"auto"}>
                <Grid container width={["800px", "800px", "100%"]} alignItems={"center"} columnSpacing={"33px"}>
                    {JoinTeamImage.map((teamImg) => (
                        <Grid item xs={4} key={teamImg.id}>
                            <StyledImage
                                width={"100%"}
                                height={desktopView ? "357px" : "230px"}
                                src={_url(`assets/redesign/icons/${teamImg.img}`)}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default JoinGlobalTeamSection;
