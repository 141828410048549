import { Box, Grid } from "@mui/material";
import { useGetWindowSize } from "../../../../components/common/hook/WindowSize";
import { DisplayText, Text } from "../../../../components/common/typography/Style";
import { _url } from "../../../../config/utils";
import { ColorTheme } from "../../../../styles/color";
import { OurStoryInfo, OurStoryMobileInfo } from "../util";

const OurStory = () => {
    const { desktopView } = useGetWindowSize();
    const renderView = (info) => {
        if (info.type === "img") {
            return (
                <Box mt={["0px", "0px", "40px"]} pl={"16px"}>
                    <img
                        height={"188px"}
                        style={{ objectFit: "contain" }}
                        width={"289px"}
                        src={_url(`assets/redesign/icons/${info.imgSrc}`)}
                    />
                </Box>
            );
        }
        return (
            <Grid container pl={"16px"} pr={["0px", "0px", "50px"]} mb={["0px", "0px", "40px"]}>
                <Grid item xs={12}>
                    <DisplayText size={"xs"} textweight={"bold"}>
                        {info.title}
                    </DisplayText>
                </Grid>
                {info.description1 && (
                    <Grid item xs={12}>
                        <Text textcolor={ColorTheme.grayNeutral[600]} size={"md"} textweight={"regular"}>
                            {info.description1}
                        </Text>
                    </Grid>
                )}
                {info.description2 && (
                    <Grid item xs={12} mt={"10px"}>
                        <Text textcolor={ColorTheme.grayNeutral[600]} size={"md"} textweight={"regular"}>
                            {info.description2}
                        </Text>
                    </Grid>
                )}
            </Grid>
        );
    };

    const renderStoryView = () => {
        if (desktopView) {
            return OurStoryInfo.map((info, index) => (
                <Grid borderRight={index % 2 === 0 ? "1px #9DA4AE solid" : ""} item xs={12} md={6} key={info.id}>
                    {index % 2 === 0 && (
                        <Box
                            top={index !== 0 ? "70px" : "0px"}
                            position={"relative"}
                            left={"8px"}
                            display={"flex"}
                            justifyContent={"end"}
                        >
                            <img src={_url("assets/redesign/icons/primary_dot.svg")} />
                        </Box>
                    )}

                    {renderView(info)}
                </Grid>
            ));
        }

        return OurStoryMobileInfo.map((info, index) => (
            <Grid borderLeft={`1px ${ColorTheme.grayNeutral[400]} solid`} item xs={12} md={6} key={info.id}>
                {index % 2 === 0 && (
                    <Box
                        top={index !== 0 ? "70px" : "0px"}
                        position={"relative"}
                        left={"-8px"}
                        display={"flex"}
                        justifyContent={"start"}
                    >
                        <img src={_url("assets/redesign/icons/primary_dot.svg")} />
                    </Box>
                )}

                {renderView(info)}
            </Grid>
        ));
    };
    return (
        <Grid container>
            <Grid item xs={12}>
                <DisplayText textweight={"bold"} textAlign={"center"} size={"md"}>
                    Our Story
                </DisplayText>
                <Text
                    textweight={"regular"}
                    mt={"16px"}
                    textcolor={ColorTheme.grayNeutral[700]}
                    textAlign={"center"}
                    size={"md"}
                >
                    We are driven by our history and inspired by the impact we can make.
                </Text>
            </Grid>
            <Grid item xs={12} mt={"120px"}>
                <Grid container>
                    <Grid item xs={12}>
                        <Grid container columnSpacing={["0px", "0px", "80px"]}>
                            {renderStoryView()}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default OurStory;
