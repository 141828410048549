import { Grid } from "@mui/material";
import { useGetWindowSize } from "../../../../components/common/hook/WindowSize";
import { DisplayText, Text } from "../../../../components/common/typography/Style";
import { _url } from "../../../../config/utils";

const AboutPayLaterSection = () => {
    const { desktopView } = useGetWindowSize();
    return (
        <Grid container justifyContent={"space-between"} pt={"56px"}>
            <Grid item xs={12} md={4}>
                <DisplayText
                    textheight={desktopView ? undefined : "42px"}
                    size={desktopView ? "lg" : "md"}
                    textweight={"semiBold"}
                    className="display-text"
                >
                    About Pay Later Travel
                </DisplayText>
            </Grid>
            <Grid item xs={12} md={8}>
                <Text size={"md"} textweight={"regular"}>
                    Our innovative lay-by model allows you to explore the world debt-free. With the freedom to lock in
                    great travel deals today, using just a small deposit, and paying the remainder in up to{" "}
                    <span style={{ fontWeight: 600 }}>26 weekly payments</span>, we empower you to travel on your terms.
                </Text>
            </Grid>
            <Grid item xs={12} py={"40px"}>
                <img
                    height={desktopView ? "100%" : "212px"}
                    src={_url("assets/redesign/icons/about_us.png")}
                    width={"100%"}
                />
            </Grid>
        </Grid>
    );
};

export default AboutPayLaterSection;
