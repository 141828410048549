import React, { Component } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col, Button } from "reactstrap";
import PropTypes from "prop-types";

class DropdownHotelGuests extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdownOpen: false
        };
    }

    onToggle = (e) => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    };

    subAdult = () => {
        if (this.props.adults > 1) {
            this.props.onChangeAdults(this.props.adults - 1);
        }
    };

    addAdult = () => {
        if (this.props.adults < 10) {
            this.props.onChangeAdults(this.props.adults + 1);
        }
    };

    subRoom = () => {
        if (this.props.rooms > 1) {
            this.props.onChangeRooms(this.props.rooms - 1);
        }
    };

    addRoom = () => {
        if (this.props.rooms < 5) {
            this.props.onChangeRooms(this.props.rooms + 1);
        }
    };

    render() {
        const guests = this.props.adults + (this.props.childAges.length > 0 ? this.props.childAges.length : 0);
        return (
            <Dropdown
                isOpen={this.state.dropdownOpen}
                toggle={this.onToggle}
                className="SearchForm__dropdown dropdown-passengers"
            >
                <DropdownToggle caret color="success" className="SearchForm__dropdownToggleButton">
                    <div className="SearchForm__label">Guests</div>
                    <div className="SearchForm__fieldValue">
                        {this.props.adults > 0 && (
                            <span className="info-passenger">
                                {guests} guest
                                {guests > 1 ? "s" : ""}
                            </span>
                        )}
                        {(this.props.adults > 0 || this.props.childAges.length > 0 || this.props.rooms > 0) && (
                            <span>
                                ,{this.props.rooms} room
                                {this.props.rooms > 1 ? "s" : ""}
                            </span>
                        )}
                    </div>
                </DropdownToggle>
                <DropdownMenu className="SearchForm__dropdownMenu">
                    <div className="item">
                        <Row>
                            <Col>
                                <span className="number">{this.props.rooms}</span>
                                <span className="title">
                                    Room
                                    {this.props.rooms > 1 ? "s" : ""}
                                </span>
                            </Col>
                            <Col xs="auto">
                                <Button
                                    onClick={this.subRoom}
                                    type="button"
                                    className="btn-count"
                                    disabled={this.props.rooms === 0}
                                >
                                    -
                                </Button>
                                <Button onClick={this.addRoom} type="button" className="btn-count">
                                    +
                                </Button>
                            </Col>
                        </Row>
                    </div>
                    <div className="item">
                        <Row>
                            <Col>
                                <span className="number">{this.props.adults}</span>
                                <span className="title">
                                    Adult
                                    {this.props.adults > 1 ? "s" : ""}
                                </span>
                            </Col>
                            <Col xs="auto">
                                <Button
                                    onClick={this.subAdult}
                                    type="button"
                                    className="btn-count"
                                    disabled={this.props.adults === 0}
                                >
                                    -
                                </Button>
                                <Button onClick={this.addAdult} type="button" className="btn-count">
                                    +
                                </Button>
                            </Col>
                        </Row>
                    </div>
                    <div className="item">
                        <Row>
                            <Col>
                                <span className="number">{this.props.childAges.length}</span>
                                <span className="title">
                                    Child
                                    {this.props.childAges.length > 1 || this.props.childAges.length === 0 ? "ren" : ""}
                                </span>
                            </Col>
                            <Col xs="auto">
                                <Button
                                    onClick={() => {
                                        if (this.props.childAges.length > 0) {
                                            this.props.removeChild();
                                        }
                                    }}
                                    type="button"
                                    className="btn-count"
                                    disabled={this.props.childAges.length === 0}
                                >
                                    -
                                </Button>
                                <Button
                                    onClick={() => {
                                        if (this.props.childAges.length < 5) {
                                            this.props.addChild();
                                        }
                                    }}
                                    type="button"
                                    className="btn-count"
                                >
                                    +
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {this.props.childAges.length > 0 && (
                                    <div className="children-ages">
                                        <h4>Ages of children?</h4>
                                        {this.props.childAges.length > 0 &&
                                            this.props.childAges.map((child, index) => (
                                                <select
                                                    onChange={(e) => this.props.updateChildAge(e, index)}
                                                    value={this.props.childAges[index]}
                                                    id={`childrenAges-${index}`}
                                                    name="childrenAges"
                                                >
                                                    <option value="0">&lt;1 year old</option>
                                                    <option value="1">1 year old</option>
                                                    <option value="2">2 years old</option>
                                                    <option value="3">3 years old</option>
                                                    <option value="4">4 years old</option>
                                                    <option value="5">5 years old</option>
                                                    <option value="6">6 years old</option>
                                                    <option value="7">7 years old</option>
                                                    <option value="8">8 years old</option>
                                                    <option value="9">9 years old</option>
                                                    <option value="10">10 years old</option>
                                                    <option value="11">11 years old</option>
                                                    <option value="12">12 years old</option>
                                                    <option value="13">13 years old</option>
                                                    <option value="14">14 years old</option>
                                                    <option value="15">15 years old</option>
                                                    <option value="16">16 years old</option>
                                                    <option value="17">17 years old</option>
                                                </select>
                                            ))}
                                    </div>
                                )}
                            </Col>
                        </Row>
                    </div>
                </DropdownMenu>
            </Dropdown>
        );
    }
}

DropdownHotelGuests.propTypes = {
    onChangeAdults: PropTypes.func,
    onChangeRooms: PropTypes.func,
    adults: PropTypes.number,
    rooms: PropTypes.number
};

DropdownHotelGuests.defaultProps = {
    onChangeAdults: () => false,
    onChangeRooms: () => false,
    adults: 1,
    rooms: 1
};

export default DropdownHotelGuests;
