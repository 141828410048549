import { TextField } from "@mui/material";
import { useField } from "formik";
import React from "react";
import { FormFeedback, Input } from "reactstrap";
import { ColorTheme } from "../../../styles/color";
import { logAmplitudeEvent } from "../../../utils/log-amplitude-event";
import { Text } from "../typography/Style";
import { LabelTextfieldContainer, StyledTextField } from "./Style";

const FormikInputField = ({
    name,
    fieldLabel,
    id,
    label,
    isMandatory,
    inputType,
    onTextfieldBlur,
    amplitudeData,
    redesign,
    ...rest
}) => {
    const [field, { error, touched }, { setTouched }] = useField(name);
    console.log("error", error);
    if (inputType === "label") {
        return (
            <LabelTextfieldContainer>
                <Text mb={"6px"} size={"sm"} textweight={"medium"}>
                    {fieldLabel}
                </Text>
                <TextField
                    error={!!error && !!touched}
                    onBlur={() => {
                        setTouched(true);
                        amplitudeData &&
                            logAmplitudeEvent(amplitudeData.amplitudeEvent, {
                                ...amplitudeData.payload,
                                value: field.value
                            });
                    }}
                    fullWidth
                    label={""}
                    variant={"outlined"}
                    size={"small"}
                    autoComplete="off"
                    helperText={!!error && !!touched && error}
                    {...field}
                    {...rest}
                />
            </LabelTextfieldContainer>
        );
    }
    if (inputType === "primary") {
        return (
            <>
                <TextField
                    error={!!error && !!touched}
                    onBlur={() => {
                        setTouched(true);
                        amplitudeData &&
                            logAmplitudeEvent(amplitudeData.amplitudeEvent, {
                                ...amplitudeData.payload,
                                value: field.value
                            });
                    }}
                    fullWidth
                    label={label}
                    variant={"outlined"}
                    size={"small"}
                    autoComplete="off"
                    helperText={!!error && !!touched && error}
                    {...field}
                    {...rest}
                />
            </>
        );
    }
    return (
        <StyledTextField
            redesign={redesign ? 1 : 0}
            onBlur={() => {
                setTouched(true);
                amplitudeData &&
                    logAmplitudeEvent(amplitudeData.amplitudeEvent, { ...amplitudeData.payload, value: field.value });
                onTextfieldBlur && onTextfieldBlur();
            }}
        >
            {label && (
                <Text textcolor={ColorTheme.grayNeutral[700]} size={"sm"} textweight={"regular"}>
                    {label} {isMandatory && <span className="text-danger">*</span>}
                </Text>
            )}
            <Input invalid={!!error && !!touched} name={name} id={id} {...field} {...rest} />
            <FormFeedback>{error}</FormFeedback>
        </StyledTextField>
    );
};

export default FormikInputField;
