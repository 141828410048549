import React from "react";

class BaseComponent extends React.Component {
    onChangeValue = (f, v) => {
        const { form } = this.state;
        if (f === "card_number") {
            if (v.length > 0) {
                if (parseInt(v[v.length - 1], 10) || parseInt(v[v.length - 1], 10) === 0) {
                    form[f].value = v;
                    form[f].isTouched = true;
                }
            } else {
                form[f].value = "";
                form[f].isTouched = true;
            }
        } else if (form[f].value !== undefined) {
            form[f].value = v;
            form[f].isTouched = true;
        } else {
            form[f] = v;
        }
        this.setState({ form });
    };

    checkValidate = (field, isDate = false) => {
        if (field.isTouched) {
            if (isDate) {
                if (field.value == null) return true;
                return false;
            }
            if (field.value.length > 0) {
                return false;
            }
            return true;
        }
        return false;
    };

    onTouched = (field) => {
        const { form } = this.state;
        form[field].isTouched = true;
        this.setState({
            form
        });
    };

    validateRequired = () => {
        let conditions = true;
        const { form } = this.state;
        Object.keys(form).forEach((k) => {
            if (form[k] && form[k].isTouched !== undefined && form[k].value !== undefined && form[k].value.length < 1) {
                conditions = false;
                form[k].isTouched = true;
            }
        });
        if (!conditions) {
            this.setState({
                form
            });
        }
        return conditions;
    };

    getValue = (exclude = []) => {
        const datas = {};
        Object.keys(this.state.form).forEach((k) => {
            if (exclude.indexOf(k) < 0) {
                if (this.state.form[k].value !== undefined) {
                    datas[k] = this.state.form[k].value;
                } else {
                    datas[k] = this.state.form[k];
                }
            }
        });
        return datas;
    };
}

export default BaseComponent;
