import { Dialog, Grid, IconButton, MenuItem, Popover, Select } from "@mui/material";
import React, { useState } from "react";
import { PrimaryButton } from "../../../../components/common/button/ButtonStyle";
import { useGetWindowSize } from "../../../../components/common/hook/WindowSize";
import { Text } from "../../../../components/common/typography/Style";
import { _url } from "../../../../config/utils";
import { SHOW_CABIN_CLASS } from "../../../../constants";
import { FlightClassOption } from "../../../../constants/Options";
import { useBooleanState } from "../../../../hooks/CommonHooks";
import { ColorTheme } from "../../../../styles/color";
import { CustomButton, CustomItem, PassengerTextfield, StyledSelect } from "../style";

const FlightPassengerDropdown = ({ adults, child, infants, cabinClass, onChangePassenger }) => {
    const [adultPassenger, setAdults] = useState(adults);
    const [childPassenger, setChild] = useState(child);
    const [infantsPassenger, setInfants] = useState(infants);
    const [flightClass, setCabinClass] = useState(cabinClass);
    const openGuestPopOver = useBooleanState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const { mobileView } = useGetWindowSize();

    const handleClick = (event) => {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
        openGuestPopOver.toggle();
    };

    const subAdult = () => {
        if (adultPassenger > 1) {
            setAdults((prev) => prev - 1);
        }
    };

    const addAdult = () => {
        if (adultPassenger + childPassenger + infantsPassenger < 6) {
            setAdults((prev) => prev + 1);
        }
    };

    const subChildren = () => {
        if (childPassenger > 0) {
            setChild((prev) => prev - 1);
        }
    };

    const addChildren = () => {
        if (adultPassenger + childPassenger + infantsPassenger < 6) {
            setChild((prev) => prev + 1);
        }
    };

    const subInfant = () => {
        if (infantsPassenger > 0) {
            setInfants((prev) => prev - 1);
        }
    };

    const addInfant = () => {
        if (adultPassenger + childPassenger + infantsPassenger < 6) {
            setInfants((prev) => prev + 1);
        }
    };

    const handleFlightClassChange = (event) => {
        setCabinClass(event.target.value);
    };

    const closeModal = () => {
        setAnchorEl(event.currentTarget);
        openGuestPopOver.toggle();
        onChangePassenger({
            adults: adultPassenger,
            child: childPassenger,
            infants: infantsPassenger,
            cabinClass: flightClass
        });
        setAnchorEl(null);
        openGuestPopOver.toggle();
    };

    const renderPassengerForm = () => {
        return (
            <>
                <Grid container p={"20px"}>
                    <Grid item xs={12}>
                        <Text size={"sm"} textweight={"medium"}>
                            Cabin class
                        </Text>
                        {SHOW_CABIN_CLASS && (
                            <StyledSelect fullWidth>
                                <Select
                                    id="demo-simple-select"
                                    value={flightClass}
                                    label=""
                                    sx={{
                                        ".MuiOutlinedInput-notchedOutline": {
                                            border: `2px ${ColorTheme.grayNeutral[1000]} solid`
                                        },
                                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                            borderColor: `${ColorTheme.secondary[950]}`
                                        },
                                        "&:hover .MuiOutlinedInput-notchedOutline": {
                                            borderColor: `${ColorTheme.grayNeutral[1000]}`
                                        }
                                    }}
                                    onChange={handleFlightClassChange}
                                    renderValue={(o) => {
                                        return FlightClassOption.find((item) => item.value === o).name;
                                    }}
                                >
                                    {FlightClassOption.filter((item) => item.toShow).map((item) => (
                                        <MenuItem key={item.value} value={item.value}>
                                            <span style={{ width: "100%" }}>{item.name}</span>
                                            {item.value === flightClass && (
                                                <img src={_url("assets/redesign/icons/check.svg")} alt="tick" />
                                            )}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </StyledSelect>
                        )}
                    </Grid>
                </Grid>
                <CustomItem>
                    <Grid alignItems={"center"} container justifyContent={"space-between"} flexWrap={"nowrap"}>
                        <Grid item>
                            <Text size={"md"} textweight={"semiBold"}>
                                Adult
                            </Text>
                        </Grid>
                        <Grid item>
                            <Grid container gap={"24px"} alignItems={"center"} flexWrap={"nowrap"}>
                                <CustomButton
                                    onClick={subAdult}
                                    type="button"
                                    className="btn-count"
                                    disabled={adultPassenger === 0}
                                >
                                    -
                                </CustomButton>
                                <Text size={"xl"} textweight={"regular"}>
                                    {adultPassenger}
                                </Text>
                                <CustomButton onClick={addAdult} type="button" className="btn-count">
                                    +
                                </CustomButton>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        alignItems={"center"}
                        mt={"40px"}
                        justifyContent={"space-between"}
                        flexWrap={"nowrap"}
                    >
                        <Grid item>
                            <Text size={"md"} textweight={"semiBold"}>
                                Child
                            </Text>
                            <Text size={"md"} textweight={"regular"}>
                                2 to 11 years
                            </Text>
                        </Grid>
                        <Grid item>
                            <Grid container gap={"24px"} alignItems={"center"} flexWrap={"nowrap"}>
                                <CustomButton
                                    onClick={subChildren}
                                    type="button"
                                    className="btn-count"
                                    disabled={childPassenger === 0}
                                >
                                    -
                                </CustomButton>
                                <Text size={"xl"} textweight={"regular"}>
                                    {childPassenger}
                                </Text>
                                <CustomButton onClick={addChildren} type="button" className="btn-count">
                                    +
                                </CustomButton>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        mt={"40px"}
                        justifyContent={"space-between"}
                        flexWrap={"nowrap"}
                        alignItems={"center"}
                    >
                        <Grid item>
                            <Text size={"md"} textweight={"semiBold"}>
                                Lap Infant
                            </Text>
                            <Text size={"md"} textweight={"regular"}>
                                Under 2
                            </Text>
                        </Grid>
                        <Grid item>
                            <Grid container gap={"24px"} alignItems={"center"} flexWrap={"nowrap"}>
                                <CustomButton
                                    onClick={subInfant}
                                    type="button"
                                    className="btn-count"
                                    disabled={infantsPassenger === 0}
                                >
                                    -
                                </CustomButton>
                                <Text size={"xl"} textweight={"regular"}>
                                    {infantsPassenger}
                                </Text>
                                <CustomButton onClick={addInfant} type="button" className="btn-count">
                                    +
                                </CustomButton>
                            </Grid>
                        </Grid>
                    </Grid>
                    {mobileView && (
                        <Grid container mt={"56px"} justifyContent={"center"}>
                            <PrimaryButton onClick={closeModal}>Apply</PrimaryButton>
                        </Grid>
                    )}
                </CustomItem>
            </>
        );
    };
    return (
        <Grid container>
            <Grid item xs={12}>
                <PassengerTextfield
                    height={"56px"}
                    borderRadius={["4px 4px 16px 16px", "4px 4px 16px 16px", "4px"]}
                    onClick={handleClick}
                >
                    <img src={_url("assets/redesign/icons/user_profile.svg")} />
                    <Text size={"sm"} textweight={"regular"}>
                        {adults > 0 && (
                            <span className="info-passenger">
                                {adults} adult{adults > 1 ? "s" : ""}
                            </span>
                        )}
                        {child > 0 && (
                            <span className="info-passenger">
                                {adults > 0 && ", "}
                                {child} child{child > 1 ? "ren" : ""}
                            </span>
                        )}
                        {infants > 0 && (
                            <span className="info-passenger">
                                {(adults > 0 || child > 0) && ", "}
                                {infants} infant{infants > 1 ? "s" : ""}
                            </span>
                        )}
                        {SHOW_CABIN_CLASS && cabinClass && (
                            <span className="info-passenger">
                                , {FlightClassOption.find((item) => item.value === cabinClass).name}
                            </span>
                        )}
                    </Text>
                </PassengerTextfield>
            </Grid>
            {mobileView ? (
                <Dialog fullScreen open={openGuestPopOver.value} onClose={closeModal}>
                    <Grid container pt={"30px"} px={"16px"}>
                        <Grid item xs={12}>
                            <IconButton onClick={closeModal} style={{ position: "absolute", right: 10, top: 23 }}>
                                <img src={_url("assets/redesign/icons/close_icon.svg")} />
                            </IconButton>
                            <Text size={"md"} align="center" textweight={"semiBold"}>
                                Class and Passenger Type
                            </Text>
                        </Grid>
                        <Grid item xs={12}>
                            {renderPassengerForm()}
                        </Grid>
                    </Grid>
                </Dialog>
            ) : (
                <Popover
                    open={openGuestPopOver.value}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center"
                    }}
                    onClose={closeModal}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "center"
                    }}
                    PaperProps={{
                        style: {
                            minWidth: 380,
                            paddingTop: "15px",
                            paddingBottom: "15px"
                        }
                    }}
                >
                    {renderPassengerForm()}
                </Popover>
            )}
        </Grid>
    );
};

export default FlightPassengerDropdown;
