import React, { useState, forwardRef } from "react";
import moment from "moment";
import { CustomDateRangePickerWrapper } from "../search/form/Styled";
import DatePicker from "react-datepicker";
import { CustomDatePickerContainer, CustomDatePickerDate, CustomDatePickerLabel } from "./Style";

const CustomDateRangePicker = ({
    startDate,
    endDate,
    startDateLabel,
    endDateLabel,
    handleDateChange,
    minimumDate,
    maximumDate
}) => {
    const [openDatePicker, setOpenDatePicker] = useState({
        from: false,
        to: false
    });

    const CustomDatePickerInput = forwardRef(({ value, onClick, dateLabel }, ref) => (
        <CustomDatePickerContainer onClick={onClick} ref={ref}>
            <CustomDatePickerLabel>{dateLabel}</CustomDatePickerLabel>
            <CustomDatePickerDate>{value ? moment(value).format("DD MMM") : "Add Date"}</CustomDatePickerDate>
        </CustomDatePickerContainer>
    ));

    const handleFromDate = (date) => {
        if (date > endDate) {
            handleDateChange({
                startDate: date,
                endDate: new Date(moment(date).add(5, "days"))
            });
            setOpenDatePicker({
                from: false,
                to: true
            });
            return;
        }
        handleDateChange({
            startDate: date,
            endDate: endDate
        });
        setOpenDatePicker({
            from: false,
            to: true
        });
    };

    const handleToDate = (date) => {
        if (date < startDate) {
            handleDateChange({
                startDate: date,
                endDate: null
            });
            setOpenDatePicker({
                from: false,
                to: true
            });
            return;
        }

        handleDateChange({
            startDate: startDate,
            endDate: date
        });
        setOpenDatePicker({
            from: false,
            to: false
        });
    };

    return (
        <CustomDateRangePickerWrapper>
            <DatePicker
                selected={new Date(startDate)}
                onChange={(date) => {
                    handleFromDate(date);
                }}
                open={openDatePicker.from}
                onClickOutside={() =>
                    setOpenDatePicker((prev) => ({
                        to: false,
                        from: false
                    }))
                }
                onInputClick={() =>
                    setOpenDatePicker((prev) => ({
                        to: false,
                        from: true
                    }))
                }
                selectsStart
                minDate={minimumDate ? new Date(minimumDate) : new Date()}
                startDate={new Date(startDate)}
                endDate={new Date(endDate)}
                monthsShown={2}
                customInput={<CustomDatePickerInput dateLabel={startDateLabel} />}
            />
            <DatePicker
                selected={endDate ? new Date(endDate) : null}
                onChange={(date) => {
                    handleToDate(date);
                }}
                open={openDatePicker.to}
                onInputClick={() =>
                    setOpenDatePicker((prev) => ({
                        from: false,
                        to: true
                    }))
                }
                onClickOutside={() =>
                    setOpenDatePicker((prev) => ({
                        to: false,
                        from: false
                    }))
                }
                startDate={new Date(startDate)}
                endDate={new Date(endDate)}
                minDate={new Date(startDate)}
                maxDate={new Date(maximumDate) || new Date(moment().add("12", "months"))}
                monthsShown={window.innerWidth < 768 ? 12 : 2}
                customInput={<CustomDatePickerInput dateLabel={endDateLabel} />}
            />
        </CustomDateRangePickerWrapper>
    );
};

export default CustomDateRangePicker;
