import ReactGA from "react-ga4";

export const triggerEarlyPaymentEvent = () => {
    ReactGA.event({
        category: "payment",
        action: "earlyPayment",
        label: "Early Payment"
    });
};

export const triggerRetryPaymentEvent = () => {
    ReactGA.event({
        category: "payment",
        action: "retryPayment",
        label: "Retry Payment"
    });
};

export const triggerUpdateCardEvent = () => {
    ReactGA.event({
        category: "card",
        action: "updateCard",
        label: "Update Card"
    });
};

export const triggerViewdBookingDetailEvent = () => {
    ReactGA.event({
        category: "booking",
        action: "viewedBookingDetail",
        label: "View Booking"
    });
};

export const triggerBookingPassengerDetailSubmitEvent = () => {
    ReactGA.event({
        category: "booking",
        action: "bookingPassengerDetailsSubmitted",
        label: "Booking Detail submit"
    });
};

export const triggerBookingFlightSelectEvent = () => {
    ReactGA.event({
        category: "flight",
        action: "bookingFlightSelected",
        label: "Booking Flight select"
    });
};

export const triggerLoginEvent = (id) => {
    ReactGA.event({
        category: "auth",
        action: "loggedId",
        label: "Login Complete",
        value: id
    });
};

export const triggerLogoutEvent = () => {
    ReactGA.event({
        category: "auth",
        action: "loggedOut",
        label: "Logout Complete"
    });
};

export const triggerBookingSearchEvent = () => {
    ReactGA.event({
        category: "flightBooking",
        action: "bookingSearchStarted",
        label: "Flight Booking Search Started"
    });
};

export const triggerHotelBookingSearchEvent = () => {
    ReactGA.event({
        category: "hotelBooking",
        action: "hotelBookingSearchStarted",
        label: "Hotel Booking Search Started"
    });
};

export const triggerShowMoreFlightEvent = () => {
    ReactGA.event({
        category: "flight",
        action: "bookingShowMoreResultsClicked",
        label: "Booking Show more clicked"
    });
};

export const triggerBookingFilterAppliedAirlineEvent = () => {
    ReactGA.event({
        category: "flightFilter",
        action: "bookingFilterAppliedAirlines",
        label: "Filter by Applied airlines"
    });
};

export const triggerBookingFilterStopAppliedAirlineEvent = () => {
    ReactGA.event({
        category: "flightFilter",
        action: "bookingFilterStopAppliedAirlines",
        label: "Filter by stop"
    });
};

export const triggerBookingFilterArrivalAppliedAirlineEvent = () => {
    ReactGA.event({
        category: "flightFilter",
        action: "bookingFilterArrivalAppliedAirlines",
        label: "Filter by Arrival Applied airlines"
    });
};

export const triggerBookingFilterDepartureAppliedAirlineEvent = () => {
    ReactGA.event({
        category: "flightFilter",
        action: "bookingFilterDepartureAppliedAirlines",
        label: "Filter by Departure Applied airlines"
    });
};

export const triggerHomePageLoadedEvent = () => {
    ReactGA.event({
        category: "pageload",
        action: "homepageloaded",
        label: "Home Page Loaded"
    });
};

export const triggerFlightSearchPageLoadedEvent = () => {
    ReactGA.event({
        category: "pageload",
        action: "flightsearchpageloaded",
        label: "Flight Search Page Loaded"
    });
};

export const triggerFlightReviewPageLoadedEvent = () => {
    ReactGA.event({
        category: "pageload",
        action: "flightreviewpageloaded",
        label: "Flight Review Page Loaded"
    });
};
