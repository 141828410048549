import { Box, Divider, Grid, useMediaQuery, useTheme } from "@mui/material";
import { Trans } from "react-i18next";
import { Col } from "reactstrap";
import { DisplayText, Text } from "../../../components/common/typography/Style";
import { _formatMoney, _url } from "../../../config/utils";
import { ColorTheme } from "../../../styles/color";
import { PaymentPlanButton } from "./Style";

export const PaymentPlanOptions = ({ reviewData, paymentPlan, onChangePaymentFrequency = () => {} }) => {
    const theme = useTheme();
    const mobileView = useMediaQuery(theme.breakpoints.down(900));

    const shouldRenderBiWeeklyBox = paymentPlan.fortnights > 0;
    const shouldRenderWeeklyBox = paymentPlan.weeks > 0;
    const biWeeklyPaymentWidth = 6;
    const weeklyPaymentWidth = shouldRenderBiWeeklyBox ? 6 : 12;

    if (reviewData.plan === 2 && !shouldRenderBiWeeklyBox) {
        onChangePaymentFrequency({ paymentFrequency: "weekly" });
    }

    return (
        <Col sm={12}>
            <Grid mt={"24px"} container columnSpacing={"8px"}>
                {shouldRenderWeeklyBox && (
                    <Grid position={"relative"} item xs={weeklyPaymentWidth}>
                        <PaymentPlanButton
                            className="weekly-payment-option"
                            onClick={() => {
                                onChangePaymentFrequency({ paymentFrequency: "weekly" });
                            }}
                            isactive={reviewData.plan === 1 ? 1 : 0}
                        >
                            <Box
                                position={"absolute"}
                                right={"10px"}
                                top={"10px"}
                                height={"20px"}
                                display={"flex"}
                                justifyContent={"end"}
                            >
                                {reviewData.plan === 1 && (
                                    <img style={{ height: 20, width: 20 }} src={_url("assets/icons/check_green.svg")} />
                                )}
                            </Box>
                            <Text
                                size={mobileView ? "xs" : "md"}
                                textweight={"regular"}
                                textcolor={reviewData.plan === 1 ? undefined : ColorTheme.grayNeutral[600]}
                                textAlign={"center"}
                            >
                                <Trans>paymentPlanWeekly</Trans>
                            </Text>
                            <DisplayText
                                size={"xs"}
                                textsize={mobileView ? "18px" : undefined}
                                textweight={"bold"}
                                justifyContent={"center"}
                                textcolor={
                                    reviewData.plan === 1 ? ColorTheme.primary[500] : ColorTheme.grayNeutral[500]
                                }
                                display={"flex"}
                                alignItems={"baseline"}
                                gap={"5px"}
                                textheight={mobileView ? "28px" : "30px"}
                            >
                                ${_formatMoney(paymentPlan.pricePerWeek / 100)}
                                <Text
                                    component={"span"}
                                    size={"xs"}
                                    textweight={"regular"}
                                    textcolor={ColorTheme.grayNeutral[600]}
                                >
                                    {" x "}
                                    {paymentPlan.weeks}
                                </Text>
                            </DisplayText>
                        </PaymentPlanButton>
                    </Grid>
                )}
                {shouldRenderBiWeeklyBox && (
                    <Grid item xs={biWeeklyPaymentWidth} position={"relative"}>
                        <PaymentPlanButton
                            className="fortnightly-payment-option"
                            onClick={() => {
                                onChangePaymentFrequency({ paymentFrequency: "biWeekly" });
                            }}
                            isactive={reviewData.plan === 2 ? 1 : 0}
                        >
                            <Box
                                position={"absolute"}
                                right={"10px"}
                                top={"10px"}
                                height={"20px"}
                                display={"flex"}
                                justifyContent={"end"}
                            >
                                {reviewData.plan === 2 && (
                                    <img style={{ height: 20, width: 20 }} src={_url("assets/icons/check_green.svg")} />
                                )}
                            </Box>
                            <Text size={mobileView ? "xs" : "md"} textweight={"regular"} textAlign={"center"}>
                                <Trans>paymentPlanBi</Trans>
                            </Text>
                            <DisplayText
                                size={"xs"}
                                textweight={"bold"}
                                justifyContent={"center"}
                                textcolor={
                                    reviewData.plan === 2 ? ColorTheme.primary[500] : ColorTheme.grayNeutral[500]
                                }
                                display={"flex"}
                                alignItems={"baseline"}
                                gap={"5px"}
                                textsize={mobileView ? "18px" : undefined}
                                textheight={mobileView ? "28px" : "30px"}
                            >
                                ${_formatMoney(paymentPlan.pricePerFortnight / 100)}
                                <Text
                                    component={"span"}
                                    size={"xs"}
                                    textweight={"regular"}
                                    textcolor={ColorTheme.grayNeutral[600]}
                                >
                                    {" x "}
                                    {paymentPlan.fortnights}
                                </Text>
                            </DisplayText>
                        </PaymentPlanButton>
                    </Grid>
                )}
                <Grid item xs={12} my={["20px", "20px", "28px"]}>
                    <Divider />
                </Grid>
            </Grid>
        </Col>
    );
};
