import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { forgotPassword } from "./util";

export const useFinaliseAccount = () => {
    const passenger = useSelector((state) => state.passengers);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const booking = useSelector((state) => state.bookings.recentlyCompletedBooking);

    const [showPasswordIcon, setShowPasswordIcon] = useState(false);
    const [showConfirmPasswordIcon, setShowConfirmPasswordIcon] = useState(false);

    const updatePassword = (values) => {
        dispatch(forgotPassword(values, passenger.email, booking.authProviderId)).then(() => {
            navigate(`/bookings/${booking.reference}`);
        });
    };

    useEffect(() => {
        if (!booking) {
            navigate("/");
        }
    }, []);

    return {
        showPasswordIcon,
        setShowPasswordIcon,
        showConfirmPasswordIcon,
        setShowConfirmPasswordIcon,
        updatePassword
    };
};
